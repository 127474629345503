import { BooleanInput, NumberInput, ReferenceInput, SelectInput, SimpleForm, SaveButton, ToolbarClasses, FormDataConsumer, Toolbar  } from 'react-admin';
import { Product, Variant } from '../types';
import { useState } from 'react';


interface PriceListingFormProps {
  products: Product[]
}

const NoDeleteToolBar = () => (
  <Toolbar>
    <div className={ToolbarClasses.defaultToolbar}>
        <SaveButton />
    </div>
  </Toolbar>
)
  

export const PriceListingForm = (props: PriceListingFormProps) => {
  const [variants, setVariants] = useState<Variant[]>([]);

  return (
    <SimpleForm toolbar={<NoDeleteToolBar />}>
      <BooleanInput source="isEnabled" />
      <ReferenceInput label="Thema" source="topicId" reference="topics" filter={{ isBeursGoesTopic: true }} perPage={99999} sort={{ field: 'name', order: 'ASC'}} >
        <SelectInput label={"resources.price-listings.fields.topic"} optionText="name" required />
      </ReferenceInput>
      <SelectInput source='name' optionText="name" optionValue='name' choices={props.products} onChange={(p) => setVariants(props.products.find(x=> x.name === p.target.value)?.variants)} required />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return (
          <SelectInput source='productId' label="Variant" optionText="variant" optionValue='id' choices={variants && variants.length ? variants : props.products.find(x=> x.name === formData?.name)?.variants ?? []} required {...rest} />
        )}
        }
      </FormDataConsumer>
      <NumberInput source="minimumPrice" min="0.00" max="100000.00" step="0.01" required />
      <NumberInput source="maximumPrice" min="0.00" max="100000.00" step="0.01" required />
    </SimpleForm>
  );
};
