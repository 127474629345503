import * as React from 'react';
import { Datagrid, DateField, List, CreateButton, ExportButton, TopToolbar, TextField, WithRecord } from 'react-admin';
import { ServiceImageField } from '../../components/Field/ServiceImageField';

const ListActions = () => (
  <TopToolbar>
      <CreateButton/>
      <ExportButton maxResults={999999999}/>
  </TopToolbar>
);

export const TopicList = (props: any) => (
  <List actions={<ListActions />} filter={{ _embed: 'media' }} {...props}>
    <Datagrid bulkActionButtons={null} rowClick="edit">
      <WithRecord render={record => {
          return record.useMediaUrl ? 
            <img src={`${record.mediaUrl}?width=48&height=48`} width={48} height={48} alt={record.mediaUrl} /> 
            : 
            <ServiceImageField source="media" />
      }} />
      
      <TextField source="name" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);
