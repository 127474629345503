import * as React from 'react';

interface SideBySideProps {
    LeftComponent: React.ReactElement
    RightComponent: React.ReactElement
}

export default function SideBySideView(props: SideBySideProps) {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', columnGap: '20px' }}>
      <div style={{ flexGrow: 1 }}>{props.LeftComponent}</div>
      <div style={{ flexGrow: 1 }}>{props.RightComponent}</div>
    </div>
  );
}
