import { ToggleButton, ToggleButtonProps } from '@mui/material';
import YouTubeIcon  from '@mui/icons-material/YouTube';
import { useTranslate } from 'ra-core';
import { useTiptapEditor } from 'ra-input-rich-text';

export const VideoButton = (props: Omit<ToggleButtonProps, 'value'>) => {
  const editor = useTiptapEditor();
  const translate = useTranslate();

  const label = translate('ra.tiptap.clear_format', {
    _: 'Add video',
  });
  
  const addVideo = () => {
    const url = prompt('Enter YouTube or Vimeo URL')

    if (url) {
      editor.commands.setVideo({
        src: url,
        width: 640,
        height: 480,
      })
    }
  }

  return (
    <ToggleButton
      aria-label={label}
      title={label}
      {...props}
      disabled={!editor?.isEditable}
      value="clear"
      onClick={addVideo}
    >
      <YouTubeIcon fontSize="inherit" />
    </ToggleButton>
  );
};
