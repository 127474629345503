import ArticleIcon from '@mui/icons-material/Article';
import { GuardedComponents } from '../../lib/permissions/types';
import { ResourceDefinition } from '../types';
import { NewsCreate, NewsEdit, NewsList } from '.';

export default {
  permissionResource: 'News',
  name: 'news',
  icon: ArticleIcon,
  components: new GuardedComponents([
    ['list', { action: 'GetList', component: NewsList }],
    ['edit', { action: 'Update', component: NewsEdit }],
    ['create', { action: 'Create', component: NewsCreate }],
  ]),
  i18n: {
    nl: {
      name: 'Actueel |||| Actueel',
      imageHelperText: 'Optimaal resultaat bij 930x1050',
      fields: {
        archive: "Archiveer",
        deArchive: "Archivering ongedaan maken",
        isArchived: "Gearchiveerd",
        createdBy: 'Aangemaakt door',
        topic: 'Thema',
        isImportant: 'Pinned',
        isPreview: 'Preview modus, alleen de aanmaker van dit bericht (%{createdBy}) kan dit item zien.',
        pushNotification: {
          enabled: 'Pushnotificatie versturen',
          title: 'Titel',
          description: 'Omschrijving',
        },
        agenda: {
          enabled: 'Agenda item aanmaken',
          allowRegistrations: 'Aanmelden mogelijk',
          location: 'Locatie',
          start: 'Start datum/tijd',
          end: 'Eind datum/tijd',
        },
        publishedAt: 'Gepubliceerd op',
        departments: 'Afdelingen',
        regions: 'Regios',
        agendaIds: 'Gekoppelde agenda items',
        noRegions: 'Geen regios beschikbaar',
        title: 'Titel',
        useMediaUrl: 'Url gebruiken in plaats van upload?',
        media: "Media",
        mediaUrl: "Media url",
        publishAt: "Inplannen publiceren",
        publishedAt_gte: 'Gepubliceerd na',
        publishedAt_lte: 'Gepubliceerd voor',
        extra_options: 'Extra opties',
        lto: 'LTO',
        isLocalNews: 'Lokaal'
      },
    },
  },
} as ResourceDefinition;
