import * as React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import { GroupedReferenceSelectArrayInput } from '../../components/Input/GroupedReferenceSelectArrayInput';
import { RecordEditTitle } from '../../components/RecordTitle';
import { NoDeleteToolbar } from '../../components/NoDeleteToolbar';
import { ReferenceArrayInput } from '../../components/Input/ReferenceArrayInput';

const resourceSort = { field: 'resource', direction: 'asc' };
// const saveButtonProps = { transform: ({ permissions, ...data }) => data };

export function RoleEdit(props: any) {
  return (
    <Edit title={<RecordEditTitle />} {...props}>
      <SimpleForm toolbar={<NoDeleteToolbar />}>
        <TextInput source="name" />
        <ReferenceArrayInput source="permissionIds" reference="permissions" sort={resourceSort} perPage={100}>
          <GroupedReferenceSelectArrayInput
            extractGroupLabel={({ resourceLabel }) => resourceLabel}
            labelKey="actionLabel"
            groupBy="resource"
          />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  );
}
