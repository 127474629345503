import type { FilteredComponents, GuardedComponents, PermissionDto, PermissionMap } from './types';
import { createPermissionMap, isAllowed } from './functions';
import { SA } from '../constants';

export class PermissionGuard {
  private map: PermissionMap | null = null;

  private superAdmin: boolean = false;
  private beursGoesMember: boolean = false;

  public hasPermissions() {
    return !!this.map || this.superAdmin;
  }

  constructor(permissions: PermissionDto[] | null = null, beursGoes: boolean = false) {
    this.beursGoesMember = beursGoes;
    this.setPermissions(permissions);
  }

  public setPermissions(permissions: Symbol | PermissionDto[] | null = null) {
    if (!permissions) {
      this.map = null;

      return;
    }

    if (permissions === SA) {
      this.superAdmin = true;

      return;
    }

    this.map = createPermissionMap(permissions as PermissionDto[]);
  }

  public isSuperAdmin() {
    return this.superAdmin;
  }

  public isBeursGoesMember() {
    return this.beursGoesMember;
  }

  public when(resource: string, action: null | string, value: any) {
    if (this.isAllowed(resource, action)) return value;

    return null;
  }

  public filterComponents(resource: string, components: GuardedComponents): FilteredComponents {
    const filtered: FilteredComponents = {};
    components.forEach(({ component, action, onlyBeursGoes }, key) => {
      if (this.isAllowed(resource, action, onlyBeursGoes)) filtered[key] = component as any;
    });

    return filtered;
  }

  public whenResource(resource: string, value: any) {
    if (this.isAllowed(resource, null)) return value();

    return null;
  }

  public isAllowed(resource: string, action?: string | null, onlyBeursGoes: boolean = false) {
    if (this.superAdmin) return true;
    if (!this.map) return false;
    if(onlyBeursGoes && !this.beursGoesMember) return false;

    return isAllowed(this.map, resource, action);
  }

  public areAllowed(resource: string, actions: string[]) {
    if (this.superAdmin) return true;

    if (!this.map) return false;

    return actions.every((action) => isAllowed(this.map, resource, action));
  }
}
