import * as React from 'react';
import { ToggleButton, ToggleButtonProps } from '@mui/material';
import InsertLink from '@mui/icons-material/InsertLink';

import { useTranslate } from 'ra-core';
import { useEditorSelection, useTiptapEditor } from 'ra-input-rich-text';

type LinkButtonProps = ToggleButtonProps & { }


export const LinkButtons = (props: Omit<LinkButtonProps, 'value'>) => {
    const editor = useTiptapEditor();
    const translate = useTranslate();
    const currentTextSelection = useEditorSelection();

    const label = translate('lto.rte.link.button_title');

    const handleClick = () => {
        if (!editor.can().setLink({ href: '' })) {
            return;
        }
        const { href } = editor.getAttributes('link');
        const url = window.prompt('URL', href ?? '');
        if(url !== null) {
            editor
                .chain()
                .focus()
                .unsetAllMarks()
                .extendMarkRange('link')
                .setLink({ href: url })
                .run();
        }
    };

    return (
        <ToggleButton
            aria-label={label}
            title={label}
            {...props}
            disabled={!editor?.isEditable || !currentTextSelection}
            value="link"
            onClick={handleClick}
            selected={editor && editor.isActive('link')}
        >
            <InsertLink fontSize="inherit" />
        </ToggleButton>
    );
};
