import * as React from 'react';
import { List, Datagrid, TextField, DateField, ReferenceField, NumberField, downloadCSV, } from 'react-admin';
import { usePermissionGuard } from '../../lib/permissions/usePermissionGuard';
import { dataProvider } from '../../lib/dataProvider';

const exporter = async () => {
  const data = await dataProvider.getRaw("/price-listings/export");
  downloadCSV(data, "prijsnoteringen-" + (new Date()).toLocaleString("nl-NL"))
};

export const PriceListingList = (props: any) => {
  const { guard } = usePermissionGuard();
  const canExport = guard.isAllowed('Transaction', 'Export');
  return (
    <List {...props} exporter={canExport ? () => exporter() : canExport}>
      <Datagrid bulkActionButtons={null} rowClick="show" sx={{
        '& th.column-minimumPrice': {
          textAlign: 'right',
        },
        '& th.column-maximumPrice': {
          textAlign: 'right',
        },
        '& td.column-minimumPrice': {
          textAlign: 'right',
          fontVariantNumeric: 'tabular-nums'
        },
        '& td.column-maximumPrice': {
          textAlign: 'right',
          fontVariantNumeric: 'tabular-nums'
        }
      }}>
        <DateField source="createdAt" locales="nl-NL" />
        <DateField source="updatedAt" locales="nl-NL" />
        <ReferenceField label={"resources.price-listings.fields.topic"} source="topicId" reference="topics">
          <TextField label={"resources.price-listings.fields.topic"} source="name" />
        </ReferenceField>
        <TextField source="name" />
        <TextField source="variant" />
        <NumberField source="minimumPrice" locales="nl-NL" options={{ style: 'currency', currency: 'EUR' }} />
        <NumberField source="maximumPrice" locales="nl-NL" options={{ style: 'currency', currency: 'EUR' }} />
      </Datagrid>
    </List>
  );
}
