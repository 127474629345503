import { Logout, UserMenu } from 'react-admin';
import { CopyAccessToken } from './CopyAccessToken';
import { ListProfiles } from './ListProfiles';

export const CustomUserMenu = (props: any) => (
  <UserMenu {...props}>
    {process.env.NODE_ENV === 'development' ? <CopyAccessToken /> : null}
    <ListProfiles />
    <Logout />
  </UserMenu>
);
