import polyglotI18nProvider from 'ra-i18n-polyglot';
import resources from '../resources';
import nl from './i18n/nl'; // import nl from 'ra-language-dutch'; once https://github.com/nickwaelkens/ra-language-dutch/pull/17 is merged.

const resourceDefaults = {
  nl: {
    fields: {
      createdAt: 'Aangemaakt op',
      updatedAt: 'Aangepast op',
    },
  },
};

// @todo move to individual resources and add to build tools resource profile.
const locales = {
  nl: {
    ...nl,

    // Custom root key for custom translations
    lto: {
      generic: {
        select_all: 'Selecteer alles',
        deselect_all: 'Deselecteer alles',
        unknown_error: 'Er is een onbekende fout opgetreden. Probeer het later opnieuw.',
        invalid_url: 'Url is ongeldig. Voer een geldig geformateerde url in.',
        url_notfound: 'Dit is geen geldige URL om een afbeelding uit te genereren. Voeg een geldige URL toe.',
        media: {
          images: {
            select_uploaded_earlier: 'Of selecteer een eerder geüploade afbeelding'
          }
        },
      },
      upload_label: 'Sleep je afbeelding hierheen of klik om te selecteren',
      rte: {
        link: {
          button_title: "Add link to URL",
        },
        link_to_content: {
          button_title: "Add internal link",
          news: {
            select_item_title: 'Selecteer een Actueel item',
            select_item: 'Selecteer een item',
            remove_link:'Verwijder huidige link'
          },
          no_options: 'Geen resultaten',
          select: 'Selecteer',
          close_modal: 'Sluit venster',
        },
        link_to_file: {
          button_title: "Add file",
          or: 'of',
          select_item_title: 'Upload of selecteer een bestand',
          select_item: 'Selecteer een item',
          remove_link:'Verwijder huidige link',
          select: 'Selecteer',
          close_modal: 'Sluit venster',
          delete_file: 'Weet u zeker dat u bestand \'%{fileName}\' wilt verwijderen?'
        }
      },
      screens: {
        import_status: {
          success_title: 'Geen import fouten gevonden',
          success_description: 'Alles lijkt te werken, je hoeft dus niets te doen!',
          dialog_title: 'Afdeling migreren',
          dialog_confirm: 'Bevestigen',
          list_button_migrate: 'Migreer afdeling',
          dialog_cancel: 'Annuleren',
          dialog_description:
            'Selecteer de afdeling waarnaartoe je de afdeling (actueel, enquetes en lidmaatschappen) wilt migreren. Let op! Dit kan tussen de 30 en 60 seconden duren.',
          missing_department: 'Selecteer eerst een nieuwe afdeling.',
        },
        accept_invite: {
          title: 'Account aanmaken',
          description: 'Kies een wachtwoord.',
          submit: 'Wachtwoord instellen',
          email: 'E-mailadres',
          password: 'Wachtwoord',
          password_repeat: 'Wachtwoord herhalen',
          error_invalid_password:
            'Wachtwoord moet minstens 8 karakters, een kleine letter, een hoofdletter, een cijfer en een symbool bevatten.',
        },
        reset_password: {
          title: 'Wachtwoord resetten',
          description: 'Kies een nieuw wachtwoord.',
          email: 'E-mailadres',
          password: 'Wachtwoord',
          password_repeat: 'Wachtwoord herhalen',
          submit: 'Wachtwoord instellen',
          error_invalid_password:
            'Wachtwoord moet minstens 8 karakters, een kleine letter, een hoofdletter, een cijfer en een symbool bevatten.',
        },
        login: {
          email: 'E-mailadres',
          password: 'Wachtwoord',
          signin: 'Inloggen',
          lost_password: 'Wachtwoord vergeten?',
          reset_password: 'Wachtwoord herstellen',
          invalid_credentials: 'Ongeldige credentials.',
          reset_password_email_sent:
            'Er is een e-mail verzonden naar het opgegeven e-mailadres. Klik op de link in de e-mail om je wachtwoord te resetten.',
          unknown_error: 'Er is een onbekende fout opgetreden. Probeer het later opnieuw.',
          back_to_login: 'Terug naar inloggen',
        },
        survey_list: {
          status: {
            draft: 'Concept',
            closed: 'Gesloten',
            published: 'Gepubliceerd',
          },
        },
        survey_show: {
          close_survey: 'Sluit enquête',
          closed: 'De enquête is gesloten',
          export: 'Exporteer',
          confirm_close: {
            title: 'Enquête sluiten',
            description:
              'Een gesloten enquête kan niet meer worden geopened. Weet je zeker dat je de enquête wilt sluiten?',
          },
        },
        user_list: {
          resend_confirmation: 'Opnieuw uitnodigen',
          resend_sending: 'Verzenden...',
          resend_success: 'Uitnodiging verzonden',
          resend_error: 'Er is een fout opgetreden tijdens het verzenden van de uitnodiging',
          export_error: 'Er is een fout opgetreden tijdens exporteren'
        },
        survey_edit: {
          confirm: {
            title: 'Enquête aanpassen',
            description:
              'Het aanpassen van een enquête verwijdert alle reacties en kan niet worden teruggedraaid. Weet je het zeker?',
          },
        },
      },
    },
    resources: {
      ...resources.reduce((acc, resource) => {
        acc[resource.name] = resourceDefaults.nl;

        if (!resource?.i18n?.nl) return acc;

        acc[resource.name] = {
          ...resource.i18n.nl,
          fields: { ...resourceDefaults.nl.fields, ...(resource.i18n.nl?.fields ?? {}) },
        };

        return acc;
      }, {}),
    },
  },
};

export const i18nProvider = polyglotI18nProvider((locale: string) => {
  return locales[locale];
}, 'nl');
