import * as React from 'react';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import { useTranslate } from 'ra-core';
import { CommonInputProps } from 'ra-ui-materialui';
import { TextInput, TextInputProps } from 'react-admin';

// @todo figure out if this is really needed. Label works fine, so I'm not sure what the point of this is.
export const LabeledSearchInput = (props: SearchInputProps) => {
  const translate = useTranslate();

  if (props.label) {
    throw new Error("<SearchInput> isn't designed to be used with a label prop. Use <TextInput> if you need a label.");
  }

  return (
    <StyledTextInput
      hiddenLabel
      resettable
      placeholder={translate('ra.action.search')}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon color="disabled" />
          </InputAdornment>
        ),
      }}
      size="small"
      {...props}
    />
  );
};

export type SearchInputProps = CommonInputProps & TextInputProps;

const PREFIX = 'RaSearchInput';

const StyledTextInput = styled(TextInput, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})({
  marginTop: 0,
});
