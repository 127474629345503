import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import { GuardedComponents } from '../../lib/permissions/types';
import { ResourceDefinition } from '../types';
import { TopicCreate, TopicEdit, TopicList } from '.';

export default {
  permissionResource: 'Topic',
  name: 'topics',
  icon: CollectionsBookmarkIcon,
  components: new GuardedComponents([
    ['list', { action: 'GetList', component: TopicList }],
    ['edit', { action: 'Update', component: TopicEdit }],
    ['create', { action: 'Create', component: TopicCreate }],
  ]),
  i18n: {
    nl: {
      name: 'Thema |||| Thema\'s',
      imageHelperText: 'Optimaal resultaat bij 930x1050',
      fields: {
        name: 'Naam',
        description: 'Omschrijving',
        isBeursGoesTopic: 'Beurs Goes Thema',
        useMediaUrl: 'Url gebruiken in plaats van upload?',
        mediaUrl: "Media url"
      },
    },
  },
} as ResourceDefinition;
