import * as React from 'react';
import { SaveButton, Toolbar } from 'react-admin';

export function NoDeleteToolbar({ saveButtonProps = {}, ...props }: any = {}) {
  return (
    <Toolbar {...props}>
      <SaveButton {...saveButtonProps} />
    </Toolbar>
  );
}
