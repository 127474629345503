export const ENV = process.env.REACT_APP_ENV;
export const API_URL = process.env.REACT_APP_API_URL;
export const VIMEO_API_TOKEN = process.env.REACT_APP_VIMEO_API_TOKEN;
export const SA = Symbol('SUPER_ADMIN');
export const FILE_UPLOAD = '__DASHBOARD_FILE_UPLOAD';
export const ADMIN_ID = process.env.REACT_APP_ADMIN_ROLE_ID || 'a72da601-f917-4946-8d64-673798c18cae';
export const SA_ID = process.env.REACT_APP_SA_ROLE_ID || 'c6511a59-cc18-47dd-bc04-6ff1470857dd';
export const APP_ROLE_ID = process.env.REACT_APP_APP_ROLE_ID;
export const IMAGE_KIT_BASE_URL = (process.env.REACT_APP_IMAGEKIT_BASE || 'https://ik.imagekit.io/demo').replace(
  /\/$/,
  '',
);


export const ALLOWED_FILE_UPLOAD = ['application/msword',
                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
                                    'application/vnd.ms-word.document.macroEnabled.12',
                                    'application/vnd.ms-excel',
                                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                    'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
                                    'application/vnd.ms-excel.sheet.macroEnabled.12',
                                    'application/vnd.ms-excel.template.macroEnabled.12',
                                    'application/vnd.ms-excel.addin.macroEnabled.12',
                                    'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
                                    'application/vnd.ms-powerpoint',
                                    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                                    'application/vnd.openxmlformats-officedocument.presentationml.template',
                                    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
                                    'application/vnd.ms-powerpoint.addin.macroEnabled.12',
                                    'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
                                    'application/vnd.ms-powerpoint.template.macroEnabled.12',
                                    'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
                                    'text/plain',
                                    'application/pdf']