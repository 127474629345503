import * as React from 'react';
import { Labeled, useChoicesContext, useInput, useDataProvider } from 'react-admin';
import { useCallback, useMemo } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import AccordionDetails from '@mui/material/AccordionDetails';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import SideBySideView from '../SideBySide/SideBySideView';
import { useTranslate } from 'ra-core';

export function GroupedRegionDepartmentSelectArrayInput(props: any) {
  const {
    choices: choicesProp,
    className,
    create,
    createLabel,
    createValue,
    format,
    helperText,
    labelKey = 'name',
    groupBy,
    sortBy,
    label,
    isFetching: isFetchingProp,
    isLoading: isLoadingProp,
    onBlur,
    onChange,
    onCreate,
    parse,
    resource: resourceProp,
    source: sourceProp,
    validate,
    extractGroupLabel,
    regionsLabel,
    noRegionsLabel,
    ...rest
  } = props;

  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const [regions, setRegions] = React.useState<any[]>();
  const [expanded, setExpanded] = React.useState<string>('');
  const { allChoices, source, resource } = useChoicesContext({
    choices: choicesProp,
    isLoading: isLoadingProp,
    isFetching: isFetchingProp,
    resource: resourceProp,
    source: sourceProp,
  });

  const input = useInput({
    format,
    onBlur,
    onChange,
    parse,
    resource,
    source,
    validate,
    ...rest,
  });

  const { field, fieldState, isRequired } = input;

  React.useEffect(() => {
    dataProvider.getList('regions', {
      pagination: { page: 1, perPage: 99999 },
      sort: { field: 'name', order: 'ASC' },
      filter: null
    })
        .then(({ data }) => {
          data.forEach(region => {
            if(field.value) {
              const checked = region.departmentIds.every(departmentId => field.value.includes(departmentId))
              region['checked'] = checked
              region['indeterminate'] = region.departmentIds.some(departmentId => field.value.includes(departmentId))
            }
            else {
              region['checked'] = false
              region['indeterminate'] = false
            }
          })
          setRegions(data);

        });
  }, [dataProvider]);

  const handleAccordionChange = useCallback(
    (panel) => (_: any, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    },
    [],
  );

  const groupedMap = useMemo(() => {
    const idMap = (field.value || []).reduce((acc, id) => {
      acc[id] = true;

      return acc;
    }, {});

    const reduced = (allChoices || []).reduce((acc, choice) => {
      if (!acc[choice[groupBy]])
        acc[choice[groupBy]] = { selectedCount: 0, options: [], label: extractGroupLabel(choice) };

      acc[choice[groupBy]].options.push({
        id: choice.id,
        label: choice[labelKey],
        checked: idMap[choice.id],
      });

      if (idMap[choice.id]) acc[choice[groupBy]].selectedCount++;

      return acc;
    }, {});

    const values = [];

    for (const key in reduced) {
      reduced[key].id = key;
      reduced[key]?.options.sort((a, b) => {
        return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
      });

      values.push(reduced[key]);
    }

    return values;
  }, [field, allChoices]);

  const handleRegionChange =  useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      const region = regions.find(x=> x.id === event.target.value);
      if(!region) return;
      const newValues = [...(field.value || [])];

      region.departmentIds.forEach(departmentId => {
        const index = newValues.indexOf(departmentId);

        if (!region.checked) {
          if (index === -1) newValues.push(departmentId);
        } else {
          if (index === -1) return; // weird.
  
          newValues.splice(index, 1);
        }
  
      });

      region.checked = !region.checked
      region.indeterminate = false

      field.onChange(newValues);
    },
    [field, setRegions],
  );

  const handleChange = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();

      const choice = event.target.value;
      const newValues = [...(field.value || [])];
      const index = newValues.indexOf(choice);

      if (event.target.checked) {
        if (index === -1) newValues.push(choice);
      } else {
        if (index === -1) return; // weird.

        newValues.splice(index, 1);
      }

      regions.forEach(region => {
        const checked = region.departmentIds.every(departmentId => newValues.includes(departmentId))

        region['checked'] = checked
        region['indeterminate'] = !checked && region.departmentIds.some(departmentId => newValues.includes(departmentId))
      })

      field.onChange(newValues);
    },
    [field, regions],
  );

  const checkAll = useCallback(
    (group) => {
      // group.options{.id}
      const newValues = [...(field.value || [])];

      group.options.forEach((option) => {
        const index = newValues.indexOf(option.id);

        if (index === -1) newValues.push(option.id);
      });

      regions.filter(x=> x.ltoId === group.id).forEach(region => {
        const checked = region.departmentIds.every(departmentId => newValues.includes(departmentId))

        region['checked'] = true
        region['indeterminate'] = false
      })

      field.onChange(newValues);
    },
    [field,regions],
  );

  const uncheckAll = useCallback(
    (group) => {
      const newValues = [...(field.value || [])];

      group.options.forEach((option) => {
        const index = newValues.indexOf(option.id);

        if (index !== -1) newValues.splice(index, 1);
      });

      regions.filter(x=> x.ltoId === group.id).forEach(region => {
        region['checked'] = false
        region['indeterminate'] = false
      })

      field.onChange(newValues);
    },
    [field, regions],
  );

  if(!regions) return null

  return (
    <Labeled
      isRequired={isRequired}
      label={label}
      id={`grouped-label`}
      color={fieldState?.error ? 'error' : undefined}
      style={{ marginBottom: '2em' }}
      source={source}
      resource={resource}
    >
      <>
        {groupedMap?.map((group: any) => (
          <Accordion
            expanded={expanded === group.label}
            onChange={handleAccordionChange(group.label)}
            key={`group_${group.label}`}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
              <Typography>
                {group.label}
                <Typography variant="caption" padding={1}>
                  ({group.selectedCount}/{group.options.length})
                </Typography>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>

              <SideBySideView 
                LeftComponent={
                  <>
                  <Typography>{translate(props.regionsLabel)}</Typography>
                   <FormGroup>
                      {regions.filter(x=> x.ltoId === group.id).map(({checked, indeterminate, id, name}: {checked: boolean, indeterminate: boolean, id: string, name: string}) => {
                          return (
                            <FormControlLabel
                              key={`label_${id}`}
                              control={<Checkbox onClick={handleRegionChange} indeterminate={indeterminate} checked={checked} name={id} value={id} />}
                              label={name}
                            />
                          );
                        }
                      )}
                      {regions.filter(x=> x.ltoId === group.id).length === 0 ? <Typography variant='caption'>{translate(props.noRegionsLabel)}</Typography>: null}
                    </FormGroup>
                  </>
                }
                RightComponent={
                  <>
                    <Typography>Afdelingen</Typography>
                    <FormHelperText>
                      {!group.options.every(option => field.value && field.value.indexOf(option.id) !== -1) ? 
                        <Link
                          href="#"
                          color="inherit"
                          onClick={(e) => {
                            e.preventDefault();
                            checkAll(group);
                          }}
                        >
                          { translate('lto.generic.select_all') }
                        </Link>
                      :
                        <Link
                          href="#"
                          color="inherit"
                          onClick={(e) => {
                            e.preventDefault();
                            uncheckAll(group);
                          }}
                        >
                          { translate('lto.generic.deselect_all') }
                        </Link>
                      }
                      
                    </FormHelperText>
                    <FormGroup>
                      {group.options.map(
                        ({ checked = false, label, id }: { id: string; checked?: boolean; label: string }) => {
                          return (
                            <FormControlLabel
                              key={`label_${id}`}
                              control={<Checkbox onChange={handleChange} checked={checked} name={id} value={id} />}
                              label={label}
                            />
                          );
                        },
                      )}
                    </FormGroup>
                    <FormHelperText>
                      {!group.options.every(option => field.value && field.value.indexOf(option.id) !== -1) ? 
                        <Link
                          href="#"
                          color="inherit"
                          onClick={(e) => {
                            e.preventDefault();
                            checkAll(group);
                          }}
                        >
                          { translate('lto.generic.select_all') }
                        </Link>
                      :
                        <Link
                          href="#"
                          color="inherit"
                          onClick={(e) => {
                            e.preventDefault();
                            uncheckAll(group);
                          }}
                        >
                          { translate('lto.generic.deselect_all') }
                        </Link>
                      }
                      
                    </FormHelperText>
                  </>
                }
              />
            </AccordionDetails>
          </Accordion>
        ))}
      </>
    </Labeled>
  );
}
