import React, { useMemo } from 'react';
import {
  ArrayInput,
  BooleanInput,
  FormDataConsumer,
  FormTab,
  Labeled,
  ReferenceInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TabbedFormProps,
  TextInput,
  useGetList,
} from 'react-admin';
import { GroupedRegionDepartmentSelectArrayInput } from '../../components/Input/GroupedRegionDepartmentSelectArrayInput';
import { NoDeleteToolbar } from '../../components/NoDeleteToolbar';
import { ReferenceArrayInput } from '../../components/Input/ReferenceArrayInput';
import { DateTimePicker } from '../../components/Input/DateTimePickerInput';
import { fromUTC } from '../../lib/i18n/utils';

const rules = {
  title: [required()],
  description: [required()],
  topicId: [required()],
  departments: [required()],
};

export function SurveyForm(props: Partial<TabbedFormProps>) {
  const { data, isLoading } = useGetList('ltos');

  const ltoMap = useMemo(
    () =>
      data?.reduce((acc, lto) => {
        acc[lto.id] = lto.name;

        return acc;
      }, {}),
    [data],
  );

  if (isLoading) return null;

  return (
    <TabbedForm toolbar={<NoDeleteToolbar />} warnWhenUnsavedChanges {...props}>
      <FormTab label="resources.surveys.tabs.placement">
        <TextInput source="title" validate={rules.title} helperText={false} />
        <ReferenceInput source="topicId" reference="topics" perPage={99999} sort={{ field: 'name', order: 'ASC'}}>
          <SelectInput label={"resources.surveys.fields.topic"} optionText="name" validate={rules.topicId} />
        </ReferenceInput>
        <ReferenceArrayInput source="departmentIds" reference="departments" perPage={99999} required>
          <GroupedRegionDepartmentSelectArrayInput
            extractGroupLabel={({ ltoId }) => ltoMap[ltoId]}
            groupBy="ltoId"
            sortBy="name"
            label={'resources.news.fields.departments'}
            validate={rules.departments}
            regionsLabel={'resources.news.fields.regions'}
            noRegionsLabel={'resources.news.fields.noRegions'}
          />
        </ReferenceArrayInput>
        <TextInput source="description" multiline validate={rules.description} />
        <TextInput source="outro" multiline />
        <BooleanInput source="isPublished" label="Open" />
        <Labeled
          label={"resources.surveys.fields.extra_options"}
        >
          <></>
        </Labeled>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return(<DateTimePicker source="publishDate" value={formData.publishDate ? fromUTC(formData.publishDate) : null} validate={null} label="resources.surveys.fields.publishAt" />)
          }}
        </FormDataConsumer>
        <br />
        <BooleanInput source="showRealtimeResults"/>
      </FormTab>
      <FormTab label="resources.surveys.tabs.questions">
        <ArrayInput source="questions" label={null}>
          <SimpleFormIterator>
            <SelectInput
              source="type"
              label="resources.surveys.fields.type"
              choices={[
                { id: 'Text', name: 'Feedback' },
                { id: 'MultipleChoice', name: 'Multiple-choice' },
                { id: 'Open', name: 'Open vraag' },
              ]}
            />
            <TextInput source="title" label="resources.surveys.fields.title" />

            <FormDataConsumer>
              {({ getSource, scopedFormData }) => {
                if (scopedFormData?.type === 'Text')
                  return <TextInput source={getSource('text')} label="resources.surveys.fields.text" multiline />;
                  if (scopedFormData?.type === 'Open') return (
                    <>
                      <BooleanInput source={getSource('mandatory')} label="resources.surveys.fields.mandatory" />
                      <TextInput source={getSource('maximumLength')} label="resources.surveys.fields.maximum_length" />
                    </>
                  );
                  if (scopedFormData?.type !== 'MultipleChoice') return null;

                return (
                  <ArrayInput
                    source={getSource('options')}
                    label={scopedFormData.options?.length > 0 ? 'resources.surveys.fields.options' : ''}
                  >
                    <SimpleFormIterator>
                      <TextInput source={'text'} label="resources.surveys.fields.option" />
                    </SimpleFormIterator>
                  </ArrayInput>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  );
}
