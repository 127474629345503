import React from 'react';
import { Create } from 'react-admin';
import { SurveyForm } from './SurveyForm';
import { fromGenericToUTC } from '../../lib/i18n/utils';

export const SurveyCreate = (props: any) => {
  const transform = data => ({
    ...data,
    publishDate: data.publishDate ? fromGenericToUTC(data.publishDate) : null,
  });

  return (
    <Create {...props} transform={transform}>
      <SurveyForm />
    </Create>
  );
};
