import {
  ArrayInput,
  BooleanInput,
  DateField,
  DateInput,
  FormDataConsumer,
  FormTab,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  useDataProvider,
} from 'react-admin';
import { useCallback } from 'react';
import {useFormContext} from 'react-hook-form'
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

import * as React from 'react';
import { APP_ROLE_ID } from '../../lib/constants';
import { useTranslate } from 'ra-core';
import { ReferenceArrayInput } from '../../components/Input/ReferenceArrayInput';

export function ProfileTab({ profileIndex, name, profileType, isBeursGoes, ...props }) {
  const dataProvider = useDataProvider();
  const [regions, setRegions] = React.useState<any[]>();
  const { loading, isBeursGoesMember } = isBeursGoes;    
  const translate = useTranslate();
  const {setValue} = useFormContext();
  React.useEffect(() => {
    dataProvider.getList('regions', {
      pagination: { page: 1, perPage: 99999 },
      sort: { field: 'name', order: 'ASC' },
      filter: null
    })
    .then(({ data }) => {
      setRegions(data);
    });
  }, [dataProvider]);

  const handleRegionChange =  useCallback(
    ({event, id, checked, indeterminate, departmentIds, regions, departmentIdsSource}) => {
      event.stopPropagation();
      event.preventDefault();
      const region = regions.find(x=> x.id === id);
      if(!region) return;
      const newValues = [...(departmentIds || [])];

      region.departmentIds.forEach(departmentId => {
        const index = newValues.indexOf(departmentId);
        if (!checked && !indeterminate) {
          if (index === -1) newValues.push(departmentId);
        } else {
          if (index === -1) return; // weird.
  
          newValues.splice(index, 1);
        }
  
      });
      setValue(departmentIdsSource, newValues);
    },
    [setValue],
  );

  if (!regions) return null;
  if (loading) return null;

  return (
    <FormTab label={name} {...props}>
      <BooleanInput source={`profiles.${profileIndex}.isActive`} label="resources.profiles.fields.isActive" />
      {isBeursGoesMember ? (
        <BooleanInput
          source={`profiles.${profileIndex}.isBeursGoesMember`}
          label="resources.profiles.fields.isBeursGoesMember"
        />
      ) : null}

      <FormDataConsumer>
        {({ formData, ...rest }) => {
          if (!formData.profiles[profileIndex].isActive) return null;
          return (
            <>
              {profileType === 'Dashboard' ? (
                <ReferenceInput
                  label="Role"
                  source={`profiles.${profileIndex}.roleId`}
                  filter={{ id_ne: APP_ROLE_ID }}
                  reference="roles"
                >
                  <SelectInput optionText="name" label="Rol" required />
                </ReferenceInput>
              ) : null}

              <ArrayInput
                source={`profiles.${profileIndex}.memberships`}
                label={translate('resources.memberships.name', { smart_count: 2 })}
              >
                <SimpleFormIterator disableReordering>
                  <ReferenceInput source="ltoId" reference="ltos">
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                  <FormDataConsumer>
                    {({ formData, getSource, scopedFormData, ...rest }) => {
                      const departmentIdsSource = getSource("departmentIds")
                      const { fromDate, toDate} = scopedFormData
                      if (!scopedFormData.ltoId) {
                        // @todo clear departmentIds on changing lto. There's a bug here still.
                        return null;
                      }

                      return (
                        <>
                          { regions.some(x=> x.ltoId === scopedFormData.ltoId) ? (
                            <>
                              <Typography>{translate('resources.regions.name', { smart_count: 2 })}</Typography>
                              <FormGroup>
                                {regions.filter(x=> x.ltoId === scopedFormData.ltoId).map((region) => {
                                    const {id, name } = region;
                                    const checked = region.departmentIds.every(departmentId => scopedFormData.departmentIds && scopedFormData.departmentIds.includes(departmentId));
                                    const indeterminate = !checked && region.departmentIds.some(departmentId => scopedFormData.departmentIds && scopedFormData.departmentIds.includes(departmentId));
                                    return (
                                      <FormControlLabel
                                        key={`label_${id}`}
                                        control={<Checkbox onClick={(event)=> handleRegionChange({event, id, checked, indeterminate, departmentIds: scopedFormData.departmentIds, regions, departmentIdsSource})} indeterminate={indeterminate} checked={checked} name={id} value={id} />}
                                        label={name}
                                      />
                                    );
                                  }
                                )}
                                {regions.filter(x=> x.ltoId === scopedFormData.id).length === 0 ? <Typography variant='caption'>{translate('resources.users.fields.noRegions')}</Typography>: null}
                              </FormGroup>
                            </>
                          ) :null }
                          <ReferenceArrayInput
                            source={getSource('departmentIds')}
                            reference="departments"
                            perPage={99999}
                            filter={{ ltos: [scopedFormData.ltoId] }}
                            sort={{ field: 'name', order: 'ASC' }}
                          >
                            <SelectArrayInput
                              optionText="name"
                              label={translate('resources.departments.name', { smart_count: 2 })}
                            />
                          </ReferenceArrayInput>
                          <div style={{ display: 'none'}}>
                            {scopedFormData.crmId ? <TextInput source={getSource("CrmId")} /> : null }
                            {scopedFormData.number ? <TextInput source={getSource("Number")} /> : null }
                            <DateInput source={getSource("fromDate")} />
                            <DateInput source={getSource("toDate")} />
                          </div>
                          <div style={{marginBottom: '15px'}}>
                            {fromDate && toDate 
                              ? <>Geldig van <DateField style={{fontWeight: 'bold'}} source={getSource("fromDate")} locales="nl-NL" /> t/m <DateField source={getSource("toDate")} locales="nl-NL" style={{fontWeight: 'bold'}} /></>
                              : <>Geldig van <DateField style={{fontWeight: 'bold'}} source={getSource("fromDate")} locales="nl-NL" /></>
                            }
                          </div> 
                        </>
                      );
                    }}
                  </FormDataConsumer>
                </SimpleFormIterator>
              </ArrayInput>
            </>
          );
        }}
      </FormDataConsumer>
    </FormTab>
  );
}
