import * as React from 'react';
import { List, Datagrid, TextField, ReferenceField, Button, FunctionField, useRefresh } from 'react-admin';
import { useTranslate } from 'ra-core';

import { useCallback, useState } from 'react';
import { EmptyStatus } from './EmptyStatus';
import { MigrateDialog } from './MigrateDialog';

export const ImportList = (props: any) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const [migrate, setMigrate] = useState(null);
  const openMigrate = useCallback(
    (e, record) => {
      e.preventDefault();
      e.stopPropagation();

      setMigrate(record);
    },
    [setMigrate],
  );

  return (
    <>
      <List {...props} empty={<EmptyStatus />} hasCreate={false}>
        <Datagrid rowClick={null} bulkActionButtons={null}>
          <ReferenceField label="LTO" source="ltoId" reference="ltos" link={false}>
            <TextField source="name" />
          </ReferenceField>
          <TextField source="name" />
          <TextField source="state" />

          <FunctionField
            render={(record) => {
              return (
                <Button
                  onClick={(e) => openMigrate(e, record)}
                  label={translate('lto.screens.import_status.list_button_migrate')}
                />
              );
            }}
          />
        </Datagrid>
      </List>
      <MigrateDialog refresh={refresh} data={migrate} onClose={() => setMigrate(null)} />
    </>
  );
};
