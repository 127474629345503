import * as React from 'react';
import { useEditController } from 'react-admin';
import { NewsForm } from './NewsForm';
import { Edit } from '../../components/Edit';
import { RecordEditTitle } from '../../components/RecordTitle';
import {  transformMediaUrlInput } from '../../lib/media/makeUrl'
import { fromGenericToUTC } from '../../lib/i18n/utils';

export const NewsEdit = (props: any) => {
  const { record, isLoading } = useEditController();
  const transform = data => ({
    ...data,
    publishedAt: fromGenericToUTC(data.publishedAt),
    agendaIds: data.agendaIds ?? [],
    mediaUrl: transformMediaUrlInput(data.mediaUrl)
  });

  if (isLoading || !record.createdBy) return null;

  return (
    <Edit meta={{ embed: 'media' }} title={<RecordEditTitle property="title" />} {...props} transform={transform}>
      <NewsForm username={record.createdBy.name} hideSendPushNotification hideAgenda />
    </Edit>
  )
};
