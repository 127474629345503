import * as React from 'react';
import { TopicForm } from './TopicForm';
import { Edit } from '../../components/Edit';
import { RecordEditTitle } from '../../components/RecordTitle';
import {  transformMediaUrlInput } from '../../lib/media/makeUrl'

export function TopicEdit(props: any) {
  const transform = data => ({
    ...data,
    mediaUrl: transformMediaUrlInput(data.mediaUrl)
  });
  
  return (
    <Edit title={<RecordEditTitle />} meta={{ embed: 'media' }} {...props} transfor={transform}>
      <TopicForm />
    </Edit>
  );
}
