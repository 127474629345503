import {useState, useEffect} from 'react';
import { Edit } from 'react-admin';
import { Product } from '../types';
import { PriceListingForm } from './PriceListingForm';
import { dataProvider } from '../../lib/dataProvider';

export const PriceListingEdit = (props: any) => {
  const [products, setProducts] = useState<Product[]>();
  useEffect(() =>  {    
    async function getProducts() {
      const data = await dataProvider.get("/products");
      setProducts(data);
    }
    getProducts();
  }, [setProducts]);

  if (!products) return null;

  return(
    <Edit {...props}>
      <PriceListingForm products={products} />
    </Edit>
  )
};
