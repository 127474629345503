import ImageIcon from '@mui/icons-material/Image';
import { GuardedComponents } from '../../lib/permissions/types';
import { ResourceDefinition } from '../types';
import { MediaList, MediaShow } from '.';

export default {
  permissionResource: 'Media',
  name: 'media',
  icon: ImageIcon,
  hideInNav: true,
  components: new GuardedComponents([['list', { action: 'GetList', component: MediaList }]]),
  i18n: {
    nl: {
      name: 'Media |||| Media',
      fields: {
        name: 'Naam',
      },
    },
  },
} as ResourceDefinition;
