import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { GuardedComponents } from '../../lib/permissions/types';
import { ResourceDefinition } from '../types';
import { DeviceCreate, DeviceEdit, DeviceList, DeviceShow } from '.';

export default {
  permissionResource: 'Device',
  name: 'devices',
  icon: QuestionMarkIcon,
  hideInNav: false,
  superAdminOnly: true,
  components: new GuardedComponents([
    ['list', { action: 'GetList', component: DeviceList }],
    ['edit', { action: 'Update', component: DeviceEdit }],
    ['create', { action: 'Create', component: DeviceCreate }],
  ]),
  i18n: {
    nl: {
      name: 'Apparaat |||| Apparaten',
      fields: {
        name: 'Naam',
        type: 'Soort',
        token: 'Token',
      },
    },
  },
} as ResourceDefinition;
