import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { useSearchParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { Auth } from 'aws-amplify';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Loading, useLogin, useNotify } from 'react-admin';
import { dataProvider } from '../../lib/dataProvider';
import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useTranslate } from 'ra-core';

enum ChallengeError {
  Invalid = 'INVALID_CHALLENGE_TOKEN',
  RateLimit = 'RATE_LIMIT_EXCEEDED',
}

enum SetNewPasswordError {
  Unknown = 'UNKNOWN_ERROR',
  InvalidPassword = 'INVALID_PASSWORD',
}

function useIsValidChallenge(email: string, challenge: string) {
  const [error, setError] = useState<ChallengeError>(null);
  const [loading, setLoading] = useState(true);
  const [isValid, setIsValid] = useState(null);

  useEffect(() => {
    async function CheckChallenge() {
      try {
        await dataProvider.post('/users/verify-challenge', {
          emailAddress: email,
          challenge,
          scope: 'AcceptInvite',
        });

        setError(null);
        setLoading(false);
        setIsValid(true);
      } catch (e) {
        setError(e.status === 429 ? ChallengeError.RateLimit : ChallengeError.Invalid);
        setLoading(false);
        setIsValid(false);
      }
    }

    CheckChallenge();
  }, [email, challenge, setIsValid, setError, setLoading]);

  return useMemo(() => ({ isValid, loading, error }), [isValid, loading, error]);
}

function useSetPassword() {
  const [error, setError] = useState<SetNewPasswordError>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(null);

  const setNewPassword = useCallback(
    async (email: string, challenge: string, newPassword: string) => {
      try {
        await dataProvider.post('/users/accept-invite', {
          emailAddress: email,
          challenge,
          password: newPassword,
        });

        await Auth.signIn(email, newPassword);

        window.location.href = '/';

        setSuccess(true);
        setError(null);
      } catch (e) {
        setSuccess(false);
        setError(
          e?.body?.error === 'invalid_password' ? SetNewPasswordError.InvalidPassword : SetNewPasswordError.Unknown,
        );
      }

      setLoading(false);
    },
    [setSuccess, setError, setLoading],
  );

  return useMemo(() => ({ setNewPassword, success, loading, error }), [setNewPassword, success, loading, error]);
}

/**
 * @todo add error handling with more informative messages.
 * @todo fix the need to reload when changing auth stuff. But honestly nobody cares enough to fix this.
 */
export function AcceptInvite() {
  const [searchParams] = useSearchParams();
  const translate = useTranslate();
  const email = searchParams.get('email');
  const challenge = searchParams.get('challenge');
  const [newPassword, setNewPassword] = useState('');
  const login = useLogin();
  const notify = useNotify();
  const challengeState = useIsValidChallenge(email, challenge);
  const passwordState = useSetPassword();

  useEffect(() => {
    if (passwordState.success) {
      notify('Wachtwoord ingesteld! Je wordt nu ingelogd.');
    }
  }, [newPassword, email, login, notify, passwordState]);

  if (challengeState.loading || passwordState.loading) return <Loading />;
  if (challengeState.error || (passwordState.error && passwordState.error !== SetNewPasswordError.InvalidPassword)) {
    return (
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Error
        </Typography>
        <Typography component="p" align={'center'}>
          {challengeState.error === ChallengeError.Invalid
            ? 'Ongeldige challenge'
            : challengeState.error === ChallengeError.RateLimit
            ? 'Teveel pogingen. Wacht een paar minuten en probeer het opnieuw.'
            : 'Onbekende fout'}
        </Typography>
      </Container>
    );
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const password = data.get('password') as string;

    passwordState.setNewPassword(email, challenge, password);
    setNewPassword(password);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {translate(`lto.screens.accept_invite.title`)}
        </Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          {translate('lto.screens.accept_invite.description')}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            label={translate(`lto.screens.accept_invite.email`)}
            name="email"
            type="text"
            disabled
            id="email"
            autoComplete="username"
            defaultValue={email}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            error={!!passwordState.error}
            helperText={!!passwordState.error ? translate(`lto.screens.accept_invite.error_invalid_password`) : null}
            name="password"
            inputProps={{
              minLength: 8,
              pattern: '^(?=.*[A-Z])(?=.*[\\^$*.[\\]{}()?\\-!@#%&\\/,><"\':;|_~`+=])(?=.*[0-9])(?=.*[a-z]).{8,}$',
              passwordrules:
                'minlength: 8; maxlength: 20; required: lower; required: upper; required: digit; required: [!"#$%&\'()*+,./:;<=>?@[^_`{|}~]];',
            }}
            label={translate(`lto.screens.accept_invite.password`)}
            type="password"
            autoComplete="new-password"
            id="password"
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            {translate(`lto.screens.accept_invite.submit`)}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
