import { Show, SimpleShowLayout, TextField, ReferenceField, NumberField, BooleanField } from 'react-admin';

export const PriceListingShow = () => (
  <Show>
    <SimpleShowLayout>
      <BooleanField source="isEnabled" />
      <ReferenceField label={"resources.price-listings.fields.topic"} source="topicId" reference="topics">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" />
      <TextField source="variant" />
      <NumberField source="minimumPrice" locales="nl-NL" options={{ style: 'currency', currency: 'EUR' }} />
      <NumberField source="maximumPrice" locales="nl-NL" options={{ style: 'currency', currency: 'EUR' }} />
    </SimpleShowLayout>
  </Show>
);
