import * as React from 'react';
import { ReferenceInput, SelectInput, SimpleForm, TextInput, FormDataConsumer, ReferenceArrayInput, SelectArrayInput } from 'react-admin';
import { useTranslate } from 'ra-core';

export const RegionForm = (props: any) => {
  const translate = useTranslate()
  return (
    <SimpleForm>
      <TextInput source="name" required />
      <ReferenceInput label="LTO" source="ltoId" reference="ltos">
        <SelectInput optionText="name" required />
      </ReferenceInput>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          if (!formData.ltoId) {
            return null;
          }
          return (
            <ReferenceArrayInput
              source="departmentIds"
              reference="departments"
              perPage={99999}
              filter={{ ltos: [formData.ltoId] }}
              sort={{field: 'name', order: 'ASC'}}
            >
              <SelectArrayInput
                optionText="name"
                label={translate('resources.departments.name', { smart_count: 2 })}
              />
            </ReferenceArrayInput>
          )
        }}
      </FormDataConsumer>
    </SimpleForm>
  )
}
