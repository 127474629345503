import CollectionsIcon from '@mui/icons-material/Collections';
import { GuardedComponents } from '../../lib/permissions/types';
import { ResourceDefinition } from '../types';
import { SectorCreate, SectorEdit, SectorList, SectorShow } from '.';

export default {
  permissionResource: 'Sector',
  name: 'sectors',
  icon: CollectionsIcon,
  components: new GuardedComponents([
    ['list', { action: 'GetList', component: SectorList }],

    ['edit', { action: 'Update', component: SectorEdit }],
    ['create', { action: 'Create', component: SectorCreate }],
  ]),
  i18n: {
    nl: {
      name: 'Sector |||| Sectoren',
      fields: {
        name: 'Naam',
      },
    },
  },
} as ResourceDefinition;
