import { forwardRef } from 'react';
import { undoableEventEmitter, useAuthProvider, useNotify, usePermissions, useRefresh, useUserMenu } from 'react-admin';
import MenuItem from '@mui/material/MenuItem';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';

/**
 * @todo refactor to ProfileItem and move loop to CustomMenu. Otherwise keyboard doesn't work.
 */
export const ListProfiles = forwardRef((props: any, ref: any) => {
  const { onClose } = useUserMenu();
  const notify = useNotify();
  const refresh = useRefresh();
  const navigate = useNavigate();
  const authProvider = useAuthProvider();
  const { isLoading, permissions } = usePermissions();

  if (isLoading) return null;

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'grey.400', pb: 0.5 }}>
      {permissions.profiles.map((profile: any) => (
        <MenuItem
          ref={ref}
          key={`identity_${profile.id}`}
          disabled={authProvider.getActiveProfile() === profile.id}
          // It's important to pass the props to allow MUI to manage the keyboard navigation
          {...props}
          sx={{ color: 'text.secondary' }}
          onClick={async (event) => {
            undoableEventEmitter.once('end', ({ isUndo }) => {
              if (isUndo) return;

              authProvider.setActiveProfile(profile.id);

              window.location.href = '/';
            });

            notify('Profiel is gewijzigd, de content wordt herladen.', {
              type: 'success',
              autoHideDuration: 2000,
              undoable: true,
            });

            onClose(); // Close the menu
          }}
        >
          <ListItemIcon>
            <FingerprintIcon />
          </ListItemIcon>
          <ListItemText>{profile.name}</ListItemText>
        </MenuItem>
      ))}
    </Box>
  );
});
