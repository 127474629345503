import { useCallback, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { FilterPayload, RaRecord, SortPayload, useGetList, ChoicesContextValue } from 'react-admin';
import { useReferenceParams } from './useReferenceParams';
import { UseQueryOptions } from 'react-query';

/**
 * @todo maybe something less disgusting than this solution.
 *   this basically just disables the getMany altogether.
 * Prepare data for the ReferenceArrayInput components
 *
 * @example
 *
 * const { allChoices, availableChoices, selectedChoices, error, isFetching, isLoading } = useReferenceArrayInputController({
 *      record: { referenceIds: ['id1', 'id2']};
 *      reference: 'reference';
 *      resource: 'resource';
 *      source: 'referenceIds';
 * });
 *
 * @param {Object} props
 * @param {Object} props.record The current resource record
 * @param {string} props.reference The linked resource name
 * @param {string} props.resource The current resource name
 * @param {string} props.source The key of the linked resource identifier
 *
 * @param {Props} props
 *
 * @return {Object} controllerProps Fetched data and callbacks for the ReferenceArrayInput components
 */
export const useReferenceArrayInputController = <RecordType extends RaRecord = any>(
  props: UseReferenceArrayInputParams<RecordType>,
): ChoicesContextValue<RecordType> & { isFromReference?: boolean } => {
  const {
    debounce,
    enableGetChoices,
    filter,
    page: initialPage = 1,
    perPage: initialPerPage = 25,
    sort: initialSort = { field: 'id', order: 'DESC' },
    queryOptions = {},
    reference,
    source,
  } = props;
  const { getValues } = useFormContext();
  // When we change the defaultValue of the child input using react-hook-form resetField function,
  // useWatch does not seem to get the new value. We fallback to getValues to get it.
  const value = useWatch({ name: source }) ?? getValues(source);

  /**
   * Get the records related to the current value (with getMany)
   */
  const [params, paramsModifiers] = useReferenceParams({
    resource: reference,
    page: initialPage,
    perPage: initialPerPage,
    sort: initialSort,
    debounce,
    filter,
  });

  // filter out not found references - happens when the dataProvider doesn't guarantee referential integrity
  // const finalReferenceRecords = referenceRecords ? referenceRecords.filter(Boolean) : [];

  const isGetMatchingEnabled = enableGetChoices ? enableGetChoices(params.filterValues) : true;

  const {
    data: matchingReferences,
    total,
    pageInfo,
    error: errorGetList,
    isLoading: isLoadingGetList,
    isFetching: isFetchingGetList,
    refetch: refetchGetMatching,
  } = useGetList<RecordType>(
    reference,
    {
      pagination: {
        page: params.page,
        perPage: params.perPage,
      },
      sort: { field: params.sort, order: params.order },
      filter: { ...params.filter, ...filter },
    },
    { retry: false, enabled: isGetMatchingEnabled, ...queryOptions },
  );

  // We merge the currently selected records with the matching ones, otherwise
  // the component displaying the currently selected records may fail
  const finalMatchingReferences = matchingReferences;

  const refetch = useCallback(() => {
    refetchGetMatching();
  }, [refetchGetMatching]);

  const currentSort = useMemo(
    () => ({
      field: params.sort,
      order: params.order,
    }),
    [params.sort, params.order],
  );
  return {
    sort: currentSort,
    allChoices: finalMatchingReferences,
    availableChoices: matchingReferences,
    selectedChoices: value,
    displayedFilters: params.displayedFilters,
    error: errorGetList,
    filter,
    filterValues: params.filterValues,
    hideFilter: paramsModifiers.hideFilter,
    isFetching: isFetchingGetList,
    isLoading: isLoadingGetList,
    page: params.page,
    perPage: params.perPage,
    refetch,
    resource: reference,
    setFilters: paramsModifiers.setFilters,
    setPage: paramsModifiers.setPage,
    setPerPage: paramsModifiers.setPerPage,
    setSort: paramsModifiers.setSort,
    showFilter: paramsModifiers.showFilter,
    source,
    total: total,
    hasNextPage: pageInfo ? pageInfo.hasNextPage : total != null ? params.page * params.perPage < total : undefined,
    hasPreviousPage: pageInfo ? pageInfo.hasPreviousPage : params.page > 1,
    isFromReference: true,
  };
};

export interface UseReferenceArrayInputParams<RecordType extends RaRecord = any> {
  debounce?: number;
  filter?: FilterPayload;
  queryOptions?: UseQueryOptions<{
    data: RecordType[];
    total?: number;
    pageInfo?: {
      hasNextPage?: boolean;
      hasPreviousPage?: boolean;
    };
  }>;
  page?: number;
  perPage?: number;
  record?: RecordType;
  reference: string;
  resource?: string;
  sort?: SortPayload;
  source: string;
  enableGetChoices?: (filters: any) => boolean;
}
