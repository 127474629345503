import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { GuardedComponents } from '../../lib/permissions/types';
import { ResourceDefinition } from '../types';
import { TransactionList } from '.';

export default {
  permissionResource: 'Transaction',
  name: 'transactions',
  icon: SyncAltIcon,
  components: new GuardedComponents([
    ['list', { action: 'GetList', component: TransactionList, onlyBeursGoes: true }],
  ]),
  i18n: {
    nl: {
      name: 'Transactie |||| Transacties',
      fields: {
        createdAt: 'Aangemaakt op',
        createdBy: 'Aangemaakt door',
        name: 'Product',
        variant: 'Variant',
        dateFrom_gte: "Vanaf datum",
        dateTo_lte: "Tot datum",
        productId: "Product",
        price: "Prijs",
        weight: "Gewicht",
        priceUnit: ""
      },
    },
  },
} as ResourceDefinition;
