export function fromUTC(value: string): Date {
  const [date, time] = value.split('T');
  const [year, month, day] = date.split('-').map(Number);
  const [hours, minutes, seconds] = time.split(':').map(Number);

  return new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));
}

export function fromGeneric(value: string): Date {
  const [date, time] = value.split('T');
  const [year, month, day] = date.split('-').map(Number);
  const [hours, minutes, seconds] = time.split(':').map(Number);

  return new Date(year, month - 1, day, hours, minutes, seconds);
}

export function fromUTCToGeneric(value: string): string {
  const [date, time] = value.split('T');
  const [year, month, day] = date.split('-').map(Number);
  const [hours, minutes, seconds] = time.split(':').map(Number);

  return new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds)).toISOString();
}

export function fromGenericToUTC(value: string): string {
  const [date, time] = value.split('T');
  const [year, month, day] = date.split('-').map(Number);
  const [hours, minutes, seconds] = time.split(':').map(Number);

  return new Date(year, month - 1, day, hours, minutes, seconds).toISOString();
}