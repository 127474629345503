import { Auth } from 'aws-amplify';
import { AuthProvider } from 'react-admin';
import { API_URL, SA, SA_ID } from './constants';

const ns = (key: string) => `__AUTH_PROVIDER_LTO::${key}`;

async function getAttributes() {
  const { attributes } = await Auth.currentAuthenticatedUser();

  return attributes;
}

export function getActiveProfile() {
  return localStorage.getItem(ns('active_profile'));
}

export function setActiveProfile(profile: string) {
  return localStorage.setItem(ns('active_profile'), profile);
}

export function makeAuthProvider(httpClient: any): AuthProvider {
  return {
    getActiveProfile,
    setActiveProfile,
    clearActiveProfile: () => localStorage.removeItem(ns('active_profile')),

    login: async ({ username, password }: Credentials) => Auth.signIn(username, password),
    logout: async () => {
      await Auth.signOut();

      localStorage.removeItem(ns('active_profile'));
      localStorage.clear();
    },
    checkAuth: async () => {
      try {
        await Auth.currentSession();

        return Promise.resolve();
      } catch (error) {
        return Promise.reject();
      }
    },
    checkError: (error) => {
      const status = error.status;

      if (status === 401 || status === 403) {
        return Promise.reject();
      }

      // other error code (404, 500, etc): no need to log out
      return Promise.resolve();
    },
    getIdentity: async () => {
      const { sub, email } = await getAttributes();
      const profile = getActiveProfile();
      const identity: any = { id: sub, email };

      if (profile) {
        try {
          const { json } = await httpClient(`${API_URL}/my/profiles?id=${profile}&_embed=User`);

          Object.assign(identity, { isBeursGoesMember: !!json[0]?.isBeursGoesMember, fullName: `${json[0]?.user?.firstName} ${json[0]?.user?.lastName}`.trim()  });
        } catch (error) {
          // @todo handle.
        }
      }

      return identity;
    },
    getPermissions: async () => {
      try {
        await Auth.currentSession();
        const profilesRes = await httpClient(`${API_URL}/my/profiles`);

        const findActiveProfile = () =>
          profilesRes.json.find((profile) => profile.type === 'Dashboard' && profile.isActive) ??
          profilesRes.json[0];
        const currentActiveProfile = localStorage.getItem(ns('active_profile'));

        const activeProfile = findActiveProfile();
        const activeProfileId = currentActiveProfile || activeProfile?.id;

        if (!currentActiveProfile) {
          localStorage.setItem(ns('active_profile'), activeProfileId);
        }

        const rolesRes = await httpClient(`${API_URL}/my/role`);
        return {
          profiles: profilesRes.json,
          permissions: rolesRes.json.id === SA_ID ? SA : rolesRes.json.permissions,
          beursGoes: activeProfile.isBeursGoesMember,
        };
      } catch (error) {
        await Auth.signOut();
        return null;
      }
    },
  };
}

interface Credentials {
  username: string;
  password: string;
}
