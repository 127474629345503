import { Edit, SimpleForm, FunctionField, TextInput, useTranslate } from 'react-admin';
import { NoDeleteToolbar } from '../../components/NoDeleteToolbar';
import { RichTextInput } from '../../components/RichTextJsonInput';
import { RichTextInputToolbar } from 'ra-input-rich-text';
import { CustomFormatButtons } from '../../components/FormatButtons';
import { RecordEditTitle } from '../../components/RecordTitle';

export const LtoEdit = (props: any) => {
  const translate = useTranslate();

  return (
    <Edit {...props} title={<RecordEditTitle property="name" />}>
      <SimpleForm toolbar={<NoDeleteToolbar />}>
        <TextInput source="id" disabled />
        <TextInput source="name" disabled />
        <FunctionField fontWeight='bold' source='contactInfo' render={() => translate('resources.ltos.fields.contactInfo')} />
        <RichTextInput
          source="contactText"
          contentType="json"
          toolbar={
            <RichTextInputToolbar>
              <CustomFormatButtons />
            </RichTextInputToolbar>
          }
        />
        <TextInput source="phoneNumber" />
        <TextInput source="email" />
        <TextInput source="whatsAppNumber" />
      </SimpleForm>
    </Edit>
  )
};
