import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField
} from 'react-admin';

export function RegionList(props: any) {
  return (
    <List queryOptions={{meta: { embed: 'lto' }}} exporter={false} sort={{ field: 'name', order: 'ASC' }}>
      <Datagrid rowClick="edit" bulkActionButtons={null}>
        <TextField source="name" sortBy='name' />
        <TextField source="lto.name" />
        <DateField source="createdAt" locales="nl-NL" />
        <TextField source="createdBy.name" sortBy='createdBy' />
      </Datagrid>
    </List>
  );
}
