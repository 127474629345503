import * as React from 'react';
import { Archive, Info } from '@mui/icons-material';
import { List, Datagrid, TextField, DateField, BooleanField, BooleanInput, Button, useListContext, TopToolbar, CreateButton, ExportButton, useListController, ReferenceField, WrapperField, useUnselectAll, useRecordContext, Labeled, useNotify, SearchInput, AutocompleteArrayInput, DateInput, ReferenceArrayInput, SelectInput, FilterButton} from 'react-admin';
import { dataProvider } from '../../lib/dataProvider';
import { useLocation } from 'react-router-dom';
import { usePermissionGuard } from '../../lib/permissions/usePermissionGuard';
import AgendaResource from './AgendaResource';

interface FilterValuesObject {
  isArchived: boolean
}
interface ArchiveActionProps {
  selectedIds?: string[]
  filterValues?: FilterValuesObject
}

const ArchiveItems = async (props: ArchiveActionProps) => {
  await dataProvider.put("/agenda/archive", { archive: !props.filterValues?.isArchived, ids: props.selectedIds})  
}

const PostBulkActionButtons = (props: ArchiveActionProps) => {
  const notify = useNotify();

  const unselectAll = useUnselectAll('agenda');
  const { refetch } = useListContext();
  return (
    <>
      <Button 
        label={!props.filterValues?.isArchived ? 'resources.agenda.fields.archive' : 'resources.agenda.fields.deArchive'} 
        startIcon={<Archive/>} 
        onClick={() => { 
            ArchiveItems(props).then(() => {
              unselectAll();
              refetch();
            });
          } 
        }
      />
      <Button 
        label={"resources.agenda.fields.exportRegistrations"}
        startIcon={<Archive/>} 
        onClick={() => { 
            ExportRegistrations(props.selectedIds, notify)
          } 
        }
      />
    </>
  )
};

const Download = (data, type, filename) => {
  const fakeLink = document.createElement('a');
  fakeLink.style.display = 'none';
  document.body.appendChild(fakeLink);
  // @ts-ignore

  fakeLink.setAttribute('href', `data:${type};base64,${data}`);
  fakeLink.setAttribute('download', `${filename}.xlsx`);
  fakeLink.click();

};

const ExportRegistrations = async (ids: string[], notify: (translation: string, params: any) => void) => {
  try{
    const data = await dataProvider.put("/agenda/export", { ids });
    Download(data, "application/octet-stream", "agenda-aanmeldingen-" + (new Date()).toLocaleString("nl-NL"))
  }
  catch {
    notify('lto.screens.agenda_list.export_error', { type: 'warning' });
  }
};

const postFilters = [
  <SearchInput source="_s" resettable alwaysOn />,
  <DateInput source='publishedAt_gte' />,
  <DateInput source='publishedAt_lte' />,
  //@ts-ignore api accepts comma-seperated order parameter value
  <ReferenceArrayInput source="createdBy" reference="users" filter={{ ProfileType: 'Dashboard' }} perPage={999} sort={{ field: 'firstName,lastName', order: 'ASC,ASC' }}>
    <AutocompleteArrayInput optionText={(option) => `${option.firstName} ${option.lastName}`} />
  </ReferenceArrayInput>,
  <ReferenceArrayInput source="lto" reference="ltos" sort={{ field: 'name', order: 'ASC' }}>
    <SelectInput optionText='name' />
  </ReferenceArrayInput>,
  <BooleanInput source="isArchived" alwaysOn className='center-boolean-input' />,
];

const ListActions = (props: any) => {
  return (
    <TopToolbar>
        <FilterButton />
        { props.hasCreate ? <CreateButton/> : null }
        <ExportButton maxResults={999999999}/>
    </TopToolbar>
  )
};

const InfoBlock = () => {
  return (
    <>
      <Info className='item-info-icon' />
      <div className='item-info-block'>
        <Labeled>
          <BooleanField source="allowRegistrations" />
        </Labeled>
        <br />
        <Labeled>
          <DateField source="start" showTime />
        </Labeled>
        <br />
        <Labeled>
          <DateField source="end" showTime />
        </Labeled>
      </div>
    </>
  )
}

const Grid = (prop: any) =>{
  const { guard } = usePermissionGuard();
  const canArchive = guard.isAllowed(AgendaResource.permissionResource, 'Archive');
  const location = useLocation();
  const { setFilters} = useListContext();

  React.useEffect(() => {
    if(location.search === '') {
      setFilters(prop.filterDefaultValues, [])
    }
  }, [location]);
  
  return (
    <Datagrid bulkActionButtons={canArchive ? <PostBulkActionButtons /> : null} rowClick="edit">
      <ReferenceField link={false} source="topicId" sortBy='topicId' reference="topics" label="resources.agenda.fields.topic">
        <TextField source="name" label="resources.agenda.fields.topic" />
      </ReferenceField>
      <TextField source="title" />
      <BooleanField source="isPinned"  />
      <WrapperField label="Info">
        <InfoBlock />
      </WrapperField>
      <DateField source="publishedAt" />
      <TextField source="createdBy.name" sortBy="createdBy" label="resources.news.fields.createdBy" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  )
}

export const AgendaList = (props: any) => {
  const listContext = useListController();

  return (
    <List actions={<ListActions />} debounce={500} hasCreate={!listContext.filterValues?.isArchived} filterDefaultValues={{ isArchived: false }} filters={postFilters}  filter={{ _embed: 'topic', _sf: 'title,description' }} {...props}>
      <Grid {...props} filterDefaultValues={{ isArchived: false, _sf: 'title,description' }} />
    </List>
  )
}