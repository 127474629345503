import * as React from 'react';
import { Create, ReferenceArrayInput, SimpleForm, SortPayload, TextInput } from 'react-admin';
import { GroupedReferenceSelectArrayInput } from '../../components/Input/GroupedReferenceSelectArrayInput';

const resourceSort : SortPayload = { field: 'resource', order: 'ASC' };

export const RoleCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <ReferenceArrayInput source="permissionIds" reference="permissions" sort={resourceSort} perPage={1000}>
        <GroupedReferenceSelectArrayInput
          extractGroupLabel={({ resourceLabel }) => resourceLabel}
          labelKey="actionLabel"
          groupBy="resource"
        />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);
