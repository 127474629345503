import * as React from 'react';
import { ToggleButton, ToggleButtonProps } from '@mui/material';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { useTranslate } from 'ra-core';
import { useTiptapEditor } from 'ra-input-rich-text';

export const HorizontalRuleButton = (props: Omit<ToggleButtonProps, 'value'>) => {
  const editor = useTiptapEditor();
  const translate = useTranslate();

  const label = translate('ra.tiptap.clear_format', {
    _: 'Horizontal rule',
  });

  return (
    <ToggleButton
      aria-label={label}
      title={label}
      {...props}
      disabled={!editor?.isEditable}
      value="clear"
      onClick={() => editor.chain().focus().setHorizontalRule().run()}
    >
      <HorizontalRuleIcon fontSize="inherit" />
    </ToggleButton>
  );
};
