import { Amplify, Auth } from 'aws-amplify';
import { AppBar, defaultTheme, CustomRoutes, Loading, Layout, Resource, Admin } from 'react-admin';
import { Route } from 'react-router-dom';
import awsConfig from './amplify-config';
import { makeAuthProvider } from './lib/authProvider';
import { SignIn } from './screens/auth/Login';
import { CustomUserMenu } from './components/CustomUserMenu';
import resources from './resources';
import { dataProvider, httpClient } from './lib/dataProvider';
import { AcceptInvite } from './screens/auth/AcceptInvite';
import { PermissionGuard } from './lib/permissions/PermissionGuard';
import { i18nProvider } from './lib/i18nProvider';
import { ResetPassword } from './screens/auth/ResetPassword';

Amplify.configure(awsConfig);

const authProvider = makeAuthProvider(httpClient);
const CustomAppBar = (props: any) => <AppBar {...props} userMenu={<CustomUserMenu />} />;
const CustomLayout = (props: any) => <Layout {...props} appBar={CustomAppBar} />;

const LTOTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: '#1C45E5',
    },
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
  },
  components: {
    ...defaultTheme.components,
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#1C45E5',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          minWidth: 400,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectRoot: {
          minWidth: 'initial',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          minWidth: 400,
        },
      },
    },
  },
};

function App() {
  return (
    <Admin
      layout={CustomLayout}
      loginPage={SignIn}
      disableTelemetry={true}
      ready={() => <Loading />}
      i18nProvider={i18nProvider}
      authProvider={authProvider}
      dataProvider={dataProvider}
      theme={LTOTheme}
    >
      <CustomRoutes noLayout>
        <Route path="accept-invite" element={<AcceptInvite />} />
        <Route path="reset-password" element={<ResetPassword />} />
      </CustomRoutes>
      {async (p) => {
        // @todo make this less hacky. First call is undefined, second doesn't redirect.
        try {
          await Auth.currentSession();
        } catch (error) {
          if (
            !window.location.hash.startsWith('#/accept-invite?') &&
            !window.location.hash.startsWith('#/reset-password')
          )
            window.top.location = '/#/login';
        }

        if (!p) return null;

        const guard = new PermissionGuard(p?.permissions, p?.beursGoes);

        return resources.map(({ permissionResource: r, hideInNav, superAdminOnly, components, ...props }) => {
          if ((superAdminOnly && !guard.isSuperAdmin()) || hideInNav || !guard.isAllowed(r)) return null;

          return <Resource key={`r_${r}`} {...guard.filterComponents(r, components)} {...props} />;
        });
      }}
    </Admin>
  );
}

export default App;
