import { Create, useCreateContext } from 'react-admin';
import * as React from 'react';
import { useCallback } from 'react';
import { UserForm } from './UserForm';
import { defaultValues } from './defaultValues';

export const UserCreate = (props: any) => {
  return (
    <Create {...props}>
      <UserCreateForm />
    </Create>
  );
};

function UserCreateForm() {
  const { save } = useCreateContext();
  const onBeforeSave = useCallback(
    (data) => {
      return save({ ...data, profiles: data.profiles.filter((p) => p.isActive) });
    },
    [save],
  );

  return <UserForm onSubmit={onBeforeSave} defaultValues={defaultValues} />;
}
