import {
  BooleanInput,
  FormDataConsumer,
  ImageInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useGetList,
  AutocompleteInput,
  Labeled,
} from 'react-admin';
import { useMemo } from 'react';
import { RichTextInputToolbar, ListButtons, ClearButtons } from 'ra-input-rich-text';
import { ToggleButtonGroup } from '@mui/material';
import { useTranslate } from 'ra-core';
import NewsResource from './NewsResource';
import { withUpload } from '../../lib/withUpload';
import { ServiceImageField } from '../../components/Field/ServiceImageField';
import { RichTextInput } from '../../components/RichTextJsonInput';
import { VideoButton } from '../../components/VideoButton';
import { CustomFormatButtons } from '../../components/FormatButtons';
import { GroupedRegionDepartmentSelectArrayInput } from '../../components/Input/GroupedRegionDepartmentSelectArrayInput';
import { LevelSelect } from '../../components/LevelSelect';
import { HorizontalRuleButton } from '../../components/HorizontalRuleButton';
import { usePermissionGuard } from '../../lib/permissions/usePermissionGuard';
import { NoDeleteToolbar } from '../../components/NoDeleteToolbar';
import { ReferenceArrayInput } from '../../components/Input/ReferenceArrayInput';
import {  transformMediaUrlInput } from '../../lib/media/makeUrl'
import {  isUrlValid } from '../../lib/media/isValidUrl'
import { ImageButton } from '../../components/ImageButton';
import { LinkContentButton } from '../../components/LinkContentButton';
import { LinkFileButton } from '../../components/LinkFileButton';
import { DateTimePicker } from '../../components/Input/DateTimePickerInput';
import AgendaResource from '../Agenda/AgendaResource';
import { LinkButtons } from '../../components/LinkButton';
import { ALLOWED_FILE_UPLOAD } from '../../lib/constants';
import { fromUTC } from '../../lib/i18n/utils';

let urlValid = false;

const urlExists = (url:string) => {
  if (url)
    return urlValid ? undefined : 'lto.generic.url_notfound';
  else 
    return undefined;
}

const rules = {
  title: [required()],
  imageurl: [required(), isUrlValid, urlExists],
  image: [required()],
  topic: [required()],
  departments: [required()],
  content: [required()],
  location: [required()],
  start: [required()],
  end: [required()],
};

export function NewsForm({ hideSendPushNotification = false, username = '', hideAgenda = false }) {
  const { guard } = usePermissionGuard();
  const translate = useTranslate();
  const { data, isLoading } = useGetList('ltos');
  const { data: agendaData, isLoading: isaAgendaLoading } = useGetList(
    'agenda',
    { 
      pagination: { page: 1, perPage: 999 },
      sort: { field:'createdAt' , order: 'DESC' }, 
    },
  );
  const showPush = guard.isAllowed(NewsResource.permissionResource, 'PushNotifications');
  const showAgenda = guard.isAllowed(AgendaResource.permissionResource, 'Create');
  const ltoMap = useMemo(
    () =>
      data?.reduce((acc, lto) => {
        acc[lto.id] = lto.name;

        return acc;
      }, {}),
    [data],
  );

  if (isLoading || isaAgendaLoading) return null;

  return (
    <SimpleForm defaultValues={withUpload('mediaId', 'media')} toolbar={<NoDeleteToolbar />}>
      <TextInput source="title" validate={rules.title} helperText={false} />
      <ReferenceInput label={"resources.news.fields.topic"} source="topicId" reference="topics" perPage={99999} sort={{ field: 'name', order: 'ASC'}}>
        <SelectInput label={"resources.news.fields.topic"} optionText="name" validate={rules.topic} />
      </ReferenceInput>

      <ReferenceArrayInput source="departmentIds" reference="departments" perPage={99999}>
        <GroupedRegionDepartmentSelectArrayInput
          extractGroupLabel={({ ltoId }) => ltoMap[ltoId]}
          groupBy="ltoId"
          sortBy="name"
          label={'resources.news.fields.departments'}
          validate={rules.departments}
          regionsLabel={'resources.news.fields.regions'}
          noRegionsLabel={'resources.news.fields.noRegions'}
        />
      </ReferenceArrayInput>

      <Labeled
        isRequired
        label={"resources.news.fields.media"}
      >
        <></>
      </Labeled>
        <BooleanInput source="useMediaUrl" />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const displayurl = transformMediaUrlInput(formData.mediaUrl)
            return formData.useMediaUrl ? (
                <>
                  <TextInput source="mediaUrl" placeholder='mediaUrl' validate={rules.imageurl} />
                  {formData.mediaUrl ? <img onError={() => urlValid = false} onLoad={() => urlValid = true} src={`${displayurl}${displayurl.includes('?') ? '&' : '?'}width=48&height=48`} width={48} height={48} alt={formData.mediaUrl} /> : null}
                </>
              ) : (
                <ImageInput
                  source="media"
                  label={false}
                  accept="image/png, image/jpeg, image/webp"
                  validate={rules.image}
                  placeholder={<p>{translate('lto.upload_label')}</p>}
                  helperText={'resources.news.imageHelperText'}
                >
                  <ServiceImageField source="media" sortable={false} />
                </ImageInput>
              )
            }
          }
            
        </FormDataConsumer>

      <RichTextInput
        source="content"
        validate={rules.content}
        contentType="json"
        toolbar={
          <RichTextInputToolbar>
            <LevelSelect />
            <HorizontalRuleButton />
            <CustomFormatButtons />
            <ListButtons />
            <ToggleButtonGroup>
              <LinkButtons />
              <LinkContentButton resource={NewsResource.name} />
              <LinkFileButton allowed={ALLOWED_FILE_UPLOAD} />
            </ToggleButtonGroup>
            <ToggleButtonGroup>
              <ImageButton />
              <VideoButton />
            </ToggleButtonGroup>
            <ClearButtons />
          </RichTextInputToolbar>
        }
      />

      <Labeled
        label={"resources.news.fields.extra_options"}
      >
        <></>
      </Labeled>
      <BooleanInput source="isImportant" label="Pinned" helperText={false} />
      <BooleanInput source="isLocalNews" helperText={false} />

      {!hideSendPushNotification && showPush ? (
        <>
          <BooleanInput source="pushNotification.enabled" helperText={false} />
          <FormDataConsumer>
            {({ formData, ...rest }) => 
              formData.pushNotification?.enabled ? (
                <>
                  <TextInput source="pushNotification.title" defaultValue="Belangrijk nieuws!" />
                  <TextInput source="pushNotification.description" defaultValue="Er is nieuw belangrijk nieuws." />
                </>
              ) : null
            }
          </FormDataConsumer>
        </>
      ) : null}

      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return(<DateTimePicker source="publishedAt" value={formData.publishedAt ? fromUTC(formData.publishedAt) : null} validate={null} label="resources.news.fields.publishAt" />)
        }}
      </FormDataConsumer>

      <BooleanInput source="isPreview" label={translate("resources.news.fields.isPreview", { createdBy: username })} helperText={false} />
      
      {!hideAgenda && showAgenda ?
          (
            <>
              <BooleanInput source="agenda.enabled" helperText={false}/>
              <FormDataConsumer>
                  {({ formData, ...rest }) => 
                    formData.agenda?.enabled ? (
                      <>
                        <DateTimePicker source="agenda.start" validate={rules.start} label='resources.news.fields.agenda.start' />
                        <DateTimePicker source="agenda.end" validate={rules.end} label='resources.news.fields.agenda.end' />
                        <TextInput source="agenda.location" validate={rules.location}  />
                        <BooleanInput source="agenda.allowRegistrations" />
                      </>
                    ) : <AutocompleteInput multiple label={translate('resources.news.fields.agendaIds')} source='agendaIds' choices={agendaData.map(x=> ({ id: x.id, name: x.title}))} />
                  }
                </FormDataConsumer>
              </>
          ) : <AutocompleteInput multiple label={translate('resources.news.fields.agendaIds')} source='agendaIds' choices={agendaData.map(x=> ({ id: x.id, name: x.title}))} />
      } 
    </SimpleForm>
  );
}
