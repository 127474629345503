import CircularProgress from '@mui/material/CircularProgress';
import { useRecordContext } from 'react-admin';
import * as React from 'react';
import type { PublicFieldProps } from 'ra-ui-materialui';
import { makeUrl } from '../../lib/media/makeUrl';
import { Transformation } from '../../lib/media/Transformation';

export const ServiceImageField = ({
  source,
  transformation = Transformation.Thumbnail,
  width = 48,
  height = 48,
  alt,
  ...props
}: ServiceImageFieldProps) => {
  const record = useRecordContext(props);

  if (!record) return <CircularProgress />;

  const blob = record?.src;

  const filePath = record?.[source]?.filePath || record?.filePath;

  if(blob && !filePath) return <img src={blob} width={width} height={height} alt={alt || source} />;

  if (typeof record?.[source] !== 'string' && !filePath) return <CircularProgress />;

  const src = typeof record?.[source] === 'string' ? record?.[source] : makeUrl(filePath, transformation);

  return <img src={src} width={width} height={height} alt={alt || source} />;
};

ServiceImageField.defaultProps = { label: '', sortable: false };

interface ServiceImageFieldProps extends PublicFieldProps {
  source: string;
  transformation?: Transformation;
  width?: number;
  height?: number;
  alt?: string;
}
