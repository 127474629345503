import * as React from 'react';
import { Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslate } from 'ra-core';

export function DetachedBooleanField({ value }: DetachedBooleanFieldProps) {
  const translate = useTranslate();
  const ariaLabel = value ? 'ra.boolean.true' : 'ra.boolean.false';

  return (
    <StyledTypography variant="body2">
      <Tooltip title={translate(ariaLabel, { _: ariaLabel })}>
        {value ? <DoneIcon data-testid="true" fontSize="small" /> : <ClearIcon data-testid="false" fontSize="small" />}
      </Tooltip>
    </StyledTypography>
  );
}

interface DetachedBooleanFieldProps {
  value: boolean;
}

const StyledTypography = styled(Typography, {
  name: 'DetachedBooleanFieldProps',
  overridesResolver: (props, styles) => styles.root,
})({
  display: 'inline-flex',
  verticalAlign: 'middle',
  lineHeight: 0,
});
