import CategoryIcon from '@mui/icons-material/Category';
import { GuardedComponents } from '../../lib/permissions/types';
import { ResourceDefinition } from '../types';
import { CategoryCreate, CategoryEdit, CategoryList, CategoryShow } from '.';

export default {
  permissionResource: 'Category',
  name: 'categories',
  hideInNav: false,
  superAdminOnly: true,
  icon: CategoryIcon,
  components: new GuardedComponents([
    ['list', { action: 'GetList', component: CategoryList }],

    ['edit', { action: 'Update', component: CategoryEdit }],
    ['create', { action: 'Create', component: CategoryCreate }],
  ]),
  i18n: {
    nl: {
      name: 'Categorie |||| Categorieën',
      fields: {
        name: 'Naam',
      },
    },
  },
} as ResourceDefinition;
