import { AgendaForm } from './AgendaForm';
import { Edit } from '../../components/Edit';
import { RecordEditTitle } from '../../components/RecordTitle';
import {  transformMediaUrlInput } from '../../lib/media/makeUrl'
import { fromGenericToUTC } from '../../lib/i18n/utils';
import { useEditController } from 'react-admin';

export const AgendaEdit = (props: any) => {
  const { record, isLoading } = useEditController();
  const transform = data => ({
    ...data,
    publishedAt: fromGenericToUTC(data.publishedAt),
    newsIds: data.newsIds ?? [],
    mediaUrl: transformMediaUrlInput(data.mediaUrl)
  });
  
  if (isLoading || !record.createdBy) return null;

  return (
    <Edit meta={{ embed: 'media' }} title={<RecordEditTitle property="title" />} {...props} transform={transform}>
      <AgendaForm username={record.createdBy.name} hideSendPushNotification />
    </Edit>
  )
};
