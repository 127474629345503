import { Button, CreateBase, Form, ReferenceInput, SelectInput, useNotify } from 'react-admin';
import { useCallback, useState } from 'react';
import { useTranslate } from 'ra-core';
import { httpClient } from '../../lib/dataProvider';
import { API_URL } from '../../lib/constants';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import * as React from 'react';

export function MigrateDialog({ data, onClose, refresh }) {
  const notify = useNotify();
  const [migrating, setMigrating] = useState(false);
  const translate = useTranslate();
  const onConfirm = useCallback(
    async (d: any) => {
      if (!data || !d.department) {
        notify('lto.screens.import_status.missing_department');

        return;
      }

      setMigrating(true);

      try {
        await httpClient(API_URL + '/departments/migrate', {
          method: 'PUT',
          body: JSON.stringify({
            fromDepartmentId: data.departmentId,
            toDepartmentId: d.department,
          }),
        });

        // all went well, update the list.
        refresh();
        onClose();
      } catch (error) {
        // use notify
        notify('lto.generic.unknown_error');
      } finally {
        setMigrating(false);
      }
    },
    [data, onClose, refresh],
  );

  return (
    <Dialog open={!!data} onClose={onClose}>
      <CreateBase>
        <DialogTitle>{translate('lto.screens.import_status.dialog_title')}</DialogTitle>
        <Form onSubmit={onConfirm}>
          <DialogContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
            <DialogContentText>{translate('lto.screens.import_status.dialog_description')}</DialogContentText>
            <ReferenceInput
              enableGetChoices={() => !!data}
              filter={{ ltos: data?.ltoId }}
              sort={{ field: 'name', order: 'ASC' }}
              perPage={999999}
              label="Afdeling"
              source="department"
              reference="departments"
              link={false}
            >
              <SelectInput source="name" />
            </ReferenceInput>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={onClose}
              label={translate('lto.screens.import_status.dialog_cancel')}
              size="medium"
              disabled={migrating}
            />
            <Button
              label={translate('lto.screens.import_status.dialog_confirm')}
              size="medium"
              variant={'outlined'}
              type="submit"
              disabled={migrating}
            >
              <ThumbUpAltIcon />
            </Button>
          </DialogActions>
        </Form>
      </CreateBase>
    </Dialog>
  );
}
