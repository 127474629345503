import { useLogin, useNotify } from 'react-admin';
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { dataProvider } from '../../lib/dataProvider';
import Typography from '@mui/material/Typography';
import { useTranslate } from 'ra-core';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';

export function SignIn() {
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const login = useLogin();
  const translate = useTranslate();
  const navigate = useNavigate();
  const notify = useNotify();
  const [searchParams] = useSearchParams();
  const reset = typeof searchParams.get('reset') === 'string';

  function doLinkClick() {
    if (reset) navigate('/login');
    else navigate('/login?reset');
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');

    if (reset) {
      setSending(true);
      try {
        await dataProvider.post('/users/request-reset-password', {
          emailAddress: email,
          isDashboardUser: true,
        });

        setSent(true);
        notify('lto.screens.login.reset_password_email_sent');
      } catch (error) {
        notify('lto.screens.login.unknown_error');
      } finally {
        setSending(false);
      }
    } else {
      try {
        await login({ username: email, password });
      } catch (error) {
        notify('lto.screens.login.invalid_credentials');
      }
    }
  };

  function resetToLogin() {
    setSent(false);
    navigate('/login');
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {translate(`lto.screens.login.${reset ? 'lost_password' : 'signin'}`)}
        </Typography>
        {sent ? (
          <Box component="div" sx={{ mt: 3, textAlign: 'center' }}>
            <Typography variant="body1">{translate('lto.screens.login.reset_password_email_sent')}</Typography>
            <Button variant="text" onClick={resetToLogin} disabled={sending} sx={{ mt: 2 }} fullWidth>
              {translate(`lto.screens.login.back_to_login`)}
            </Button>
          </Box>
        ) : (
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={translate('lto.screens.login.email')}
              name="email"
              autoComplete="email"
              autoFocus
            />
            {reset ? null : (
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label={translate('lto.screens.login.password')}
                type="password"
                id="password"
                autoComplete="current-password"
              />
            )}
            <Button type="submit" fullWidth variant="contained" disabled={sending} sx={{ mt: 2, mb: 1 }}>
              {translate(`lto.screens.login.${reset ? 'reset_password' : 'signin'}`)}
            </Button>
            <Button variant="text" onClick={doLinkClick} disabled={sending} fullWidth>
              {translate(`lto.screens.login.${reset ? 'signin' : 'lost_password'}`)}
            </Button>
          </Box>
        )}
      </Box>
    </Container>
  );
}
