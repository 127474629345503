export default {
  aws_project_region: 'eu-central-1',
  aws_cognito_region: 'eu-central-1',
  aws_user_pools_id: 'eu-central-1_ODOBEtc5m',
  aws_user_pools_web_client_id: '4bb8099sn11guu65ak1g59bp0n',
  oauth: {
    domain: 'lto-prod-pool.auth.eu-central-1.amazoncognito.com',
  },
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: [],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: [],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: ['REQUIRES_LOWERCASE', 'REQUIRES_UPPERCASE', 'REQUIRES_NUMBERS', 'REQUIRES_SYMBOLS'],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
};
