import * as React from 'react';
import { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { EditContextProvider, ResourceContextProvider, useCheckMinimumRequiredProps, RaRecord } from 'ra-core';
import { EditProps, EditView } from 'react-admin';
import { useEditController } from './useEditController';

/**
 * Page component for the Edit view
 *
 * The `<Edit>` component renders the page title and actions,
 * fetches the record from the data provider.
 * It is not responsible for rendering the actual form -
 * that's the job of its child component (usually `<SimpleForm>`),
 * to which it passes the `record` as prop.
 *
 * The <Edit> component accepts the following props:
 *
 * - actions
 * - aside
 * - component
 * - title
 * - mutationMode
 * - mutationOptions
 *
 * @example
 *
 * // in src/posts.js
 * import * as React from "react";
 * import { SimpleFormEdit, SimpleForm, TextInput } from 'react-admin';

import { NoDeleteToolbar } from '../../components/NoDeleteToolbar';
 *
 * export const PostEdit = (props) => (
 *     <Edit {...props}>
 *         <SimpleForm>
 *             <TextInput source="title" />
 *         </SimpleForm>
 *     </Edit>
 * );
 *
 * // in src/App.js
 * import * as React from "react";
 * import { Admin, Resource } from 'react-admin';
 *
 * import { PostEdit } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={...}>
 *         <Resource name="posts" edit={PostEdit} />
 *     </Admin>
 * );
 * export default App;
 */
export const Edit = <RecordType extends RaRecord = any>(
  props: EditProps<RecordType> & { children: ReactNode; meta?: Record<string, any> },
) => {
  useCheckMinimumRequiredProps('Edit', ['children'], props);
  const controllerProps = useEditController<RecordType>(props);
  const body = (
    <EditContextProvider value={controllerProps}>
      <EditView {...props} {...controllerProps} />
    </EditContextProvider>
  );
  return props.resource ? (
    // support resource override via props
    <ResourceContextProvider value={props.resource}>{body}</ResourceContextProvider>
  ) : (
    body
  );
};

Edit.propTypes = {
  actions: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  aside: PropTypes.element,
  children: PropTypes.node,
  className: PropTypes.string,
  disableAuthentication: PropTypes.bool,
  hasCreate: PropTypes.bool,
  hasEdit: PropTypes.bool,
  hasShow: PropTypes.bool,
  hasList: PropTypes.bool,
  id: PropTypes.any,
  mutationMode: PropTypes.oneOf(['pessimistic', 'optimistic', 'undoable']),
  mutationOptions: PropTypes.object,
  queryOptions: PropTypes.object,
  redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
  resource: PropTypes.string,
  title: PropTypes.node,
  transform: PropTypes.func,
  sx: PropTypes.any,
};
