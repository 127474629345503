import { Archive } from '@mui/icons-material';
import { List, Datagrid, TextField, DateField, BooleanField, BooleanInput, Button, useListContext, useUnselectAll, TopToolbar, CreateButton, ExportButton, useListController, useResourceContext, ReferenceField, SearchInput, AutocompleteArrayInput, DateInput, ReferenceArrayInput, SelectInput, FilterButton, FunctionField} from 'react-admin';
import { dataProvider } from '../../lib/dataProvider';
import { usePermissionGuard } from '../../lib/permissions/usePermissionGuard';
import NewsResource from './NewsResource';
import { useLocation } from 'react-router'
import React from 'react';
import { fromUTC } from '../../lib/i18n/utils';

interface FilterValuesObject {
  isArchived: boolean
}
interface ArchiveActionProps {
  selectedIds?: string[]
  filterValues?: FilterValuesObject
}

const ArchiveItems = async (props: ArchiveActionProps) => {
  await dataProvider.put("/news/archive", { archive: !props.filterValues?.isArchived, ids: props.selectedIds})  
}

const PostBulkActionButtons = (props: ArchiveActionProps) => {
  const unselectAll = useUnselectAll('news');
  const { refetch } = useListContext();
  return <Button 
    label={!props.filterValues?.isArchived ? 'resources.news.fields.archive' : 'resources.news.fields.deArchive'} 
    startIcon={<Archive/>} 
    onClick={() => { 
        ArchiveItems(props).then(() => {
          unselectAll();
          refetch();
        });
      } 
    }
  />
};

const postFilters = [
  <SearchInput source="_s" resettable alwaysOn />,
  <DateInput source='publishedAt_gte' />,
  <DateInput source='publishedAt_lte' />,
  //@ts-ignore api accepts comma-seperated order parameter value
  <ReferenceArrayInput source="createdBy" reference="users" filter={{ ProfileType: 'Dashboard' }} perPage={999} sort={{ field: 'firstName,lastName', order: 'ASC,ASC' }}>
    <AutocompleteArrayInput optionText={(option) => `${option.firstName} ${option.lastName}`} />
  </ReferenceArrayInput>,
  <ReferenceArrayInput source="lto" reference="ltos" sort={{ field: 'name', order: 'ASC' }}>
    <SelectInput optionText='name' />
  </ReferenceArrayInput>,
  <BooleanInput source="isArchived" alwaysOn className='center-boolean-input'/>,
];

const ListActions = (props: any) => {
  return (
    <TopToolbar>
        <FilterButton />
        { props.hasCreate ? <CreateButton/> : null }
        <ExportButton maxResults={999999999}/>
    </TopToolbar>
  )
};

const Grid = (prop: any) =>{
  const { guard } = usePermissionGuard();
  const canArchive = guard.isAllowed(NewsResource.permissionResource, 'Archive');
  const { setFilters} = useListContext();
  const location = useLocation()

  React.useEffect(() => {
    if(location.search === '') {
      setFilters(prop.filterDefaultValues, [])
    }
  }, [location]);
  
  return (
    <Datagrid bulkActionButtons={canArchive ? <PostBulkActionButtons /> : null} rowClick="edit">
      {/* @todo sort on name instead of id as soon as API supports it. */}
      <ReferenceField link={false} source="topicId" sortBy='topicId' reference="topics" label="resources.news.fields.topic">
        <TextField source="name" label="resources.news.fields.topic" />
      </ReferenceField>
      <TextField source="title" />
      <BooleanField source="isImportant" />
      <FunctionField
                source="publishedAt"
                render={record => fromUTC(record.publishedAt).toLocaleString()}
            />
      <TextField source="createdBy.name" sortBy="createdBy" label="resources.news.fields.createdBy" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  )
}

export const NewsList = (props: any) => {
  const listContext = useListController();
  return (
    <List actions={<ListActions />} debounce={500} hasCreate={!listContext.filterValues?.isArchived} filterDefaultValues={{ isArchived: false, _sf: 'title,content' }} filters={postFilters}  filter={{ _embed: 'topic', _sf: 'title,content' }} {...props}>
      <Grid {...props} filterDefaultValues={{ isArchived: false, _sf: 'title,content' }} />
    </List>
  )
}