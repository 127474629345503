import * as React from 'react';
import { useTranslate } from 'ra-core';
import { Edit, useEditContext, Toolbar, ToolbarClasses, SaveButton, Button, useRecordContext, useDelete, useResourceContext, RaRecord, DeleteWithUndoButtonProps, useDeleteWithUndoController } from 'react-admin';
import { useCallback, useMemo } from 'react';
import { alpha, styled } from '@mui/material/styles';
import clsx from 'clsx';
import DeleteIcon from '@mui/icons-material/Delete';
import { UserForm } from './UserForm';
import { defaultValues } from './defaultValues';
import { RecordEditTitle } from '../../components/RecordTitle';
import { isProduction } from '../../lib//permissions/isEnvironment';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { usePermissionGuard } from '../../lib/permissions/usePermissionGuard';

const StyledButton = styled(Button, {
  name: 'RaDeleteWithUndoButton',
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  color: theme.palette.error.main,
  '&:hover': {
      backgroundColor: alpha(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
          backgroundColor: 'transparent',
      },
  },
}));

export const UserEdit = (props: any) => {
  return (
    <Edit title={<RecordEditTitle property="firstName" /> } {...props}>
      <UserEditForm />
    </Edit>
  );
};

const DeleteButton = <RecordType extends RaRecord = any>(
  props: DeleteWithUndoButtonProps<RecordType>
) => {
  const t = useTranslate();
  const { guard } = usePermissionGuard();
  const record = useRecordContext(props);
  const resource = useResourceContext(props);
  const { handleDelete } = useDeleteWithUndoController({
      record,
      resource
  });

   return (guard.isAllowed("User", "Delete") ? (
    <StyledButton
      className={'ra-delete-button'}
      onClick={(e) => {
        if (!window.confirm(t('resources.users.labels.areYouSure'))) return;

        return handleDelete(e);
      }}
      label={t('resources.users.labels.delete')}
    >
      <DeleteIcon />
    </StyledButton>
  ) : null)
};

const CustomToolbar = (props) => {
  const { save, record } = useEditContext();
  const onBeforeSave = useCallback(
    (data) => {
      return save({ ...data, profiles: data.profiles.filter((p) => p.isActive || p.id) });
    },
    [save],
  );
  return (
    <Toolbar>
      <div className={ToolbarClasses.defaultToolbar}>
        <SaveButton />
        {" "}
        { !isProduction() ? (
          <Button
            onClick={() => { 
              record.IsVerified = false;
              onBeforeSave(record);
            }}
            label="Reset account"
          >
            <RestartAltIcon />
          </Button>
        ) : null }
        <DeleteButton />
      </div>
    </Toolbar> 
  )
};

// @todo diff (somehow) and exclude profiles.
function UserEditForm() {
  const { save, record, isFetching } = useEditContext();
  const onBeforeSave = useCallback(
    (data) => {
      return save({ ...data, profiles: data.profiles.filter((p) => p.isActive || p.id) });
    },
    [save],
  );
  const hydratedRecord = useMemo(() => {
    return {
      ...record,
      profiles: [
        record.profiles.find((profile) => profile.type === 'Dashboard') || defaultValues.profiles[0],
        record.profiles.find((profile) => profile.type === 'App') || defaultValues.profiles[1],
      ],
    };
  }, [record]);

  if (isFetching) return null;

  return <UserForm toolbar={<CustomToolbar />} onSubmit={onBeforeSave} record={hydratedRecord} />;
}
