import { BooleanInput, ImageInput, ReferenceInput, required, SelectInput, SimpleForm, TextInput, FormDataConsumer } from 'react-admin';
import * as React from 'react';
import { withUpload } from '../../lib/withUpload';
import { ServiceImageField } from '../../components/Field/ServiceImageField';
import { NoDeleteToolbar } from '../../components/NoDeleteToolbar';
import { useTranslate } from 'ra-core';
import { useIsBeursGoesMember } from '../../lib/permissions/useIsBeursGoesMember';
import {  transformMediaUrlInput } from '../../lib/media/makeUrl'
import {  isUrlValid } from '../../lib/media/isValidUrl'

let urlValid = false;

const urlExists = (url:string) => {
  return urlValid ? undefined : 'lto.generic.url_notfound';
}

const rules = {
  image: [required()],
  imageurl: [required(), isUrlValid, urlExists],
};

export function TopicForm() {
  const translate = useTranslate();
  const { isBeursGoesMember, loading } = useIsBeursGoesMember();

  if (loading) return null;

  return (
    <SimpleForm defaultValues={withUpload('mediaId', 'media')} toolbar={<NoDeleteToolbar />}>
      <TextInput source="name" required />
      {isBeursGoesMember ? <BooleanInput source="isBeursGoesTopic" /> : null}
      <TextInput multiline source="description" required />
      <ReferenceInput source="categoryId" reference="categories">
        <SelectInput optionText="name" required />
      </ReferenceInput>
      <BooleanInput source="useMediaUrl" />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          const displayurl = transformMediaUrlInput(formData.mediaUrl)
          return formData.useMediaUrl ? (
              <>
                <TextInput source="mediaUrl" placeholder='mediaUrl' validate={rules.imageurl} />
                {formData.mediaUrl ? <img onError={() => urlValid = false} onLoad={() => urlValid = true} src={`${displayurl}${displayurl.includes('?') ? '&' : '?'}width=48&height=48`} width={48} height={48} alt={formData.mediaUrl} /> : null}
              </>
            ) : (
              <ImageInput
                source="media"
                accept="image/png, image/jpeg, image/webp"
                validate={rules.image}
                placeholder={<p>{translate('lto.upload_label')}</p>}
                helperText={'resources.topics.imageHelperText'}
              >
                <ServiceImageField source="media" sortable={false} />
              </ImageInput>
            )
          }
        }
      </FormDataConsumer>
    </SimpleForm>
  );
}
