import { ToggleButton, ToggleButtonProps } from '@mui/material';
import LinkedIcon from '@mui/icons-material/AddLink';
import { Box, Autocomplete, Button, Typography, Modal, TextField } from '@mui/material';
import { useTranslate } from 'ra-core';
import { useEditorSelection, useTiptapEditor } from 'ra-input-rich-text';
import React, { useCallback } from 'react';
import { useGetList } from 'react-admin';

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  boxShadow: 24,
  p: 2,
  borderRadius: '5px'
};

type LinkContentButtonProps = ToggleButtonProps & { resource:string }



export const LinkContentButton = (props: Omit<LinkContentButtonProps, 'value'>) => {
  const editor = useTiptapEditor();
  const currentTextSelection = useEditorSelection();
  const t = useTranslate();
  const [open, setOpen] = React.useState(false);
  const [contentId, setcontentId] = React.useState<string>('');
  const { resource } = props;
  const label = t('lto.rte.link_to_content.button_title');
  
  const openModal = () => { 
    const { linkToContent  } = editor.getAttributes('linktocontent')
    if(linkToContent) {
      setcontentId(linkToContent.id);
    }
    setOpen(true) 
  };
  const closeModal = () => setOpen(false);

  const setLinkToContent = useCallback((id: string, resource: string) => {
    const link = { id, resource }
    if(id?.length) {
      editor.commands.unsetAllMarks();
      editor.commands.setLinkToContent(link);
    }
    else 
    {
      editor.commands.unsetLinkToContent();
    }
    closeModal();
    setcontentId('');
  }, [editor]);

  const { data, isLoading } = useGetList(
    resource,
    { 
      pagination: { page: 1, perPage: 999 },
      sort: { field:'createdAt' , order: 'DESC' }, 
      filter: { isArchived: false },
    },
  );
  const add_months = (dt, n) =>{
    return new Date(dt.setMonth(dt.getMonth() + n));      
  }
  return (
    <>
    <ToggleButton
      aria-label={label}
      title={label}
      {...props}
      disabled={!editor?.isEditable || !currentTextSelection}
      value="clear"
      onClick={openModal}
    >
      <LinkedIcon fontSize="inherit" />
    </ToggleButton>
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography id="modal-modal-title" variant="subtitle2" component="h3">
          {t(`lto.rte.link_to_content.${resource}.select_item_title`)}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          { !isLoading ?
              <Autocomplete value={data.find(x=> x.id === contentId)?.title} 
                onChange={(e: React.SyntheticEvent, value: any) => setcontentId(value?.id)} 
                options={data.filter(x=> new Date(x.createdAt) > add_months(new Date(), -3)).map(x=> ({ id: x.id, label: x.title}))}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.label}
                  </li>
                )}
                renderInput={(params) => <TextField {...params} label={t(`lto.rte.link_to_content.${resource}.select_item`)} />}
                noOptionsText={t('lto.rte.link_to_content.no_options')} />
            : null
          }
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={() => setLinkToContent(contentId, resource)} color="error">{t(`lto.rte.link_to_content.close_modal`)}</Button>
          <Button onClick={() => setLinkToContent(contentId, resource)} >{t(`lto.rte.link_to_content.select`)}</Button>
        </div>
      </Box>
    </Modal>
    </>
  );
};
