import * as React from 'react';
import { List, Datagrid, TextField, ReferenceField, DateField, CreateButton, ExportButton, TopToolbar } from 'react-admin';

const ListActions = () => (
  <TopToolbar>
      <CreateButton/>
      <ExportButton maxResults={999999999}/>
  </TopToolbar>
);

export const DepartmentList = (props: any) => (
  <List actions={<ListActions />} {...props}>
    <Datagrid bulkActionButtons={null} rowClick="edit">
      <ReferenceField label="LTO" source="ltoId" reference="ltos" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);
