import { PermissionDto, PermissionMap } from './types';

export function createPermissionMap(permissions: PermissionDto[]): PermissionMap {
  return permissions.reduce((acc: PermissionMap, permission: PermissionDto) => {
    if (!acc[permission.resource]) acc[permission.resource] = {};

    acc[permission.resource][permission.action] = true;

    return acc;
  }, {});
}

export function isAllowed(permissionMap: PermissionMap, resource: string, action?: null | string) {
  if (!action) return typeof permissionMap?.[resource] !== 'undefined';

  return permissionMap?.[resource]?.[action] === true;
}
