import * as React from 'react';
import { List, Datagrid, TextField, DateField, CreateButton, ExportButton, TopToolbar } from 'react-admin';

const ListActions = () => (
  <TopToolbar>
      <CreateButton/>
      <ExportButton maxResults={999999999}/>
  </TopToolbar>
);

export const CategoryList = (props: any) => (
  <List actions={<ListActions />} {...props}>
    <Datagrid bulkActionButtons={null} rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);
