import PeopleIcon from '@mui/icons-material/People';
import { GuardedComponents } from '../../lib/permissions/types';
import { ResourceDefinition } from '../types';
import { UserCreate, UserEdit, UserList, UserShow } from '.';

export default {
  permissionResource: 'User',
  name: 'users',
  icon: PeopleIcon,
  components: new GuardedComponents([
    ['list', { action: 'GetList', component: UserList }],
    ['edit', { action: 'Update', component: UserEdit }],
    ['create', { action: 'Create', component: UserCreate }],
  ]),
  i18n: {
    nl: {
      name: 'Gebruiker |||| Gebruikers',
      fields: {
        firstName: 'Voornaam',
        lastName: 'Achternaam',
        name: 'Naam',
        emailAddress: 'E-mailadres',
        phoneNumber: 'Telefoonnummer',
        isVerified: 'Geverifieerd',
        noRegions: 'Geen regios beschikbaar'
      },
      labels: {
        delete: 'Verwijderen',
        areYouSure: 'Weet je het zeker? Hiermee wordt de gebruiker verwijderd!'
      }
    },
  },
} as ResourceDefinition;
