import * as React from 'react';
import { BooleanInput, Edit, ReferenceInput, SelectInput, SimpleForm, TextInput } from 'react-admin';
import { NoDeleteToolbar } from '../../components/NoDeleteToolbar';

export const ProfileEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm toolbar={<NoDeleteToolbar />}>
      <TextInput source="id" />
      <TextInput source="name" />
      <BooleanInput source="isActive" />

      <ReferenceInput label="Role" source="roleId" reference="roles">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput label="User" source="userId" reference="users">
        <SelectInput optionText="emailAddress" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
