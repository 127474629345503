import { useEffect, useMemo, useState } from 'react';
import { usePermissions } from 'react-admin';
import { PermissionGuard } from './PermissionGuard';

let count = 0;

export function usePermissionGuard() {
  const guard = useMemo(() => new PermissionGuard(), []);
  const { isLoading, permissions } = usePermissions();
  const [permissionGuard, setPermissionGuard] = useState({ isLoading, guard, count });

  useEffect(() => {
    guard.setPermissions(isLoading ? null : permissions?.permissions);

    setPermissionGuard({ guard, isLoading, count: ++count });
  }, [guard, isLoading, permissions]);

  return permissionGuard;
}
