import { ToggleButton, ToggleButtonProps } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, TextField, Button, Typography, Modal, Autocomplete } from '@mui/material';
import { useTranslate } from 'ra-core';
import { useEditorSelection, useTiptapEditor } from 'ra-input-rich-text';
import React, { useCallback, useEffect, useState } from 'react';
import { dataProvider } from '../lib/dataProvider';
import { doFileUpload } from '../lib/media/doFileUpload';
const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  boxShadow: 24,
  p: 2,
  borderRadius: '5px'
};
type LinkFileButtonProps = ToggleButtonProps & { allowed:string[] }

export const LinkFileButton = (props: Omit<LinkFileButtonProps, 'value'>) => {
  const editor = useTiptapEditor();
  const currentTextSelection = useEditorSelection();
  const { allowed } = props;
  const t = useTranslate();
  const [uploading, setUploading] = useState(false)
  const [open, setOpen] = React.useState(false);
  const [files, setfiles] = React.useState<string[]>([]);
  const [fileName, setfileName] = React.useState<string>('');
  const fileuploadField = React.createRef<HTMLInputElement>();


  const label = t('lto.rte.link_to_file.button_title');
  
  const openModal = () => { 
    const { fileName } = editor.getAttributes('file')

    if(fileName) {
      setfileName(fileName);
    }
    setOpen(true) 
  };
  
  const closeModal = () => { 
    setfileName('');
    setOpen(false);
  };

  const setLinkToFile = useCallback((fileName: string) => {
    if(fileName?.length) {
      editor.commands.unsetAllMarks();
      editor.commands.setLinkToFile(fileName);
    }
    else 
    {
      editor.commands.unsetLinkToFile();
    }
    closeModal();
    setfileName('');
  }, [editor]);

  const reloadFiles = () => {
      dataProvider.getRaw('/file/last-uploaded', { numberOfFiles: 9999 }).then(data => {
        setfiles(JSON.parse(data))
      });
  };

  useEffect(() => {
    reloadFiles();
  },[])

  const doUpload = async (file: File) => {
    if(!allowed.includes(file.type)) {
        console.warn(`Upload of file with type '${file.type}' is not allowed!`)
        return;
    }
    setUploading(true);
    const uploadData = await doFileUpload(file, '/file/upload')
    editor.commands.unsetAllMarks();
    editor.commands.setLinkToFile(uploadData.fileName);
    closeModal();
    setfileName('');
    reloadFiles();
    setUploading(false);
  }

  const onFileChange = (event) => {
      doUpload(event.target.files[0])
  }

  const onDrop = (event) => {
      console.log('event', event)
      event.preventDefault();
      if (event.dataTransfer.items) {
          const item = event.dataTransfer.items[0]
          if (item.kind === "file") {
              doUpload(item.getAsFile())
          }
      } else {
          doUpload(event.dataTransfer.files[0]);
      }
  }

  const deleteFile = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, fileName: string) => {
    event.stopPropagation();
    event.preventDefault();
    if(window.confirm(t('lto.rte.link_to_file.delete_file', {  fileName }))) {
      dataProvider.deleteRaw(`/file`, { fileName }).then(data => {
        if(JSON.parse(data)) {
          reloadFiles();
        }
      });
    }
    return false;
  }

  return (
    <>
    <ToggleButton
      aria-label={label}
      title={label}
      {...props}
      disabled={!editor?.isEditable || !currentTextSelection}
      value="clear"
      onClick={openModal}
    >
      <AttachFileIcon fontSize="inherit" />
    </ToggleButton>
    
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <form>
      <Box sx={modalStyle}>
        <Typography id="modal-modal-title" variant="subtitle2" component="h3">
          {t(`lto.rte.link_to_file.select_item_title`)}
        </Typography>
        <Typography id="modal-modal-description-upload" sx={{ mt: 2 }}>
          Upload:
          <div className={`rte-file-upload-component${uploading ? ' uploading' : ''}`} onClick={() => fileuploadField.current.click()} onDrop={onDrop} onDragOver={(e) => {e.preventDefault(); e.dataTransfer.dropEffect = 'copy';}} onDrag={(e) => {e.preventDefault();}} >
              {t('ra.input.file.upload_several')}
          </div>
          <input type="file" ref={fileuploadField} onChange={onFileChange}  style={{ display: 'none'}} accept={allowed.join(',')} />

        </Typography>

        { files.length ?
          <>
            <Typography id="modal-modal-description-select" sx={{ mt: 2 }}>
              {t(`lto.rte.link_to_file.or`)}
            </Typography>
            <Typography id="modal-modal-description-select" sx={{ mt: 2 }}>
            {t(`lto.rte.link_to_file.select`)}:
              <Autocomplete value={fileName} 
                onChange={(e: React.SyntheticEvent, value: any) => setfileName(value)} 
                options={files} 
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option}
                    <a onClick={(e) => deleteFile(e, option)}>
                      <DeleteIcon color="error" />
                    </a>
                  </Box>
                )}
                renderInput={(params) => <TextField {...params} label={t(`lto.rte.link_to_file.select_item`)} />} />
            </Typography>
          </>
          : null
        }
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={() => setLinkToFile(fileName)} color="error">{t(`lto.rte.link_to_file.close_modal`)}</Button>
          <Button onClick={() => setLinkToFile(fileName)} >{t(`lto.rte.link_to_file.select`)}</Button>
        </div>
      </Box>
      </form>
    </Modal>
    </>
  );
};
