import * as React from 'react';
import { Create } from 'react-admin';
import { TopicForm } from './TopicForm';
import {  transformMediaUrlInput } from '../../lib/media/makeUrl'

export const TopicCreate = (props: any) => {
  const transform = data => ({
    ...data,
    mediaUrl: transformMediaUrlInput(data.mediaUrl)
  });

  return (
    <Create {...props} transfor={transform}>
      <TopicForm />
    </Create>
  )
};
