import * as React from 'react';
import { Labeled, useChoicesContext, useInput } from 'react-admin';
import { useCallback, useMemo } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import AccordionDetails from '@mui/material/AccordionDetails';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';

export function GroupedReferenceSelectArrayInput(props: any) {
  const {
    choices: choicesProp,
    className,
    create,
    createLabel,
    createValue,
    format,
    helperText,
    labelKey = 'name',
    groupBy,
    sortBy,
    label,
    isFetching: isFetchingProp,
    isLoading: isLoadingProp,
    onBlur,
    onChange,
    onCreate,
    parse,
    resource: resourceProp,
    source: sourceProp,
    validate,
    extractGroupLabel,
    ...rest
  } = props;

  const [expanded, setExpanded] = React.useState<string>('');
  const { allChoices, source, resource } = useChoicesContext({
    choices: choicesProp,
    isLoading: isLoadingProp,
    isFetching: isFetchingProp,
    resource: resourceProp,
    source: sourceProp,
  });

  const input = useInput({
    format,
    onBlur,
    onChange,
    parse,
    resource,
    source,
    validate,
    ...rest,
  });

  const { field, fieldState, isRequired } = input;

  const handleAccordionChange = useCallback(
    (panel) => (_: any, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    },
    [],
  );

  const groupedMap = useMemo(() => {
    const idMap = (field.value || []).reduce((acc, id) => {
      acc[id] = true;

      return acc;
    }, {});

    const reduced = (allChoices || []).reduce((acc, choice) => {
      if (!acc[choice[groupBy]])
        acc[choice[groupBy]] = { selectedCount: 0, options: [], label: extractGroupLabel(choice) };

      acc[choice[groupBy]].options.push({
        id: choice.id,
        label: choice[labelKey],
        checked: idMap[choice.id],
      });

      if (idMap[choice.id]) acc[choice[groupBy]].selectedCount++;

      return acc;
    }, {});

    const values = [];

    for (const key in reduced) {
      reduced[key]?.options.sort((a, b) => {
        return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
      });

      values.push(reduced[key]);
    }

    return values;
  }, [field, allChoices]);

  const handleChange = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();

      const choice = event.target.value;
      const newValues = [...(field.value || [])];
      const index = newValues.indexOf(choice);

      if (event.target.checked) {
        if (index === -1) newValues.push(choice);
      } else {
        if (index === -1) return; // weird.

        newValues.splice(index, 1);
      }

      field.onChange(newValues);
    },
    [field],
  );

  const checkAll = useCallback(
    (group) => {
      // group.options{.id}
      const newValues = [...(field.value || [])];

      group.options.forEach((option) => {
        const index = newValues.indexOf(option.id);

        if (index === -1) newValues.push(option.id);
      });

      field.onChange(newValues);
    },
    [field],
  );

  return (
    <Labeled
      isRequired={isRequired}
      label={label}
      id={`grouped-label`}
      color={fieldState?.error ? 'error' : undefined}
      style={{ marginBottom: '2em' }}
      source={source}
      resource={resource}
    >
      <>
        {groupedMap?.map((group: any) => (
          <Accordion
            expanded={expanded === group.label}
            onChange={handleAccordionChange(group.label)}
            key={`group_${group.label}`}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
              <Typography>
                {group.label}
                <Typography variant="caption" padding={1}>
                  ({group.selectedCount}/{group.options.length})
                </Typography>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup>
                {group.options.map(
                  ({ checked = false, label, id }: { id: string; checked?: boolean; label: string }) => {
                    return (
                      <FormControlLabel
                        key={`label_${id}`}
                        control={<Checkbox onChange={handleChange} checked={checked} name={id} value={id} />}
                        label={label}
                      />
                    );
                  },
                )}
              </FormGroup>
              <FormHelperText>
                <Link
                  href="#"
                  color="inherit"
                  onClick={(e) => {
                    e.preventDefault();
                    checkAll(group);
                  }}
                >
                  {/* @todo translate instead of hardcode. */}
                  Selecteer alles
                </Link>
              </FormHelperText>
            </AccordionDetails>
          </Accordion>
        ))}
      </>
    </Labeled>
  );
}
