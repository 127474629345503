import { httpClient } from '../dataProvider'
import { API_URL } from '../constants';

export const doFileUpload = async (file: File, path: string = '/media') => {
  const formData = new FormData();
 
  formData.append(
      "FileToUpload",
      file,
      file.name
  );

  const { json } = await httpClient(`${API_URL}${path}`, {
    method: 'POST',
    body: formData,
  });
  return json
 }