import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { GuardedComponents } from '../../lib/permissions/types';
import { ResourceDefinition } from '../types';
import { ProfileCreate, ProfileEdit, ProfileList } from '.';

export default {
  permissionResource: 'Profile',
  name: 'profiles',
  hideInNav: true,
  icon: AccountCircleIcon,
  components: new GuardedComponents([
    ['list', { action: 'GetList', component: ProfileList }],
    ['edit', { action: 'Update', component: ProfileEdit }],
    ['create', { action: 'Create', component: ProfileCreate }],
  ]),
  i18n: {
    nl: {
      name: 'Profiel |||| Profielen',
      fields: {
        name: 'Naam',
        isActive: 'Actief',
        isBeursGoesMember: 'Beurs Goes lid',
      },
    },
  },
} as ResourceDefinition;
