import BadgeIcon from '@mui/icons-material/Badge';
import { GuardedComponents } from '../../lib/permissions/types';
import { ResourceDefinition } from '../types';
import { RoleCreate, RoleEdit, RoleList, RoleShow } from '.';

export default {
  permissionResource: 'Role',
  name: 'roles',
  icon: BadgeIcon,
  components: new GuardedComponents([
    ['list', { action: 'GetList', component: RoleList }],

    ['edit', { action: 'Update', component: RoleEdit }],
    ['create', { action: 'Create', component: RoleCreate }],
  ]),
  i18n: {
    nl: {
      name: 'Rol |||| Rollen',
      fields: {
        name: 'Naam',
      },
    },
  },
} as ResourceDefinition;
