import * as React from 'react';
import { PresetForm } from './PresetForm';
import { Edit } from '../../components/Edit';
import { NoDeleteToolbar } from '../../components/NoDeleteToolbar';
import { RecordEditTitle } from '../../components/RecordTitle';
import { useTranslate } from 'ra-core';

export const PresetEdit = (props: any) => {
  const translate = useTranslate();

  return (
    <Edit title={translate('resources.presets.name', { smart_count: 1 })} {...props} meta={{ embed: 'topics' }}>
      <PresetForm />
    </Edit>
  );
};
