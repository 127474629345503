import ImportExportIcon from '@mui/icons-material/ImportExport';
import { GuardedComponents } from '../../lib/permissions/types';
import { ResourceDefinition } from '../types';
import { ImportCreate, ImportEdit, ImportList, ImportShow } from '.';

export default {
  permissionResource: 'Import',
  name: 'import/status',
  icon: ImportExportIcon,
  components: new GuardedComponents([
    ['list', { action: 'Sync', component: ImportList }],
    ['show', { action: 'Sync', component: ImportShow }],
    ['edit', { action: 'Sync', component: ImportEdit }],
    ['create', { action: 'Sync', component: ImportCreate }],
  ]),
  i18n: {
    nl: {
      name: 'Import |||| Imports',
      fields: {
        name: 'Naam',
      },
    },
  },
} as ResourceDefinition;
