import ImportResource from './Import/ImportResource';
import PriceListingResource from './PriceListing/PriceListingResource';
import DeviceResource from './Device/DeviceResource';
import SurveyResource from './Survey/SurveyResource';
import MembershipResource from './Membership/MembershipResource';
import PresetResource from './Preset/PresetResource';
import SectorResource from './Sector/SectorResource';
import UserResource from './User/UserResource';
import ProfileResource from './Profile/ProfileResource';
import DepartmentResource from './Department/DepartmentResource';
import LtoResource from './Lto/LtoResource';
import MediaResource from './Media/MediaResource';
import PermissionResource from './Permission/PermissionResource';
import CategoryResource from './Category/CategoryResource';
import TopicResource from './Topic/TopicResource';
import TransactionResource from './Transaction/TransactionResource';
import NewsResource from './News/NewsResource';
import RoleResource from './Role/RoleResource';
import RegionResource from './Region/RegionResource';
import AgendaResource from './Agenda/AgendaResource';
import { Resources } from './types';

// NOTE: This file (imports 👆 and array 👇) gets updated when using the scaffold cli.
const resources: Resources = [
  TopicResource,
  NewsResource,
  AgendaResource,
  SurveyResource,
  CategoryResource,
  MediaResource,
  DepartmentResource,
  RegionResource,
  ProfileResource,
  RoleResource,
  UserResource,
  PermissionResource,
  LtoResource,
  SectorResource,
  PresetResource,
  MembershipResource,
  DeviceResource,
  PriceListingResource,
  TransactionResource,
  ImportResource
];

export default resources;
