import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { GuardedComponents } from '../../lib/permissions/types';
import { ResourceDefinition } from '../types';

export default {
  permissionResource: 'Membership',
  name: 'memberships',
  icon: QuestionMarkIcon,
  hideInNav: true,
  components: new GuardedComponents([]),
  i18n: {
    nl: {
      name: 'Lidmaatschap |||| Lidmaatschappen',
      fields: {
        name: 'Naam',
      },
    },
  },
} as ResourceDefinition;
