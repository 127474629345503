import { useResourceDefinition, useTranslate } from 'ra-core';
import { useGetResourceLabel, useResourceContext } from 'react-admin';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import * as React from 'react';

// Copy paste from the react-admin source code. All I did was change the title, icon and remove the button.
export const EmptyStatus = (props: EmptyProps) => {
  const { className } = props;
  const { hasCreate } = useResourceDefinition(props);
  const resource = useResourceContext(props);
  const translate = useTranslate();
  const getResourceLabel = useGetResourceLabel();
  const resourceName = translate(`resources.${resource}.forcedCaseName`, {
    smart_count: 0,
    _: getResourceLabel(resource, 0),
  });
  const emptyMessage = translate('ra.page.empty', { name: resourceName });
  const inviteMessage = translate('ra.page.invite');

  return (
    <Root className={className}>
      <div className={EmptyClasses.message}>
        <ThumbUpIcon className={EmptyClasses.icon} />
        <Typography variant="h4" paragraph>
          {translate(`lto.screens.import_status.success_title`, {
            _: emptyMessage,
          })}
        </Typography>
        <Typography variant="body1">
          {translate(`lto.screens.import_status.success_description`, {
            _: inviteMessage,
          })}
        </Typography>
      </div>
    </Root>
  );
};

export interface EmptyProps {
  resource?: string;
  hasCreate?: boolean;
  className?: string;
}

const PREFIX = 'RaEmpty';

export const EmptyClasses = {
  message: `${PREFIX}-message`,
  icon: `${PREFIX}-icon`,
  toolbar: `${PREFIX}-toolbar`,
};

const Root = styled('span', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  flex: 1,

  [`& .${EmptyClasses.message}`]: {
    textAlign: 'center',
    margin: '0 1em',
    marginTop: theme.spacing(3),
    opacity: theme.palette.mode === 'light' ? 0.5 : 0.8,
    color: theme.palette.mode === 'light' ? 'inherit' : theme.palette.text.primary,
  },
  [`& .${EmptyClasses.icon}`]: {
    width: '9em',
    height: '9em',
  },
  [`& .${EmptyClasses.toolbar}`]: {
    textAlign: 'center',
    marginTop: '2em',
  },
}));
