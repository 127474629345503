import { useTranslate } from 'ra-core';
import { useEditContext, useRecordContext, useShowContext } from 'react-admin';
import * as React from 'react';

export function RecordEditTitle(props: RecordTitleProps) {
  const { record, resource } = useEditContext();

  return <RecordTitle record={record} resource={resource} {...props} />;
}

export function RecordShowTitle(props: RecordTitleProps) {
  const { record, resource } = useShowContext();

  return <RecordTitle record={record} resource={resource} {...props} />;
}

export function RecordTitle({ record, resource, field = 'name', property = 'name', prefix }: BaseRecordTitleProps) {
  const translate = useTranslate();

  return (
    <span>
      {translate(`${prefix ? prefix : `resources.${resource}`}.${field}`, { smart_count: 1 })}{' '}
      {record ? `"${record[property]}"` : ''}
    </span>
  );
}

interface BaseRecordTitleProps extends RecordTitleProps {
  resource: string;
  record: Record<string, any> & { id: string };
}

interface RecordTitleProps {
  field?: string;
  property?: string;
  prefix?: null;
}
