import BackupTableIcon from '@mui/icons-material/BackupTable';
import { GuardedComponents } from '../../lib/permissions/types';
import { ResourceDefinition } from '../types';
import { PresetCreate, PresetEdit, PresetList, PresetShow } from '.';

export default {
  permissionResource: 'Preset',
  name: 'presets',
  icon: BackupTableIcon,
  components: new GuardedComponents([
    ['list', { action: 'GetList', component: PresetList }],
    ['edit', { action: 'Update', component: PresetEdit }],
    ['create', { action: 'Create', component: PresetCreate }],
  ]),
  i18n: {
    nl: {
      name: 'Preset |||| Presets',
      fields: {
        sector: 'Sector',
        topics: 'Thema\'s'
      },
    },
  },
} as ResourceDefinition;
