import KeyIcon from '@mui/icons-material/Key';
import { GuardedComponents } from '../../lib/permissions/types';
import { ResourceDefinition } from '../types';
import { PermissionCreate, PermissionEdit, PermissionList, PermissionShow } from '.';

export default {
  permissionResource: 'Permission',
  name: 'permissions',
  icon: KeyIcon,
  hideInNav: true,
  components: new GuardedComponents([
    ['list', { action: 'GetList', component: PermissionList }],

    ['edit', { action: 'Update', component: PermissionEdit }],
    ['create', { action: 'Create', component: PermissionCreate }],
  ]),
  i18n: {
    nl: {
      name: 'Rechten |||| Rechten',
      fields: {
        name: 'Naam',
      },
    },
  },
} as ResourceDefinition;
