import * as React from 'react';
import { Edit } from 'react-admin';
import { RegionForm,  } from './RegionForm';
import { RecordEditTitle } from '../../components/RecordTitle';

export const RegionEdit = (props: any) => (
  <Edit title={<RecordEditTitle />} {...props}>
    <RegionForm />
  </Edit>
);
