import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { GuardedComponents } from '../../lib/permissions/types';
import { ResourceDefinition } from '../types';
import { DepartmentCreate, DepartmentEdit, DepartmentList, DepartmentShow } from '.';

export default {
  permissionResource: 'Department',
  name: 'departments',
  icon: AccountTreeIcon,
  components: new GuardedComponents([
    ['list', { action: 'GetList', component: DepartmentList }],

    ['edit', { action: 'Update', component: DepartmentEdit }],
    ['create', { action: 'Create', component: DepartmentCreate }],
  ]),
  i18n: {
    nl: {
      name: 'Afdeling |||| Afdelingen',
      fields: {
        name: 'Naam',
      },
    },
  },
} as ResourceDefinition;
