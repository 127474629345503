import RoomIcon from '@mui/icons-material/Room';
import { GuardedComponents } from '../../lib/permissions/types';
import { ResourceDefinition } from '../types';
import { LtoCreate, LtoEdit, LtoList, LtoShow } from '.';

export default {
  permissionResource: 'Lto',
  name: 'ltos',
  //superAdminOnly: true,
  icon: RoomIcon,
  components: new GuardedComponents([
    ['list', { action: 'GetList', component: LtoList }],
    // ['show', { action: 'GetOne', component: LtoShow }],
    ['edit', { action: 'Update', component: LtoEdit }],
    ['create', { action: 'Create', component: LtoCreate }],
  ]),
  i18n: {
    nl: {
      name: 'LTO |||| LTOs',
      fields: {
        name: 'Naam',
        phoneNumber: 'Telefoonnummer',
        email: 'Emailadres',
        whatsAppNumber: 'WhatsApp',
        contactText: 'Contact toelichting',
        contactInfo: 'Contact info'
      },
    },
  },
} as ResourceDefinition;
