import { useGetIdentity } from 'react-admin';
import { useMemo } from 'react';
import { usePermissionGuard } from './usePermissionGuard';

export function useIsBeursGoesMember() {
  const { guard } = usePermissionGuard();
  const { identity, isLoading: identityLoading } = useGetIdentity();

  return useMemo(() => {
    return {
      loading: identityLoading,
      isBeursGoesMember: identityLoading ? false : !!(identity.isBeursGoesMember || guard.isSuperAdmin()),
    };
  }, [identity, identityLoading, guard.isSuperAdmin()]);
}
