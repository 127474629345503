import { Transformation } from './Transformation';
import { IMAGE_KIT_BASE_URL } from '../constants';

export function makeUrl(path: string, transformation?: Transformation) {
  const parts = [IMAGE_KIT_BASE_URL];

  if (transformation) parts.push(transformation);

  parts.push(path);

  return parts.join('/');
  // https://ik.imagekit.io/gpgcpzueuwg/tr:w-200,h-300/SPELEN_pyZA9hQR0.png
}

export function transformMediaUrlInput (value: string) {
  if(!value) return null;
  try{
    const url = new URL(value);
    const params = new URLSearchParams(url.search);
    params.delete('width')
    params.delete('height')
    params.delete('crop')

    const new_url = new URL(`${url.origin}${url.pathname}?${params}`);
    
    return url.search.length ? new_url.href : new_url.href.replace('?', '')
  }catch (e){
    return value;
  }
}
