import { ReferenceArrayInput, ReferenceInput, SelectArrayInput, SelectInput, SimpleForm, TextInput } from 'react-admin';
import * as React from 'react';
import { NoDeleteToolbar } from '../../components/NoDeleteToolbar';

export function PresetForm() {
  return (
    <SimpleForm toolbar={<NoDeleteToolbar />}>
      <ReferenceInput source="sectorId" reference="sectors">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceArrayInput label={"resources.presets.fields.topics"} source="topicIds" reference="topics" perPage={99999} sort={{ field: 'name', order: 'ASC'}}>
        <SelectArrayInput label={"resources.presets.fields.topics"}  optionText="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  );
}
