import {
  List,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  downloadCSV,
  DateInput,
  SelectInput,
  useListController,
  useRecordContext,
  NumberFieldProps, 
  FilterButton, 
  ExportButton, 
  TopToolbar
} from 'react-admin';
import { useEffect, useState } from 'react'
import { usePermissionGuard } from '../../lib/permissions/usePermissionGuard';
import { dataProvider } from '../../lib/dataProvider';
import { Product } from '../types';

interface ProductVariantCombo {
  id: string,
  text: string
}

const exporter = async (filters: any) => {
  if (filters['dateFrom_gte']) {
    filters['fromDate'] = filters['dateFrom_gte'];
    delete filters['dateFrom_gte'];
  }
  if (filters['dateTo_lte']) {
    filters['toDate'] = filters['dateTo_lte'];
    delete filters['dateTo_lte'];
  }

  const data = await dataProvider.getRaw("/transactions/export", filters);
  downloadCSV(data, "transacties-" + (new Date()).toLocaleString("nl-NL"))
};

const flattenProducts = (products: Product[]) => {
  let flatProductList: ProductVariantCombo[] = [];
  products.map(p => p.variants.map(v => flatProductList.push({ id: v.id, text: `${p.name}, ${v.variant}` })))
  return flatProductList;
}

const postFilters = (products: ProductVariantCombo[]) => [
  <DateInput source="dateFrom_gte" />,
  <DateInput source="dateTo_lte" />,
  <SelectInput source='productId' optionText="text" optionValue='id' choices={products} />
];

const PriceField = (props: NumberFieldProps) => {
  const record = useRecordContext();

  let unitText = ''
  switch (record.unit) {
    case 'PricePer100':
      unitText = 'per  100 kg';
      break;
    case 'PricePer1000':
      unitText = 'per 1000 kg';
      break;
  }

  return (
    <span>{unitText}</span>
  )
}

const ListActions = () => (
  <TopToolbar>
      <FilterButton />
      <ExportButton maxResults={999999999}/>
  </TopToolbar>
);

export function TransactionList(props: any) {
  const { guard } = usePermissionGuard();
  const canViewCreatedBy = guard.isAllowed('Transaction', 'ViewCreatedBy');
  const canExport = guard.isAllowed('Transaction', 'Export');
  const { filterValues } = useListController();
  const [products, setProducts] = useState<Product[]>();

  useEffect(() => {
    async function getProducts() {
      const data: Product[] = await dataProvider.get("/products");
      setProducts(data);
    }
    getProducts();
  }, [setProducts]);

  if (!products) return null;

  return (
    <List actions={<ListActions />} sort={{ field: 'createdAt', order: 'DESC' }} {...props} exporter={canExport ? () => exporter(filterValues) : canExport} filters={postFilters(flattenProducts(products))}>
      <Datagrid bulkActionButtons={null} sx={{
        '& th.column-price': {
          textAlign: 'right',
        },
        '& td.column-price': {
          textAlign: 'right',
          paddingRight: 0,
          fontVariantNumeric: 'tabular-nums'
        },
        '& th.column-weight': {
          textAlign: 'right',
        },
        '& td.column-weight': {
          textAlign: 'right',
        },
        '& .column-price-unit': {
          width: '1%',
          whiteSpace: 'nowrap',
        },
      }}>
        <DateField source="transactionDate" locales="nl-NL" />
        {canViewCreatedBy || guard.isSuperAdmin() ? <TextField source="createdBy.name" sortBy='createdBy' label="resources.transactions.fields.createdBy" /> : null}
        <TextField source="name" sortBy='product.name' />
        <TextField source="variant" sortBy='product.variant' />
        <TextField source="weight" className='weigth-in-ton' />
        <NumberField source="price" locales="nl-NL" options={{ style: 'currency', currency: 'EUR' }} />
        <PriceField source='priceUnit' sortable={false} />
        <TextField source="deliveryTerm" />
      </Datagrid>
    </List>
  );
}
