import ShowChartIcon from '@mui/icons-material/ShowChart';
import { GuardedComponents } from '../../lib/permissions/types';
import { ResourceDefinition } from '../types';
import { PriceListingCreate, PriceListingEdit, PriceListingList, PriceListingShow } from '.';

export default {
  permissionResource: 'PriceListing',
  name: 'price-listings',
  icon: ShowChartIcon,
  components: new GuardedComponents([
    ['list', { action: 'GetList', component: PriceListingList, onlyBeursGoes: true  }],
    ['show', { action: 'GetOne', component: PriceListingShow, onlyBeursGoes: true }],
    ['edit', { action: 'Update', component: PriceListingEdit, onlyBeursGoes: true }],
    ['create', { action: 'Create', component: PriceListingCreate, onlyBeursGoes: true }],
  ]),
  i18n: {
    nl: {
      name: 'Prijsnotering |||| Prijsnoteringen',
      fields: {
        name: 'Naam',
        isEnabled: 'Actief',
        minimumPrice: 'Minimum prijs',
        maximumPrice: 'Maximum prijs',
        topic: 'Thema'
      },
    },
  },
} as ResourceDefinition;
