import { ComponentType, ReactElement } from 'react';

export interface Permission {
  resource: string;
  action: string;
}

export interface PermissionDto {
  id: string;
  resource: string;
  action: string;
  label: string;
  createdAt: string;
  updatedAt: string;
}

export interface PermissionMap {
  [resource: string]: {
    [action: string]: true;
  };
}

export type ComponentKey = 'list' | 'show' | 'edit' | 'create' | string;
export type DefaultActions = 'GetList' | 'GetOne' | 'Update' | 'Create' | 'Delete' | string;

export class GuardedComponents extends Map<ComponentKey, GuardedComponentDefinition> {}

export type FilteredComponents = {
  [key in ComponentKey]?: ComponentType<any> | ReactElement;
};

export interface GuardedComponentDefinition {
  action: DefaultActions;
  component: ComponentType<any> | ReactElement;
  onlyBeursGoes?: boolean
}
