import { Mark, mergeAttributes } from '@tiptap/core'

interface LinkToContentType {
  id: string
  resource: string
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    linktocontent: {
      /**
       * Set a link mark
       */
      setLinkToContent: (linkToContent: LinkToContentType) => ReturnType
      /**
       * Unset a link mark
       */
      unsetLinkToContent: () => ReturnType
    }
  }
}

export const LinkToContent = Mark.create({
  name: 'linktocontent',

  priority: 1000,

  keepOnSplit: false,

  onCreate() {
  },

  onDestroy() {
  },

  inclusive() {
    return false
  },

  addAttributes() {
    return {
      href: {
        default: null,
      },
      linkToContent:{
        id: null,
        resource: null
      }
    }
  },

  parseHTML() {
    return [{ tag: 'a[href]:not([href *= "javascript:" i])' }]
  },

  renderHTML({ HTMLAttributes }) {
    return ['a', {href: `/#/${HTMLAttributes.linkToContent?.resource}/${HTMLAttributes.linkToContent?.id}`, 'data-linktocontent-resource': HTMLAttributes.linkToContent?.resource, 'data-linktocontent-id': HTMLAttributes.linkToContent?.id }, 0]
  },

  addCommands() {
    return {
      setLinkToContent:
        linkToContent => ({ chain }) => {
          return chain().setMark(this.name, {linkToContent}).run()
        },

      unsetLinkToContent:
        () => ({ chain }) => {
          return chain()
            .unsetMark(this.name, { extendEmptyMarkRange: true })
            .run()
        },
    }
  }
})