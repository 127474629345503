import BallotIcon from '@mui/icons-material/Ballot';
import { GuardedComponents } from '../../lib/permissions/types';
import { ResourceDefinition } from '../types';
import { SurveyCreate, SurveyEdit, SurveyList, SurveyShow } from '.';

export default {
  permissionResource: 'Survey',
  name: 'surveys',
  icon: BallotIcon,
  components: new GuardedComponents([
    ['list', { action: 'GetList', component: SurveyList }],
    ['show', { action: 'GetOne', component: SurveyShow }],
    ['edit', { action: 'Update', component: SurveyEdit }],
    ['create', { action: 'Create', component: SurveyCreate }],
  ]),
  i18n: {
    nl: {
      name: 'Enquête |||| Enquêtes',
      tabs: {
        placement: 'Plaatsing',
        questions: 'Vragen',
      },
      fields: {
        createdBy: 'Aangemaakt door',
        archive: "Archiveer",
        deArchive: "Archivering ongedaan maken",
        isArchived: "Gearchiveerd",
        title: 'Titel',
        description: 'Omschrijving',
        departments: 'Afdelingen',
        options: 'Opties',
        type: 'Soort',
        text: 'Tekst',
        topic: 'Thema',
        option: 'Optie',
        publishAt: 'Inplannen publiceren',
        publishDate: 'Gepubliceerd op',
        mandatory: 'Verplicht',
        maximum_length: 'Maximale lengte',
        showRealtimeResults: 'Realtime resultaten tonen?',
        publishDate_gte: 'Gepubliceerd na',
        publishDate_lte: 'Gepubliceerd voor',
        extra_options: "Extra opties",
        lto: "LTO"
      },
    },
  },
} as ResourceDefinition;
