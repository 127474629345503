import {
  useInput,
} from 'react-admin';
import { useTranslate } from 'ra-core';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import dayjs from 'dayjs';

const formatDate = (date: Date) => {
  const day = '0'+ date.getDate(), 
    month = '0'+ (date.getMonth() + 1), 
    year = date.getFullYear(),
    hours = '0'+ date.getHours(),
    minutes = '0'+ date.getMinutes();
    return `${year}-${month.slice(-2)}-${day.slice(-2)}T${hours.slice(-2)}:${minutes.slice(-2)}:00.000`
}

export const DateTimePicker = ({source, label, validate, value = null}) => {
  const translate = useTranslate();

  const {
    field,
    fieldState,
    isRequired,
  } = useInput({ source, validate, label });

  const helperText = fieldState.isTouched && !field.value && isRequired ? translate('ra.validation.required') : undefined
  let dateValue = field.value ? dayjs(field.value): null
  if(value) {
    dateValue = dayjs(value)
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MuiDateTimePicker
          className='Mui-error'
          //value={field.value ? new Date(field.value): new Date()}
          defaultValue={dateValue}
          format='DD-MM-YYYY HH:mm'
          onChange={(value: any, context) => { value ? field.onChange(formatDate(new Date(value))) : field.onChange(null)}}
          ampm={false}
          label={translate(label)}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
          slotProps={{
            textField: {
              error: fieldState.isTouched && !field.value && isRequired,
              required: isRequired,
              helperText,
            },
          }}
        />
    </LocalizationProvider>
  )
}