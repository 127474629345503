import React, { useEffect, useState } from 'react';
import { Node, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer, NodeViewWrapper } from '@tiptap/react'
import { doFileUpload } from '../../../lib/media/doFileUpload';
import { makeUrl } from '../../../lib/media/makeUrl';
import { listMedia } from '../../../lib/media/listMedia';
import { Transformation } from '../../../lib/media/Transformation';
import { useTranslate } from 'ra-core';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
      image: {
          /**
           * Insert a youtube video
           */
          setImage: () => ReturnType;
      };
  }
}

export const Image = Node.create({
    name: 'image',
    atom: true,
    addOptions() {
        return {
            width: 640,
            height: 480
        };
    },
    group() {
        return 'block';
    },
    draggable: true,
    addAttributes() {
        return {
            file: {
                default: null,
            },
            src: {
                default: null,
            },
            width: {
                default: this.options.width,
            },
            height: {
                default: this.options.height,
            }
        };
    },
    parseHTML() {
        return [
            {
                tag: 'rte-image-component',
            },
        ];
    },
    addCommands() {
        return {
          setImage: () => ({ commands }) => {
              // do upload here somewhere
              return commands.insertContent({
                  type: this.name,
              });
          },
        };
    },
    renderHTML({ HTMLAttributes }) {
        return [
            'div',
            [
                'rte-image-component',
                mergeAttributes(this.options.HTMLAttributes, {
                    width: this.options.width,
                    height: this.options.height,
                }, HTMLAttributes),
            ],
        ];
    },
    addNodeView() {
        return ReactNodeViewRenderer(ImageUploadComponent)
      },
});

function ImageUploadComponent (props) {
    const t = useTranslate();
    const [uploading, setUploading] = useState(false)
    const [images, setImages] = useState([])
    const allowed = ['image/png', 'image/jpeg', 'image/webp', 'image/gif']
    const fileuploadField = React.createRef<HTMLInputElement>();

    useEffect(() => {
        // Update the document title using the browser API
        listMedia().then(data => setImages(data))
    },[props]);

    const doUpload = async (file: File) => {
        if(!allowed.includes(file.type)) {
            console.warn(`Upload of file with type '${file.type}' is not allowed!`)
            return;
        }
        setUploading(true);
        const uploadData = await doFileUpload(file)
        const metadata = JSON.parse(uploadData.metaData);
        const finalurl = makeUrl(uploadData.filePath, Transformation.Content)
        props.updateAttributes({ src: finalurl, width: metadata.Width, height: metadata.Height   })
        setUploading(false);
    }

    const setUploadedFile = (uploadData:any) => {
        const metadata = JSON.parse(uploadData.metaData);
        const finalurl = makeUrl(uploadData.filePath, Transformation.Content)
        props.updateAttributes({ src: finalurl, width: metadata.Width, height: metadata.Height   })
    }
    
    const onFileChange = (event) => {
        doUpload(event.target.files[0])
    }

    const onDrop = (event) => {
        event.preventDefault();
        
        if (event.dataTransfer.items) {
            const item = event.dataTransfer.items[0]
            if (item.kind === "file") {
                doUpload(item.getAsFile())
            }
        } else {
            doUpload(event.dataTransfer.files[0]);
        }
    }
    const { src } = props.node.attrs
    return (
      <NodeViewWrapper className={props.selected ? 'react-component ProseMirror-selectednode' : 'react-component'} data-drag-handle>
        { src ? 
        <img src={src} width={640} />
        :
        <>
            <div className={'rte-image-component' + (uploading ? ' uploading' : '')} onClick={() => fileuploadField.current.click()} onDrop={onDrop} onDrag={(e) => e.preventDefault()} >
                {t('ra.input.image.upload_several')}
                <div className='last-uploaded'>
                    <div><small>{t('lto.generic.media.images.select_uploaded_earlier')}:</small></div>
                    {images.map(image => <a key={image.id} onClick={(e) => {e.stopPropagation(); setUploadedFile(image); }} title={image.name} style={{ backgroundImage: `url(${makeUrl(image.filePath, Transformation.ContentThumbnail)})` }} />)}
                </div>
            </div>
            <input type="file" ref={fileuploadField} onChange={onFileChange}  style={{ display: 'none'}} accept={allowed.join(',')} />
        </>
        }
      </NodeViewWrapper>
    )
  }