import * as React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import { NoDeleteToolbar } from '../../components/NoDeleteToolbar';

export const PermissionEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm toolbar={<NoDeleteToolbar />}>
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
);
