import TravelExplore from '@mui/icons-material/TravelExplore';
import { GuardedComponents } from '../../lib/permissions/types';
import { ResourceDefinition } from '../types';
import { RegionList, RegionEdit, RegionCreate } from '.';

export default {
  permissionResource: 'Region',
  name: 'regions',
  icon: TravelExplore,
  components: new GuardedComponents([
    ['list', { action: 'GetList', component: RegionList }],
    ['edit', { action: 'Update', component: RegionEdit }],
    ['create', { action: 'Create', component: RegionCreate }],
  ]),
  i18n: {
    nl: {
      name: 'Regio |||| Regios',
      fields: {
        createdAt: 'Aangemaakt op',
        'createdBy.name': 'Aangemaakt door',
        name: 'Regio',
        'lto.name': 'LTO'
      },
    },
  },
} as ResourceDefinition;
