import { email, FormTab, TabbedForm, TabbedFormProps, TextInput } from 'react-admin';
import * as React from 'react';
import { ProfileTab } from './ProfileTab';
import { NoDeleteToolbar } from '../../components/NoDeleteToolbar';
import { useIsBeursGoesMember } from '../../lib/permissions/useIsBeursGoesMember';

const validateEmail = email();

export function UserForm(props: Partial<TabbedFormProps>) {
  const isBeursGoes = useIsBeursGoesMember();

  return (
    <TabbedForm {...props}>
      <FormTab label="Algemeen">
        <TextInput source="firstName" />
        <TextInput source="lastName" />
        <TextInput source="emailAddress" validate={validateEmail} required />
        <TextInput source="phoneNumber" />
      </FormTab>
      <ProfileTab profileIndex={0} profileType="Dashboard" name="Dashboard" isBeursGoes={isBeursGoes} />
      <ProfileTab profileIndex={1} profileType="App" name="App" isBeursGoes={isBeursGoes} />
    </TabbedForm>
  );
}
