import {
  Show,
  SimpleShowLayout,
  Button,
  TextField,
  TopToolbar,
  useRecordContext,
  useTranslate,
  useNotify,
  DataProviderContext,
  Confirm,
  useRefresh,
  downloadCSV,
  EditButton,
  useCreatePath,
  BooleanField,
  DateField,
} from 'react-admin';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Divider from '@mui/material/Divider';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ContentCreateIcon from '@mui/icons-material/Create';
import { Question } from './Survey.types';
import { useCallback, useContext, useState } from 'react';
import { RecordShowTitle } from '../../components/RecordTitle';
import { usePermissionGuard } from '../../lib/permissions/usePermissionGuard';
import SurveyResource from './SurveyResource';


interface AnswersProps {
  question: Question;
}

function Answers({ question }: AnswersProps) {
  const totalCount = question.options?.reduce((acc, option) => {
    return acc + option.count;
  }, 0);
  return (
    <Box>
      {question.type === 'MultipleChoice' && question.options?.map((option) => {
        const rawPercentage = option.count / totalCount;
        const percentage = (rawPercentage > -1 ? rawPercentage : 0) * 100;

        return (
          <Box sx={{ maxWidth: 400 }} mt={1} key={`option_${option.id}`}>
            <Typography variant="subtitle2" gutterBottom component="div">
              {option.text}{' '}
              <Typography variant="caption" pl={1}>
                <em>
                  ({option.count}/{totalCount}, {+percentage.toFixed(2)}%)
                </em>
              </Typography>
            </Typography>
            <LinearProgress variant="determinate" value={percentage} />
          </Box>
        );
      })}
      {question.type === 'Open' && question.openAnswers?.map((openAnswer) => (
        <li>
          <Typography variant="subtitle2" gutterBottom component="span">
            {openAnswer}
          </Typography>
        </li>
      ))}
    </Box>
  );
}

const Download = (data, type, filename) => {
  const fakeLink = document.createElement('a');
  fakeLink.style.display = 'none';
  document.body.appendChild(fakeLink);
  // @ts-ignore

  fakeLink.setAttribute('href', `data:${type};base64,${data}`);
  fakeLink.setAttribute('download', `${filename}.xlsx`);
  fakeLink.click();

};

function SurveyShowActions(props) {
  const notify = useNotify();
  const dataProvider = useContext(DataProviderContext);
  const record = useRecordContext(props);
  const refresh = useRefresh();
  const [closing, setClosing] = useState(false);
  const { guard } = usePermissionGuard();
  const translate = useTranslate();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const requestConfirmation = useCallback(() => {
    setConfirmOpen(true);
  }, [setConfirmOpen]);
  const cancelConfirmation = useCallback(() => {
    setConfirmOpen(false);
  }, [setConfirmOpen]);
  const close = useCallback(async () => {
    setClosing(true);

    await dataProvider.post(`/surveys/close/${record.id}`);

    setConfirmOpen(false);
    setClosing(false);

    refresh();
    notify('lto.screens.survey_show.closed', { type: 'success' });
  }, [record]);

  const doExport = useCallback(async () => {
    try{
      const data = await dataProvider.put(`/surveys/export`, {ids: [record.id]});
      Download(data.slice(1,-1), "application/octet-stream", `enquete-${record.title}-${(new Date()).toLocaleString("nl-NL")}`)
    }
    catch {
      notify('lto.screens.user_list.export_error', { type: 'warning' });
    };
  }, [record]);

  if (!record) return <TopToolbar />;

  if (record.closedDate) {
    return (
      <TopToolbar>
        <Button onClick={doExport} label="lto.screens.survey_show.export" />
      </TopToolbar>
    );
  }

  if (!guard.isAllowed(SurveyResource.permissionResource, 'Update')) {
    return <TopToolbar />;
  }

  return (
    <TopToolbar>
      <Confirm
        isOpen={confirmOpen}
        title={translate('lto.screens.survey_show.confirm_close.title')}
        content={translate('lto.screens.survey_show.confirm_close.description')}
        onConfirm={close}
        loading={closing}
        onClose={cancelConfirmation}
      />
      {/* Add your custom actions */}
      {record.status === 'Published' ? (
        <Button
          color="primary"
          onClick={requestConfirmation}
          size="small"
          label={translate('lto.screens.survey_show.close_survey')}
        >
          <AssignmentTurnedInIcon />
        </Button>
        
      ) : null}
      {guard.isAllowed(SurveyResource.permissionResource, 'Update') ? <EditButton /> : null}
    </TopToolbar>
  );
}

export const QuestionsField = (props) => {
  const record = useRecordContext(props);

  return (
    record.questions
      ?.filter((q) => q.type === 'MultipleChoice' || q.type === 'Open')
      ?.map((question) => {
        return (
          <Box mb={3} key={`question_${question.id}`}>
            <Typography variant="overline" display="block" gutterBottom mt={2}>
              {question.title}
            </Typography>
            <Answers question={question} />
          </Box>
        );
      }) ?? null
  );
};

export function SurveyShow() {
  return (
    <Show actions={<SurveyShowActions />} title={<RecordShowTitle property="title" />}>
      <SimpleShowLayout>
        <TextField source="title" />
        <TextField source="description" />
        <BooleanField source="isPublished" label="Open" />
        <BooleanField source="showRealtimeResults" />
        <DateField source="publishDate" showTime />
        <Divider light />
        <QuestionsField source="questions" label={false} />
      </SimpleShowLayout>
    </Show>
  );
}
