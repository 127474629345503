import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { GuardedComponents } from '../../lib/permissions/types';
import { ResourceDefinition } from '../types';
import { AgendaCreate, AgendaEdit, AgendaList } from '.';

export default {
  permissionResource: 'Agenda',
  name: 'agenda',
  icon: CalendarTodayIcon,
  components: new GuardedComponents([
    ['list', { action: 'GetList', component: AgendaList }],
    ['edit', { action: 'Update', component: AgendaEdit }],
    ['create', { action: 'Create', component: AgendaCreate }],
  ]),
  i18n: {
    nl: {
      name: 'Agenda |||| Agenda',
      imageHelperText: 'Optimaal resultaat bij 930x1050',
      fields: {
        archive: "Archiveer",
        deArchive: "Archivering ongedaan maken",
        isArchived: "Gearchiveerd",
        exportRegistrations: "Exporteer deelnemers",
        createdBy: 'Aangemaakt door',
        topic: 'Thema',
        isPinned: 'Uitgelicht',
        isPreview: 'Preview modus, alleen de aanmaker van dit bericht (%{createdBy}) kan dit item zien.',
        publishedAt: 'Gepubliceerd op',
        newsIds: 'Gekoppelde actueel items',
        title: 'Titel',
        description: 'Omschrijving',
        allowRegistrations: 'Aanmelden mogelijk',
        location: 'Locatie',
        start: 'Start datum/tijd',
        end: 'Eind datum/tijd',
        pushNotification: {
          enabled: 'Pushnotificatie versturen',
          title: 'Titel',
          description: 'Omschrijving',
        },
        media: "Media",  
        useMediaUrl: 'Url gebruiken in plaats van upload?',
        mediaUrl: "Media url",
        publishAt: 'Inplannen publiceren',
        publishDate: 'Gepubliceerd op',
        publishedAt_gte: 'Gepubliceerd na',
        publishedAt_lte: 'Gepubliceerd voor',
        extra_options: 'Extra opties',
        lto: 'LTO',
        isLocalNews: 'Lokaal'
      },
    },
  },
} as ResourceDefinition;
