import {
  Create,
  useGetIdentity,
} from 'react-admin';
import { dataProvider } from '../../lib/dataProvider';
import { Product } from '../types';
import { useMemo, useEffect, useState } from 'react';
import { PriceListingForm } from './PriceListingForm';

export const PriceListingCreate = (props: any) => {
  const [products, setProducts] = useState<Product[]>();
  const { identity, isLoading: identityLoading } = useGetIdentity();
  const isBeursGoes = useMemo(() => {
    if (identityLoading) return false;

    return identity.isBeursGoesMember;
  }, [identity, identityLoading]);

  useEffect(() =>  {    
    async function getProducts() {
      const data = await dataProvider.get("/products");
      setProducts(data);
    }
    getProducts();
  }, [setProducts]);

  if (identityLoading) return null;
  if (!products) return null;
  return (
    <Create {...props} >
      <PriceListingForm products={products} />
    </Create>
  );
};
