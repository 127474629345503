import * as React from 'react';
import { ReactElement, ReactNode, useEffect } from 'react';
import clsx from 'clsx';
import { useEditor, Editor, EditorOptions, EditorContent } from '@tiptap/react';
import { RichTextInputToolbar, TiptapEditorProvider } from 'ra-input-rich-text';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import TextAlign from '@tiptap/extension-text-align';
import { FormHelperText } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useInput, useResourceContext } from 'ra-core';
import { CommonInputProps, InputHelperText, Labeled, LabeledProps } from 'ra-ui-materialui';
import { Video } from './RTEExtensions/Video/Video'
import { Image } from './RTEExtensions/Image/Image';
import { LinkToContent } from './RTEExtensions/LinkToContent/LinkToContent';
import { LinkToFile } from './RTEExtensions/LinkToFile/LinkToFile';
import { API_URL } from '../lib/constants'
/**
 * A rich text editor for the react-admin that is accessible and supports translations. Based on [Tiptap](https://www.tiptap.dev/).
 * @param props The input props. Accept all common react-admin input props.
 * @param {EditorOptions} props.editorOptions The options to pass to the Tiptap editor.
 * @param {ReactNode} props.toolbar The toolbar containing the editors commands.
 *
 * @example <caption>Customizing the editors options</caption>
 * import { RichTextInput, RichTextInputToolbar } from 'ra-input-rich-text';
 * const MyRichTextInput = (props) => (
 *     <RichTextInput
 *         toolbar={<RichTextInputToolbar size="large" />}
 *         label="Body"
 *         source="body"
 *         {...props}
 *     />
 * );
 *
 * @example <caption>Customizing the toolbar size</caption>
 * import { RichTextInput, RichTextInputToolbar } from 'ra-input-rich-text';
 * const MyRichTextInput = (props) => (
 *     <RichTextInput
 *         toolbar={<RichTextInputToolbar size="large" />}
 *         label="Body"
 *         source="body"
 *         {...props}
 *     />
 * );
 *
 * @example <caption>Customizing the toolbar commands</caption>
 * import { RichTextInput, RichTextInputToolbar } from 'ra-input-rich-text';
 * const MyRichTextInput = ({ size, ...props }) => (
 *     <RichTextInput
 *         toolbar={(
 *             <RichTextInputToolbar>
 *                 <RichTextInputLevelSelect size={size} />
 *                 <FormatButtons size={size} />
 *                 <ListButtons size={size} />
 *                 <LinkButtons size={size} />
 *                 <QuoteButtons size={size} />
 *                 <ClearButtons size={size} />
 *             </RichTextInputToolbar>
 *         )}
 *         label="Body"
 *         source="body"
 *         {...props}
 *     />
 * );
 */
export const RichTextInput = (props: RichTextInputProps) => {
  const {
    className,
    defaultValue = '',
    disabled = false,
    editorOptions = DefaultEditorOptions,
    contentType = 'html',
    fullWidth,
    helperText,
    label,
    readOnly = false,
    source,
    toolbar,
  } = props;

  const resource = useResourceContext(props);
  const {
    id,
    field,
    isRequired,
    fieldState,
    formState: { isSubmitted },
  } = useInput({ ...props, source, defaultValue });

  const editor = useEditor({
    ...editorOptions,
    extensions: [
      StarterKit,
      Link,
      Underline,
      Video,
      Image,
      LinkToContent,
      LinkToFile.configure({ baseUrl: API_URL }),
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
    ],
    editable: !disabled && !readOnly,
    content: field.value,
    editorProps: {
      attributes: {
        id,
      },
    },
  });

  useEffect(() => {
    if (fieldState.isTouched || !editor) return;

    // Don't judge me. You go and spend the time setting up invalidate cache.
    // Why this works: react-query caches, so the input field renders optimistically. But it never updates.
    // So, the contents upon edit don't change when you return to the form.
    // This only runs once, so yes it's icky... But it works.
    // A better solution would be to invalidate and clear the local cache on a successful save.
    if (JSON.stringify(editor.getJSON()) !== JSON.stringify(field.value)) {
      editor?.commands?.setContent(field.value);
    }
  }, [editor, fieldState, field]);

  const { error, isTouched } = fieldState;

  useEffect(() => {
    if (!editor) return;

    editor.setOptions({
      editable: !disabled && !readOnly,
      editorProps: {
        attributes: {
          id,
        },
      },
    });
  }, [disabled, editor, readOnly, id]);

  useEffect(() => {
    if (!editor) {
      return;
    }

    const handleEditorUpdate = () => {
      if (editor.isEmpty) {
        field.onChange('');
        field.onBlur();

        return;
      }

      const content = contentType === 'html' ? editor.getHTML() : editor.getJSON();

      field.onChange(content);
      field.onBlur();
    };

    editor.on('update', handleEditorUpdate);
    return () => {
      editor.off('update', handleEditorUpdate);
    };
  }, [contentType, editor, field]);

  return (
    <Labeled
      mt={1}
      isRequired={isRequired}
      label={label}
      id={`${id}-label`}
      color={fieldState?.error ? 'error' : undefined}
      source={source}
      resource={resource}
      fullWidth={fullWidth}
    >
      <RichTextInputContent
        className={clsx('ra-input', `ra-input-${source}`, className)}
        editor={editor}
        error={error}
        helperText={helperText}
        id={id}
        isTouched={isTouched}
        isSubmitted={isSubmitted}
        invalid={!!error}
        toolbar={toolbar || <RichTextInputToolbar />}
      />
    </Labeled>
  );
};

/**
 * Extracted in a separate component so that we can remove fullWidth from the props injected by Labeled
 * and avoid warnings about unknown props on Root.
 */
const RichTextInputContent = ({
  className,
  editor,
  error,
  fullWidth,
  helperText,
  id,
  isTouched,
  isSubmitted,
  invalid,
  toolbar,
}: RichTextInputContentProps) => (
  <Root className={className}>
    <TiptapEditorProvider value={editor}>
      {toolbar}
      <EditorContent aria-labelledby={`${id}-label`} className={classes.editorContent} editor={editor} />
    </TiptapEditorProvider>
    <FormHelperText
      className={(isTouched || isSubmitted) && invalid ? 'ra-rich-text-input-error' : ''}
      error={(isTouched || isSubmitted) && invalid}
    >
      <InputHelperText touched={isTouched || isSubmitted} error={error?.message} helperText={helperText} />
    </FormHelperText>
  </Root>
);

export const DefaultEditorOptions = {
  extensions: [
    StarterKit.configure({
      heading: {
        levels: [3, 4],
      },
    }),
    Underline,
    Link,
    TextAlign.configure({
      types: ['heading', 'paragraph'],
    }),
  ],
};

const PREFIX = 'RaRichTextInput';
const classes = {
  editorContent: `${PREFIX}-editorContent`,
};
const Root = styled('div', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',

  [`& .${classes.editorContent}`]: {
    width: '100%',
    '& .ProseMirror': {
      minHeight: 100,
      minWidth: 300,
      backgroundColor: theme.palette.background.default,
      borderColor: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)',
      borderRadius: theme.shape.borderRadius,
      borderStyle: 'solid',
      borderWidth: '1px',
      padding: theme.spacing(1),

      '&[contenteditable="false"], &[contenteditable="false"]:hover, &[contenteditable="false"]:focus': {
        backgroundColor: theme.palette.action.disabledBackground,
      },

      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
      '&:focus': {
        backgroundColor: theme.palette.background.default,
      },
      '& p': {
        margin: '0 0 1em 0',
        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
  },
}));

export type RichTextInputProps = CommonInputProps &
  Omit<LabeledProps, 'children'> & {
    disabled?: boolean;
    readOnly?: boolean;
    contentType?: 'json' | 'html';
    editorOptions?: Partial<EditorOptions>;
    toolbar?: ReactNode;
  };

export type RichTextInputContentProps = {
  className?: string;
  editor?: Editor;
  error?: any;
  fullWidth?: boolean;
  helperText?: string | ReactElement | false;
  id: string;
  isTouched: boolean;
  isSubmitted: boolean;
  invalid: boolean;
  toolbar?: ReactNode;
};
