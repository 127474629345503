import * as React from 'react';
import { List, Datagrid, TextField, DateField } from 'react-admin';

export const MediaList = (props: any) => (
  <List {...props}>
    <Datagrid bulkActionButtons={null} rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);
