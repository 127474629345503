import { ToggleButton, ToggleButtonProps } from '@mui/material';
import AddPhotoIcon  from '@mui/icons-material/AddPhotoAlternate';
import { useTranslate } from 'ra-core';
import { useTiptapEditor } from 'ra-input-rich-text';

export const ImageButton = (props: Omit<ToggleButtonProps, 'value'>) => {
  const editor = useTiptapEditor();
  const translate = useTranslate();

  const label = translate('ra.tiptap.clear_format', {
    _: 'Add image',
  });

  const addImage = () => {

      editor.commands.setImage()
  }

  return (
    <ToggleButton
      aria-label={label}
      title={label}
      {...props}
      disabled={!editor?.isEditable}
      value="clear"
      onClick={addImage}
    >
      <AddPhotoIcon fontSize="inherit" />
    </ToggleButton>
  );
};
