import * as React from 'react';
import { Create, useGetIdentity } from 'react-admin';
import { AgendaForm } from './AgendaForm';
import {  transformMediaUrlInput } from '../../lib/media/makeUrl'
import { fromGenericToUTC } from '../../lib/i18n/utils';

export const AgendaCreate = (props: any) => {
  const transform = data => ({
    ...data,
    publishedAt: data.publishedAt ? fromGenericToUTC(data.publishedAt): null,
    newsIds: data.newsIds ?? [],
    mediaUrl: transformMediaUrlInput(data.mediaUrl)
  });
  
  const { identity, isLoading } = useGetIdentity();
  if (isLoading && !identity) return null;
  
  return (
    <Create {...props} transform={transform}>
      <AgendaForm />
    </Create>
  );
};
