import { Mark, mergeAttributes } from '@tiptap/core'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    file: {
      /**
       * Set a link mark
       */
      setLinkToFile: (fileName: string) => ReturnType
      /**
       * Unset a link mark
       */
      unsetLinkToFile: () => ReturnType
    }
  }
}

export const LinkToFile = Mark.create({
  name: 'file',

  priority: 1000,

  keepOnSplit: false,

  inclusive: false,

  addOptions() {
    return {
        baseUrl: ''
    };
  }, 

  onCreate() {
  },

  onDestroy() {
  },

  addAttributes() {
    return {
      href: {
        default: null,
      },
      fileName:{
        default: null,
      }
    }
  },

  parseHTML() {
    return [{ tag: 'a[href]:not([href *= "javascript:" i])' }]
  },

  renderHTML({ HTMLAttributes }) {
    return ['a', HTMLAttributes , 0]
  },

  addCommands() {
    return {
      setLinkToFile:
        fileName => ({ chain }) => {
          return chain().setMark(this.name, {href: `${this.options.baseUrl}/file?filename=${fileName}`, fileName }).run()
        },

      unsetLinkToFile:
        () => ({ chain }) => {
          return chain()
            .unsetMark(this.name, { extendEmptyMarkRange: true })
            .run()
        },
    }
  }
})