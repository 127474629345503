import * as React from 'react';
import { List, Datagrid, TextField, DateField, FunctionField, BooleanInput, useTranslate, Button, CreateButton, ExportButton, TopToolbar, useListContext, useUnselectAll, useListController, BooleanField, useNotify, SearchInput, AutocompleteArrayInput, DateInput, ReferenceArrayInput, SelectInput, FilterButton} from 'react-admin';
import { dataProvider } from '../../lib/dataProvider';
import { Archive } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/GetApp';
import { useLocation } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import { usePermissionGuard } from '../../lib/permissions/usePermissionGuard';

const statusColors = {
  Published: 'success',
  Draft: 'default',
  Closed: 'primary',
};

interface FilterValuesObject {
  isArchived: boolean
}
interface ArchiveActionProps {
  selectedIds?: string[]
  filterValues?: FilterValuesObject
}

const ArchiveItems = async (props: ArchiveActionProps) => {
  await dataProvider.put("/surveys/archive", { archive: !props.filterValues?.isArchived, ids: props.selectedIds})  
}

const Download = (data, type, filename) => {
  console.log('data',data)
  const fakeLink = document.createElement('a');
  fakeLink.style.display = 'none';
  document.body.appendChild(fakeLink);
  // @ts-ignore

  fakeLink.setAttribute('href', `data:${type};base64,${data}`);
  fakeLink.setAttribute('download', `${filename}.xlsx`);
  fakeLink.click();

};

const PostBulkActionButtons = (props: ArchiveActionProps) => {
  const notify = useNotify();
  const unselectAll = useUnselectAll('surveys');
  const { refetch } = useListContext();

  const doExport = async (props: ArchiveActionProps) => {
    try{
      const data = await dataProvider.put(`/surveys/export`, { ids: props.selectedIds});
      Download(data, "application/octet-stream", `enquetes-${(new Date()).toLocaleString("nl-NL")}`)
    }
    catch {
      notify('lto.screens.user_list.export_error', { type: 'warning' });
    };
  };
  
  return (
    <>
      <Button 
        label={!props.filterValues?.isArchived ? 'resources.surveys.fields.archive' : 'resources.surveys.fields.deArchive'} 
        startIcon={<Archive/>} 
        onClick={() => { 
            ArchiveItems(props).then(() => {
              unselectAll();
              refetch();
            });
          } 
        }
      />
      <Button 
        label={'lto.screens.survey_show.export'} 
        startIcon={<DownloadIcon/>} 
        onClick={() => { 
            doExport(props).then(() => {
              unselectAll();
            });
          } 
        }
      />
    </>
  )
};

const postFilters = [
  <SearchInput source="_s" resettable alwaysOn />,
  <DateInput source='publishDate_gte' />,
  <DateInput source='publishDate_lte' />,
  //@ts-ignore api accepts comma-seperated order parameter value
  <ReferenceArrayInput source="createdBy" reference="users" filter={{ ProfileType: 'Dashboard' }} perPage={999} sort={{ field: 'firstName,lastName', order: 'ASC,ASC' }}>
    <AutocompleteArrayInput optionText={(option) => `${option.firstName} ${option.lastName}`} />
  </ReferenceArrayInput>,
  <ReferenceArrayInput source="lto" reference="ltos" sort={{ field: 'name', order: 'ASC' }}>
    <SelectInput optionText='name' />
  </ReferenceArrayInput>,
  <BooleanInput source="isArchived" alwaysOn className='center-boolean-input' />,
];

const ListActions = (props: any) => {
  const notify = useNotify();
  const doExport = async () => {
    try{
      const data = await dataProvider.put(`/surveys/export`, { ids: []});
      Download(data, "application/octet-stream", `enquetes-${(new Date()).toLocaleString("nl-NL")}`)
    }
    catch {
      notify('lto.screens.user_list.export_error', { type: 'warning' });
    };
  };
  return (
    <TopToolbar>
        <FilterButton />
        { props.hasCreate ? <CreateButton/> : null }
        <Button 
          label={'lto.screens.survey_show.export'} 
          startIcon={<DownloadIcon/>} 
          onClick={async () => { 
              await doExport();
            } 
          }
        />
    </TopToolbar>
  )
};

const Grid = (props: any) => {
  const { guard } = usePermissionGuard();
  const canArchive = guard.isAllowed('Survey', 'Archive');
  const translate = useTranslate();
  const location = useLocation();
  const { setFilters} = useListContext();

  React.useEffect(() => setFilters(props.filterDefaultValues, []), [])
  React.useEffect(() => {
    if(location.search === '') {
      setFilters(props.filterDefaultValues, [])
    }
  }, [location]);

  return (
    <Datagrid bulkActionButtons={canArchive ? <PostBulkActionButtons /> : null} rowClick="show">
      <TextField source="title" />
      <BooleanField source="showRealtimeResults" />
      <FunctionField
        source="status"
        render={(record) => {
          return (
            <Chip
              label={translate(`lto.screens.survey_list.status.${record.status.toLowerCase()}`)}
              color={statusColors[record.status]}
              size="small"
            />
          );
        }}
      />
      <DateField source="publishDate" showTime />
      <TextField source="createdBy.name" sortBy="createdBy" label="resources.surveys.fields.createdBy" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  );
  
};

export const SurveyList = (props: any) => {
  const listContext = useListController();

  return (
    <List actions={<ListActions />} debounce={500} hasCreate={!listContext.filterValues?.isArchived} filterDefaultValues={{ isArchived: false, _sf: 'title,description' }} filters={postFilters} {...props}>
      <Grid {...props} filterDefaultValues={{ isArchived: false, _sf: 'title,description' }} />
    </List>
  );
};
