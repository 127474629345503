import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Button,
  DateField,
  useListContext,
  useRefresh,
  useDeleteMany,
  useUnselectAll,
  useResourceContext,
  FunctionField,
  BooleanField,
  useNotify,
  CreateButton,
  fetchUtils,
  TopToolbar,
  BulkDeleteButton
} from 'react-admin';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/GetApp';
import { usePermissionGuard } from '../../lib/permissions/usePermissionGuard';
import { DetachedBooleanField } from '../../components/Field/DetachedBooleanField';
import { LabeledSearchInput } from './components/search-input';
import { useTranslate } from 'ra-core';
import { dataProvider } from '../../lib/dataProvider';
import { useCallback, useState } from 'react';
import { alpha, styled } from '@mui/material/styles';

const StyledButton = styled(Button, {
  name: 'RaDeleteWithUndoButton',
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  color: theme.palette.error.main,
  '&:hover': {
      backgroundColor: alpha(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
          backgroundColor: 'transparent',
      },
  },
}));

function BulkActions(props) {
  const t = useTranslate();
  const { guard } = usePermissionGuard();
  const { selectedIds } = useListContext(props);
  const refresh = useRefresh();
  const resource = useResourceContext(props);
  const unselectAll = useUnselectAll(resource);
  const notify = useNotify();

  const [deletePermanentlyMany, { isLoading: isDeletePermanentlyLoading }] = useDeleteMany(
    'users/dev/nuke',
    { ids: selectedIds },
    {
      onSuccess: () => {
        refresh();
        unselectAll();
      },
      onError: (error: Error) => {
        refresh();
        unselectAll();
      },
      mutationMode: 'pessimistic',
    },
  );
  const [deleteMany, { isLoading: isDeleteLoading }] = useDeleteMany(
    resource,
    { ids: selectedIds },
    {
      onSuccess: () => {
        //refresh();
        notify('ra.notification.deleted', {
            type: 'info',
            messageArgs: { smart_count: selectedIds.length },
            undoable: true,
        });
        unselectAll();
      },
      onError: (error: Error) => {
        refresh();
        unselectAll();
      },
      mutationMode: 'undoable',
    },
  );
  return (
    <>
      {guard.isAllowed("User", "Delete") ? (
        <StyledButton
          className={'ra-delete-button'}
          onClick={(e) => {
            e.stopPropagation();
            if (!window.confirm(t('resources.users.labels.areYouSure'))) return;

            return deleteMany();
          }}
          disabled={isDeleteLoading}
          label={t('resources.users.labels.delete')}
        >
          <DeleteIcon />
        </StyledButton>
      ) : null}
      {guard.isSuperAdmin() ? (
        <Button
          onClick={() => {
            if (!window.confirm('Are you sure? This will permanently delete user data!')) return;

            return deletePermanentlyMany();
          }}
          disabled={isDeletePermanentlyLoading}
          label="Nuke"
        >
          <DeleteForeverIcon />
        </Button>
      ) : null}
    </>
  );
}

const userFilters = [<LabeledSearchInput name="emailAddress_like" source="emailAddress" alwaysOn />];

function ResendConfirmationButton({ record }) {
  const translate = useTranslate();
  const [sending, setSending] = useState(false);
  const notify = useNotify();

  const send = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();
      setSending(true);

      try {
        await dataProvider.post('/users/resend-verification-mail', {
          userId: record.id,
        });

        notify('lto.screens.user_list.resend_success', { type: 'success' });
      } catch (error) {
        notify('lto.screens.user_list.resend_error', { type: 'warning' });
      } finally {
        setSending(false);
      }
    },
    [notify, setSending, translate],
  );

  return (
    <Button
      onClick={send}
      disabled={sending}
      label={translate(`lto.screens.user_list.resend_${sending ? 'sending' : 'confirmation'}`)}
    />
  );
}
const Download = (data, type, filename) => {
  const fakeLink = document.createElement('a');
  fakeLink.style.display = 'none';
  document.body.appendChild(fakeLink);
  // @ts-ignore

  fakeLink.setAttribute('href', `data:${type};base64,${data}`);
  fakeLink.setAttribute('download', `${filename}.xlsx`);
  fakeLink.click();

};

const Exporter = async (filters: any, sort: any, notify: any) => {
  try{
    const data = await dataProvider.getRaw("/users/export", { ...fetchUtils.flattenObject(filters), _sort: sort.field,_order: sort.order});
    Download(data.slice(1,-1), "application/octet-stream", "gebruikers-" + (new Date()).toLocaleString("nl-NL"))
  }
  catch {
    notify('lto.screens.user_list.export_error', { type: 'warning' });
  }
};

const ListActions = () => {
  const notify = useNotify();
  const { filterValues, sort } = useListContext();
  return (
    <TopToolbar>
        <CreateButton/>
        <Button label='ra.action.export' onClick={() => Exporter(filterValues, sort, notify)}><DownloadIcon /></Button>
    </TopToolbar>
  );
};

export function UserList(props: any) {
  return (
    <List actions={<ListActions />} filter={{ _embed: 'profiles' }} filters={userFilters} {...props}>
      <Datagrid rowClick="edit" bulkActionButtons={<BulkActions />}>
        <TextField source="emailAddress" style={{ textTransform: 'lowercase' }} />
        <TextField source="crmId" emptyText="Extern" />
        <TextField source="phoneNumber" />
        <BooleanField source="isVerified" />
        <FunctionField
          label="Dashboard"
          render={(record) => {
            const dashboard = record.profiles.find((profile) => profile.type === 'Dashboard' && profile.isActive);

            return <DetachedBooleanField value={!!dashboard} />;
          }}
        />
        <FunctionField
          label="App"
          render={(record) => {
            const app = record.profiles.find((profile) => profile.type === 'App' && profile.isActive);

            return <DetachedBooleanField value={!!app} />;
          }}
        />
        <DateField source="createdAt" />

        <FunctionField
          render={(record) => {
            const dashboard = record.profiles.find((profile) => profile.type === 'Dashboard');

            return dashboard && !record.isVerified ? <ResendConfirmationButton record={record} /> : null;
          }}
        />
      </Datagrid>
    </List>
  );
}

