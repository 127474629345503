import { Node, mergeAttributes, nodePasteRule } from '@tiptap/core';
import { VIMEO_API_TOKEN } from '../../../lib/constants'
import fetch from 'sync-fetch'

const VIMEO_REGEX = /^(https?:\/\/)?(www\.|player\.)?(vimeo\.com)(?!\/@)(.+)?$/;
const YOUTUBE_REGEX = /^(https?:\/\/)?(www\.|music\.)?(youtube\.com|youtu\.be)(?!.*\/channel\/)(?!\/@)(.+)?$/;
const VIDEO_REGEX_GLOBAL = /^(https?:\/\/)?(www\.|music\.|player.\\.)?(youtube\.com|youtu\.be|vimeo\.com)(?!\/@)(.+)?$/g;


const getVideoDimensions = (videoId: string, isYouTube: boolean = true) => {
    const response = fetch(
        isYouTube ?
        `https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${videoId}&format=json` :
        `https://api.vimeo.com/videos/${videoId}?fields=width,height,player_embed_url`,
        !isYouTube ? {
            headers:{
                Authorization: `Bearer ${VIMEO_API_TOKEN}`
            }
        }: {}
    );
    return response.json();
};

const isValidUrl = (url) => {
    return url.match(YOUTUBE_REGEX) || url.match(VIMEO_REGEX);
};
const getYoutubeEmbedUrl = (nocookie) => {
    return nocookie ? 'https://www.youtube-nocookie.com/embed/' : 'https://www.youtube.com/embed/';
};

const getEmbedUrlAndDimensions = (options) => {
    const { url, allowFullscreen, autoplay, ccLanguage, ccLoadPolicy, controls, disableKBcontrols, enableIFrameApi, endTime, interfaceLanguage, ivLoadPolicy, loop, modestBranding, nocookie, origin, playlist, progressBarColor, startAt, } = options;
    // if is already an embed url, return it
    if (url.includes('/embed/')) {
        return url;
    }
    // if is a youtu.be url, get the id after the /
    if (url.includes('youtu.be')) {
        const id = url.split('/').pop();
        if (!id) {
            return null;
        }
        const { width, height } = getVideoDimensions(id);

        return { url: `${getYoutubeEmbedUrl(nocookie)}${id}`, width, height};
    }

    if (url.includes('vimeo.com')) {
      let id = url.split('/').pop();
      if (!id) {
          return null;
      }
      if(id.includes('?')) id = id.substring(0, id.indexOf('?'))
      const { width, height, player_embed_url } = getVideoDimensions(id, false);
      return { url: player_embed_url, width, height};
  } 

    const videoIdRegex = /v=([-\w]+)/gm;
    const matches = videoIdRegex.exec(url);
    if (!matches || !matches[1]) {
        return null;
    }
    const { width, height } = getVideoDimensions(matches[1])
    let outputUrl = `${getYoutubeEmbedUrl(nocookie)}${matches[1]}`;
    const params = [];
    if (allowFullscreen === false) {
        params.push('fs=0');
    }
    if (autoplay) {
        params.push('autoplay=1');
    }
    if (ccLanguage) {
        params.push(`cc_lang_pref=${ccLanguage}`);
    }
    if (ccLoadPolicy) {
        params.push('cc_load_policy=1');
    }
    if (!controls) {
        params.push('controls=0');
    }
    if (disableKBcontrols) {
        params.push('disablekb=1');
    }
    if (enableIFrameApi) {
        params.push('enablejsapi=1');
    }
    if (endTime) {
        params.push(`end=${endTime}`);
    }
    if (interfaceLanguage) {
        params.push(`hl=${interfaceLanguage}`);
    }
    if (ivLoadPolicy) {
        params.push(`iv_load_policy=${ivLoadPolicy}`);
    }
    if (loop) {
        params.push('loop=1');
    }
    if (modestBranding) {
        params.push('modestbranding=1');
    }
    if (origin) {
        params.push(`origin=${origin}`);
    }
    if (playlist) {
        params.push(`playlist=${playlist}`);
    }
    if (startAt) {
        params.push(`start=${startAt}`);
    }
    if (progressBarColor) {
        params.push(`color=${progressBarColor}`);
    }
    if (params.length) {
        outputUrl += `?${params.join('&')}`;
    }
    return { url: outputUrl, width, height };
};

declare type SetVideoOptions = {
  src: string;
  width?: number;
  height?: number;
  start?: number;
};
declare module '@tiptap/core' {
  interface Commands<ReturnType> {
      youtube: {
          /**
           * Insert a youtube video
           */
          setVideo: (options: SetVideoOptions) => ReturnType;
      };
  }
}

export const Video = Node.create({
    name: 'video',
    addOptions() {
        return {
            addPasteHandler: true,
            allowFullscreen: true,
            autoplay: false,
            ccLanguage: undefined,
            ccLoadPolicy: undefined,
            controls: true,
            disableKBcontrols: false,
            enableIFrameApi: false,
            endTime: 0,
            height: 480,
            interfaceLanguage: undefined,
            ivLoadPolicy: 0,
            loop: false,
            modestBranding: false,
            HTMLAttributes: {},
            inline: false,
            nocookie: false,
            origin: '',
            playlist: '',
            progressBarColor: undefined,
            width: 640,
        };
    },
    inline() {
        return this.options.inline;
    },
    group() {
        return this.options.inline ? 'inline' : 'block';
    },
    draggable: true,
    addAttributes() {
        return {
            src: {
                default: null,
            },
            start: {
                default: 0,
            },
            width: {
                default: this.options.width,
            },
            height: {
                default: this.options.height,
            },
            videoWidth: {
                default: 0,
            },
            videoHeight: {
                default: 0,
            },
        };
    },
    parseHTML() {
        return [
            {
                tag: 'div[data-youtube-video] iframe',
            },
        ];
    },
    addCommands() {
        return {
            setVideo: (options) => ({ commands }) => {
                if (!isValidUrl(options.src)) {
                    return false;
                }
                const data = getEmbedUrlAndDimensions({
                    url: options.src,
                    allowFullscreen: this.options.allowFullscreen,
                    autoplay: this.options.autoplay,
                    ccLanguage: this.options.ccLanguage,
                    ccLoadPolicy: this.options.ccLoadPolicy,
                    controls: this.options.controls,
                    disableKBcontrols: this.options.disableKBcontrols,
                    enableIFrameApi: this.options.enableIFrameApi,
                    endTime: this.options.endTime,
                    interfaceLanguage: this.options.interfaceLanguage,
                    ivLoadPolicy: this.options.ivLoadPolicy,
                    loop: this.options.loop,
                    modestBranding: this.options.modestBranding,
                    nocookie: this.options.nocookie,
                    origin: this.options.origin,
                    playlist: this.options.playlist,
                    progressBarColor: this.options.progressBarColor,
                    startAt: options.start || 0,
                });

                return commands.insertContent({
                    type: this.name,
                    attrs: {...options, src: data.url, videoWidth: data.width, videoHeight: data.height }
                });
            },
        };
    },
    addPasteRules() {
        if (!this.options.addPasteHandler) {
            return [];
        }
        return [
            nodePasteRule({
                find: VIDEO_REGEX_GLOBAL,
                type: this.type,
                getAttributes: match => {
                    return { src: match.input };
                },
            }),
        ];
    },
    renderHTML({ HTMLAttributes }) {
        HTMLAttributes.height = (this.options.width / HTMLAttributes.videoWidth) * HTMLAttributes.videoHeight;
        
        return [
            'div',
            { 'data-youtube-video': '' },
            [
                'iframe',
                mergeAttributes(this.options.HTMLAttributes, {
                    width: this.options.width,
                    height: this.options.height,
                    allowfullscreen: this.options.allowFullscreen,
                    autoplay: this.options.autoplay,
                    ccLanguage: this.options.ccLanguage,
                    ccLoadPolicy: this.options.ccLoadPolicy,
                    disableKBcontrols: this.options.disableKBcontrols,
                    enableIFrameApi: this.options.enableIFrameApi,
                    endTime: this.options.endTime,
                    interfaceLanguage: this.options.interfaceLanguage,
                    ivLoadPolicy: this.options.ivLoadPolicy,
                    loop: this.options.loop,
                    modestBranding: this.options.modestBranding,
                    origin: this.options.origin,
                    playlist: this.options.playlist,
                    progressBarColor: this.options.progressBarColor,
                }, HTMLAttributes),
            ],
        ];
    },
});