import * as React from 'react';
import { Confirm, Edit, useEditContext } from 'react-admin';
import { SurveyForm } from './SurveyForm';
import { useCallback } from 'react';
import { useTranslate } from 'ra-core';
import { fromGenericToUTC } from '../../lib/i18n/utils';

export const SurveyEdit = (props: any) => {
  const transform = data => ({
    ...data,
    publishDate: data.publishDate ? fromGenericToUTC(data.publishDate) : null,
  });

  return (
    <Edit {...props} transform={transform}>
      <SurveyEditForm />
    </Edit>
  );
  
};

export function SurveyEditForm() {
  const { save, isLoading } = useEditContext();
  const translate = useTranslate();
  const [pendingUpdate, setPendingUpdate] = React.useState(null);
  const onClose = useCallback(() => {
    setPendingUpdate(null);
  }, [setPendingUpdate]);

  const onConfirm = useCallback(() => {
    save(pendingUpdate);
  }, [save, pendingUpdate]);

  return (
    <>
      <SurveyForm onSubmit={setPendingUpdate} />
      <Confirm
        isOpen={!!pendingUpdate}
        loading={isLoading}
        title={translate('lto.screens.survey_edit.confirm.title')}
        content={translate('lto.screens.survey_edit.confirm.description')}
        onConfirm={onConfirm}
        onClose={onClose}
      />
    </>
  );
}
