import * as React from 'react';
import { Edit, ReferenceInput, SelectInput, SimpleForm, TextInput } from 'react-admin';
import { NoDeleteToolbar } from '../../components/NoDeleteToolbar';
import { RecordEditTitle, RecordShowTitle } from '../../components/RecordTitle';

export const DepartmentEdit = (props: any) => (
  <Edit title={<RecordEditTitle />} {...props}>
    <SimpleForm toolbar={<NoDeleteToolbar />}>
      <TextInput source="name" />
      <ReferenceInput label="LTO" source="ltoId" reference="ltos">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
