import {
  BooleanInput,
  FormDataConsumer,
  ImageInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useGetIdentity,
  useEditController,
  useGetList,
  DateTimeInput,
  useInput,
  ReferenceArrayInput,
  AutocompleteInput,
  Labeled,
} from 'react-admin';
import React, { SyntheticEvent, useMemo, useCallback } from 'react';
import { RichTextInputToolbar, ListButtons, LinkButtons, ClearButtons } from 'ra-input-rich-text';
import { ToggleButtonGroup } from '@mui/material';
import { withUpload } from '../../lib/withUpload';
import { ServiceImageField } from '../../components/Field/ServiceImageField';
import { RichTextInput } from '../../components/RichTextJsonInput';
import { VideoButton } from '../../components/VideoButton';
import { CustomFormatButtons } from '../../components/FormatButtons';
import { GroupedRegionDepartmentSelectArrayInput } from '../../components/Input/GroupedRegionDepartmentSelectArrayInput';
import { LevelSelect } from '../../components/LevelSelect';
import { HorizontalRuleButton } from '../../components/HorizontalRuleButton';
import { usePermissionGuard } from '../../lib/permissions/usePermissionGuard';
import NewsResource from '../News/NewsResource';
import { NoDeleteToolbar } from '../../components/NoDeleteToolbar';
import { useTranslate } from 'ra-core';
import { ImageButton } from '../../components/ImageButton';
import { LinkContentButton } from '../../components/LinkContentButton';
import { LinkFileButton } from '../../components/LinkFileButton';
import { Autocomplete, TextField } from '@mui/material';
import { transformMediaUrlInput } from '../../lib/media/makeUrl';
import { isUrlValid } from '../../lib/media/isValidUrl';
import { DateTimePicker } from '../../components/Input/DateTimePickerInput';
import AgendaResource from './AgendaResource';
import { fromUTC } from '../../lib/i18n/utils';


let urlValid = false;

const urlExists = (url:string) => {
  if (url)
    return urlValid ? undefined : 'lto.generic.url_notfound';
  else 
    return undefined;
}

const rules = {
  title: [required()],
  topic: [required()],
  imageurl: [required(), isUrlValid, urlExists],
  image: [required()],
  location: [required()],
  start: [required()],
  end: [required()],
  departments: [required()],
};

export function AgendaForm({ hideSendPushNotification = false, username = '' }) {
  const { guard } = usePermissionGuard();
  const translate = useTranslate();
  const { data, isLoading } = useGetList('ltos');
  const { data: newsData, isLoading: isNewsLoading } = useGetList(
    'news',
    { 
      pagination: { page: 1, perPage: 999 },
      sort: { field:'createdAt' , order: 'DESC' }, 
      filter: { isArchived: false },
    },
  );
  const showPush = guard.isAllowed(AgendaResource.permissionResource, 'PushNotifications');
  const ltoMap = useMemo(
    () =>
      data?.reduce((acc, lto) => {
        acc[lto.id] = lto.name;

        return acc;
      }, {}),
    [data],
  );
  if (isLoading || isNewsLoading) return null;

  return (
    <SimpleForm defaultValues={withUpload('mediaId', 'media')} toolbar={<NoDeleteToolbar />}>
      <TextInput source="title" validate={rules.title} helperText={false} />
      <ReferenceInput label={"resources.agenda.fields.topic"} source="topicId" reference="topics" perPage={99999} sort={{ field: 'name', order: 'ASC'}}>
        <SelectInput label={"resources.agenda.fields.topic"} optionText="name" validate={rules.topic} />
      </ReferenceInput>
      
      <ReferenceArrayInput source="departmentIds" reference="departments" perPage={99999}>
        <GroupedRegionDepartmentSelectArrayInput
          extractGroupLabel={({ ltoId }) => ltoMap[ltoId]}
          groupBy="ltoId"
          sortBy="name"
          label={'resources.news.fields.departments'}
          validate={rules.departments}
          regionsLabel={'resources.news.fields.regions'}
          noRegionsLabel={'resources.news.fields.noRegions'}
        />
      </ReferenceArrayInput>
      
      <Labeled
        isRequired
        label={"resources.agenda.fields.media"}
      >
        <></>
      </Labeled>

      <BooleanInput source="useMediaUrl" />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          const displayurl = transformMediaUrlInput(formData.mediaUrl)
          return formData.useMediaUrl ? (
              <>
                <TextInput source="mediaUrl" placeholder='mediaUrl' validate={rules.imageurl} />
                {formData.mediaUrl ? <img style={{ marginBottom: '14px'}} onError={() => urlValid = false} onLoad={() => urlValid = true} src={`${displayurl}${displayurl.includes('?') ? '&' : '?'}width=48&height=48`} width={48} height={48} alt={formData.mediaUrl} /> : null}
              </>
            ) : (
              <ImageInput
                source="media"
                label={false}
                accept="image/png, image/jpeg, image/webp"
                validate={rules.image}
                placeholder={<p>{translate('lto.upload_label')}</p>}
                helperText={'resources.news.imageHelperText'}
              >
                <ServiceImageField source="media" sortable={false} />
              </ImageInput>
            )
          }
        }
      </FormDataConsumer>
      
      <DateTimePicker source="start" validate={rules.start} label='resources.agenda.fields.start' />
      <DateTimePicker source="end" validate={rules.end} label='resources.agenda.fields.end' />
      <TextInput source="location" validate={rules.location} />
      
      <RichTextInput
        source="description"
        contentType="json"
        toolbar={
          <RichTextInputToolbar>
            <LevelSelect />
            <HorizontalRuleButton />
            <CustomFormatButtons />
            <ListButtons />
            <ToggleButtonGroup>
              <LinkButtons />
              <LinkContentButton resource={NewsResource.name}  />
              <LinkFileButton allowed={['application/msword', 'application/msexcel', 'application/pdf', 'text/plain', 'application/vnd.ms-powerpoint']} />
            </ToggleButtonGroup>
            <ToggleButtonGroup>
              <ImageButton />
              <VideoButton />
            </ToggleButtonGroup>
            <ClearButtons />
          </RichTextInputToolbar>
        }
      />

      <Labeled
        label={"resources.agenda.fields.extra_options"}
      >
        <></>
      </Labeled>
      <BooleanInput source="isPinned" helperText={false} />
      <BooleanInput source="isLocalNews" helperText={false} />
      {!hideSendPushNotification && showPush ? (
        <>
          <BooleanInput source="pushNotification.enabled" helperText={false} />
          <FormDataConsumer>
            {({ formData, ...rest }) => 
              formData.pushNotification?.enabled ? (
                <>
                  <TextInput source="pushNotification.title" defaultValue="Een nieuw agenda item!" />
                  <TextInput source="pushNotification.description" defaultValue="Er is een nieuw agenda item." />
                </>
              ) : null
            }
          </FormDataConsumer>
        </>
      ) : null}

      <BooleanInput source="allowRegistrations" helperText={false} />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return(<DateTimePicker source="publishedAt" value={formData.PublishedAt ? fromUTC(formData.PublishedAt) : null} validate={null} label="resources.agenda.fields.publishAt" />)
        }}
      </FormDataConsumer>
      <BooleanInput source="isPreview" label={translate("resources.agenda.fields.isPreview", { createdBy: username })} helperText={false} />
      <AutocompleteInput multiple label={translate('resources.agenda.fields.newsIds')} source='newsIds' choices={newsData.map(x=> ({ id: x.id, name: x.title}))} />
    </SimpleForm>
  );
}
