import jsonServerProvider from 'ra-data-json-server';
import { GetOneParams, Options } from 'ra-core';
import { Auth } from 'aws-amplify';
import { DataProvider, fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import { API_URL, FILE_UPLOAD } from './constants';
import { getActiveProfile } from './authProvider';

export async function getCurrentAccessToken() {
  try {
    const res = await Auth.currentSession();
    return res.getAccessToken();
  } catch (error) {
    return null;
  }
}


const setOptions = async (options: Options = {}) => {
  options.headers = (options.headers as Headers) || new Headers({ Accept: 'application/json' });

  const accessToken = await getCurrentAccessToken();

  if (accessToken) {
    options.headers.set('Authorization', `Bearer ${accessToken.getJwtToken()}`);
    const activeProfile = getActiveProfile();

    if (activeProfile) {
      options.headers.set('x-profile', activeProfile);
    }
  }
  return options;
}
export const httpClient = async (url: string, options: Options = {}) => {
  options = await setOptions(options)
  return fetchUtils.fetchJson(url, options);
};

const dp = jsonServerProvider(API_URL, httpClient);

export const dataProvider: DataProvider & { httpClient: any } = {
  ...dp,
  httpClient,
  post: async (path: string, body?: any) => {
    const { json } = await httpClient(API_URL + path, {
      method: 'POST',
      body: JSON.stringify(body),
    });

    return json || { data: { ok: true } };
  },
  get: async (path: string) => {
    const { json } = await httpClient(API_URL + path, {
      method: 'GET',
    });

    return json || { data: { ok: true } };
  },
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      ...fetchUtils.flattenObject(params.filter),
      _sort: field,
      _order: order,
      _start: (page - 1) * perPage,
      _end: page * perPage,
    };
    if (params?.meta?.embed) {
      query["_embed"] = params?.meta?.embed
    }
    const url = `${API_URL}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      if (!headers.has('x-total-count')) {
        throw new Error(
          'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
        );
      }
      return {
        data: json,
        total: parseInt(
          headers.get('x-total-count').split('/').pop(),
          10
        ),
      };
    });
  },
  getRaw: async (path: string, params?: any) => {
    const requestHeaders = await setOptions({
      method: 'GET',

    })
    if (params && Object.keys(params).length !== 0) {
      path += "?" + new URLSearchParams(params)
    }

    const { body } = await fetch(API_URL + path, requestHeaders)
      .then(response =>
        response.text().then(text => ({
          status: response.status,
          statusText: response.statusText,
          headers: response.headers,
          body: text,
        }))
      );

    return body || { data: { ok: true } };
  },
  deleteRaw: async (path: string, params?: any) => {
    const requestHeaders = await setOptions({
      method: 'DELETE',

    })
    if (params && Object.keys(params).length !== 0) {
      path += "?" + new URLSearchParams(params)
    }

    const { body } = await fetch(API_URL + path, requestHeaders)
      .then(response =>
        response.text().then(text => ({
          status: response.status,
          statusText: response.statusText,
          headers: response.headers,
          body: text,
        }))
      );

    return body || { data: { ok: true } };
  },
  put: async (path: string, body?: any) => {
    const { json } = await httpClient(API_URL + path, {
      method: 'PUT',
      body: JSON.stringify(body),
    });

    return json || { data: { ok: true } };
  },
  getMany: (resource: string, params: any) => {
    if (params.ids && Object.keys(params).length === 2) {
      params._start = 0;
      params._end = params.ids.length;
    }
    params.embed = params?.meta?.embed
    return dp.getMany(resource, params);
  },
  getOne: (resource: string, params: GetOneParams) => {
    const embed = params?.meta?.embed ? `?_embed=${params?.meta?.embed}` : '';

    return httpClient(`${API_URL}/${resource}/${params.id}${embed}`).then(({ json }) => ({
      data: json,
    }));
  },
  async patchUpload(resource, params: any, action: 'update' | 'create') {
    if (!params.data[FILE_UPLOAD]) {
      return action === 'create' ? dp.create(resource, params) : dp.update(resource, params);
    }

    const { key, source } = params.data[FILE_UPLOAD];
    const { fileData, rest } = Object.keys(params.data).reduce(
      (acc, dataKey) => {
        if (dataKey === FILE_UPLOAD) {
          return acc;
        }

        if (dataKey === key) {
          acc.fileData = params.data[key];
        } else {
          acc.rest[dataKey] = params.data[dataKey];
        }

        return acc;
      },
      { fileData: null as any, rest: {} as any },
    );

    if (!fileData?.rawFile) {
      params.data = rest;

      return action === 'create' ? dp.create(resource, params) : dp.update(resource, params);
    }

    const formData = new FormData();

    formData.append('file', fileData.rawFile);

    const { json: fileUploadResponse } = await httpClient(`${API_URL}/media`, {
      method: 'POST',
      body: formData,
    });

    const { id } = fileUploadResponse;
    rest[source] = id;

    const { json } = await httpClient(`${API_URL}/${resource}${action === 'create' ? '' : `/${params.id}`}`, {
      method: action === 'create' ? 'POST' : 'PUT',
      body: JSON.stringify(rest),
    });

    return {
      data: { ...params.data, id: json.id },
    };
  },
  update: (resource: string, params: any): any => {
    return dataProvider.patchUpload(resource, params, 'update');
  },
  create: (resource: string, params: any): any => {
    return dataProvider.patchUpload(resource, params, 'create');
  },
};
